import React, {useEffect, useState} from 'react';
import OlapList from "../OlapList";

import ObjectEditInput from "./ObjectEditInput";
import ObjectTimeInput from "./ObjectTimeInput";
import ObjectDaysCheckboxes from "./ObjectDaysCheckboxes";
import ObjectDescriptionModal from "./ObjectDescriptionModal";
import ObjectCategoriesModal from "./ObjectCategoriesModal";
import ObjectCategoriesNumbersModal from "./ObjectCategoriesNumbersModal";
import serviceService from "../../../services/ServiceService";
import {useOrganization} from "../../../hooks/useOrganization";
import {useAuth} from "../../../hooks/useAuth";
import {useLocation} from "react-router-dom";
import OlapWiki from "../Management/OlapWiki";
import OlapOrganizationServices from "../OlapOrganizationServices";
import ObjectServiceModal from "./ObjectServiceModal";
import ObjectAddServiceModal from "./ObjectAddServiceModal";
import ObjectPlacerholderServiceModal from "./ObjectPlaceholderServiceModal";
import ObjectGeoLocation from "./ObjectGeoLocation";
import ObjectMediaUploadModal from "./ObjectMediaUploadModal";
import ObjectBlocked from "./ObjectBlocked";
import SimpleBar from "simplebar-react";

const ObjectPropertiesTable = ({sidebarOpen, handleSidebarChange, item, category, place, onSubmit}) => {
    const [selectedOption, setSelectedOption] = useState("Eigenschaften");
    const [hoveredColumn, setHoveredColumn] = useState(null);
    const [organizationServices, setOrganizationServices] = useState([]);
    const [categoryServices, setCategoryServices] = useState([]);
    const [objectServices, setObjectServices] = useState([]);
    const {organization} = useOrganization();
    const {user} = useAuth();
    let location = useLocation();


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const fetchOrganizationServices = async () => {
        const response = await serviceService.getOrganizationServices(organization.id, user);
        setOrganizationServices(response.data);
    }

    const fetchCategoryServices = async () => {
        const response = await serviceService.getCategoryServices(category.id, user);
        setCategoryServices(response.data);
    }

    const fetchObjectServices = async () => {
        const response = await serviceService.getObjectServices(item.id, user);
        setObjectServices(response.data);
    }

    const submitService = () => {
        fetchOrganizationServices();
        fetchObjectServices();
    }

    useEffect(() => {
        fetchOrganizationServices();
        fetchObjectServices();
    }, [location]);  // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className={`row`}>
            <div className={`col-sm-2 ${sidebarOpen ? '' : 'd-none'}`}>
                <div className='card'>
                    <div className='card-body'>
                        <SimpleBar>
                            <OlapList handleSidebarChange={handleSidebarChange}/>
                        </SimpleBar>
                    </div>
                </div>
            </div>
            <div className={`${sidebarOpen ? 'col-sm-10' : 'col-sm-12'}`}>
                <div className="card">
                    <div className="card-body">
                        <div className="olap-table">
                            <table id="alternative-pagination" className={`table nowrap dt-responsive align-middle olap-table-hover table-bordered dataTable no-footer dtr-inline`}>
                                <thead>
                                    <tr>
                                        <th className="dtr-control">
                                            <div className="dataTables_length">
                                                <select name="model-datatables_length" aria-controls="model-datatables" className="form-select form-select-sm" onChange={handleOptionChange} value={selectedOption}>
                                                    <option>Eigenschaften</option>
                                                    <option>Beschreibungstexte</option>
                                                    <option>Kategorien</option>
                                                    <option>Medien</option>
                                                    <option>Leistungen</option>
                                                </select>
                                            </div>
                                        </th>
                                        <th
                                            className={hoveredColumn === 1 ? 'hovered' : ''}
                                        >
                                            <div className="table-month">
                                                <span>{item.name}</span>
                                                <small className='text-muted'>
                                                    {item.channels.length > 0 && item.channels[0].channelType === "CPO" ? " (" + item.channels[0].channelDataMap.cpoId + " - " + item.channels[0].channelDataMap.name + ")" : ''}
                                                </small>
                                            </div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Objekt blocken
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectBlocked tag={"blocked"} object={item} onSubmit={onSubmit}/>
                                    </td>

                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Basispreis für Standardbelegung
                                            <OlapWiki tag={'basispreis-f%C3%BCr-standardbelegung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectEditInput tag={"price"} category={category} object={item}
                                                         onSubmit={onSubmit}/>
                                    </td>

                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Mindestaufenthalt
                                            <OlapWiki tag={'mindestaufenthalt'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectEditInput tag={"minimumStay"} category={category} object={item}
                                                         onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Buchungslücken füllen ab
                                            <OlapWiki tag={'buchungsl%C3%BCcken-f%C3%BCllen-ab'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectEditInput tag={"gapFill"} category={category} object={item}
                                                         onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Karenztage (nach Buchung)
                                            <OlapWiki tag={'karenztage-nach-buchung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectEditInput tag={"daysBlockedAfterBooking"} category={category}
                                                         object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Standardbelegung
                                            <OlapWiki tag={'standardbelegung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectEditInput tag={"occupancyDefault"} category={category} object={item}
                                                         onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Maximale Belegung
                                            <OlapWiki tag={'maximalbelegung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectEditInput tag={"occupancyMax"} category={category} object={item}
                                                         onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Davon Babys
                                            <OlapWiki tag={'davon-babys'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectEditInput tag={"babies"} category={category} object={item}
                                                         onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Anreise von
                                            <OlapWiki tag={'anreise-von'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectTimeInput tag={"arrivalTimeFrom"} category={category} object={item}
                                                         onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Anreise bis
                                            <OlapWiki tag={'anreise-bis'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectTimeInput tag={"arrivalTimeTo"} category={category} object={item}
                                                         onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Abreise von
                                            <OlapWiki tag={'abreise-von'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectTimeInput tag={"departureTimeFrom"} category={category} object={item}
                                                         onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Abreise bis
                                            <OlapWiki tag={'abreise-bis'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectTimeInput tag={"departureTimeTo"} category={category} object={item}
                                                         onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Ankunftstage
                                            <OlapWiki tag={'m%C3%B6gliche-anreisewochentage'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectDaysCheckboxes tag={"arrivalDays"} category={category} object={item}
                                                              onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Abreisetage
                                            <OlapWiki tag={'m%C3%B6gliche-abreisewochentage'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectDaysCheckboxes tag={"departureDays"} category={category} object={item}
                                                              onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            GPS Daten
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectGeoLocation tag={"geoLocations"} category={category} object={item}
                                                           onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Objektbeschreibung
                                            <OlapWiki tag={'objektbeschreibung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectDescriptionModal tag={"infoDescription"} object={item}
                                                                onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Beschreibung der Umgebung
                                            <OlapWiki tag={'beschreibung-der-umgebung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectDescriptionModal tag={"infoEnvironment"} object={item}
                                                                onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Information zu Kaution
                                            <OlapWiki tag={'information-zur-kaution'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectDescriptionModal tag={"infoDeposit"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Information zu Haustieren
                                            <OlapWiki tag={'information-zu-haustieren'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectDescriptionModal tag={"infoPets"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Objekttyp
                                            <OlapWiki tag={'objekttyp'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"objectTypes"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Raumtyp
                                            <OlapWiki tag={'raumtyp'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"roomTypes"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Zielgruppe
                                            <OlapWiki tag={'zielgruppe'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"targetGroups"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Attribute
                                            <OlapWiki tag={'attribute'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"attributes"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Gastronomie
                                            <OlapWiki tag={'gastronomie'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"foodSupplies"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Bad Equipment
                                            <OlapWiki tag={'bad-equipment'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"bathEquipments"} object={item}
                                                               onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Küchen Equipment
                                            <OlapWiki tag={'k%C3%BCcheneigenschaften'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"kitchenEquipments"} object={item}
                                                               onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Ausstattung
                                            <OlapWiki tag={'ausstattung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"facilities"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Geräte
                                            <OlapWiki tag={'ger%C3%A4te'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"technics"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Dienstleistungen
                                            <OlapWiki tag={'dienstleistungen'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"serviceOffers"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Freizeitangebote
                                            <OlapWiki tag={'freizeitangebote'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"leisureOffers"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Outdoor Equipment
                                            <OlapWiki tag={'outdoor-equipment'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesModal tag={"outdoorEquipments"} object={item}
                                                               onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Betten
                                            <OlapWiki tag={'betten'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectCategoriesNumbersModal tag={"beds"} object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                {
                                    objectServices.map((service, serviceIndex) => (
                                        <tr key={serviceIndex}
                                            className={`serviceRow ${selectedOption === "Leistungen" ? "" : "d-none"}`}>
                                            <td className="dtr-control">
                                                <div className="rowTitle">
                                                    {service.service.name[0] && service.service.name[0].text ? service.service.name[0].text : ''}
                                                </div>
                                            </td>
                                            <td
                                                onMouseEnter={() => setHoveredColumn(1)}
                                                onMouseLeave={() => setHoveredColumn(null)}
                                            >
                                                <ObjectServiceModal serviceItem={service} objectId={item.id}
                                                                    onSubmit={submitService}/>
                                            </td>
                                        </tr>
                                    ))
                                }
                                {
                                    objectServices.length === 0 && categoryServices.map((service, serviceIndex) => (
                                        <tr key={serviceIndex}
                                            className={`serviceRow ${selectedOption === "Leistungen" ? "" : "d-none"}`}>
                                            <td className="dtr-control">
                                                <div className="rowTitle">
                                                    <div>
                                                        {service.name[0] && service.name[0].text ? service.name[0].text : ''}
                                                    </div>
                                                    <div>
                                                        <span
                                                            className='align-items-center badge bg-dark-subtle text-dark badge-border'
                                                            style={{backgroundColor: '#e9ebec', fontSize: '11px'}}>geerbt von {category.name}</span>
                                                    </div>

                                                </div>
                                            </td>
                                            <td
                                                onMouseEnter={() => setHoveredColumn(1)}
                                                onMouseLeave={() => setHoveredColumn(null)}
                                            >
                                                <ObjectPlacerholderServiceModal service={service} objectId={item.id}
                                                                                onSubmit={submitService}/>
                                            </td>
                                        </tr>
                                    ))
                                }

                                {
                                    objectServices.length === 0 && categoryServices.length === 0 && (
                                        <tr
                                            className={`serviceRow ${selectedOption === "Leistungen" ? "" : "d-none"}`}>
                                            <td className="dtr-control">
                                                <div className="rowTitle">
                                                    <div>
                                                        Leistungen
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                onMouseEnter={() => setHoveredColumn(1)}
                                                onMouseLeave={() => setHoveredColumn(null)}
                                            >
                                                Es wurden noch keine Leistungen für dieses Objekt hinterlegt. Unter "Leistungsvorlage der Organisation" kann gestartet werden.
                                            </td>
                                        </tr>
                                    )
                                }

                                {
                                    /*

                                   <tr className={`serviceRow ${selectedOption === "Leistungen" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            <ObjectAddServiceModal objectId={item.id} onSubmit={submitService}/>
                                        </div>
                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                     */
                                }

                                <tr className={`mediaRow ${selectedOption === "Medien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Bilder und Videos
                                            <OlapWiki tag={'bilder'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <ObjectMediaUploadModal object={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {
                    selectedOption === "Leistungen" && (
                        <OlapOrganizationServices services={organizationServices} onSubmit={submitService}/>
                    )
                }

            </div>
        </div>
    );
}
export default ObjectPropertiesTable;
import React, {useEffect, useState} from 'react';
import { RiEditLine } from 'react-icons/ri';
import {useAuth} from "../../../hooks/useAuth";
import CategoryService from "../../../services/CategoryService";

const CategoryCategoriesModal = ({tag, category, onSubmit}) => {

    const categoryEnums = {
        leisureOffers: {
            BEAUTY_DEPARTMENT: 'Schönheitsabteilung',
            BILLIARDS: 'Billard',
            BOAT_RENTAL: 'Bootsverleih',
            CASINO: 'Kasino',
            STEAM_ROOM: 'Dampfbad',
            BICYCLE_RENTAL: 'Fahrradverleih',
            SKYDIVING: 'Fallschirmspringen',
            FITNESS_ROOM: 'Fitnessraum',
            GOLF: 'Golf',
            DEEP_SEA_FISHING_TRIPS: 'Hochseeangeln',
            INDOOR_PLAYGROUND: 'Indoor-Spielplatz',
            JET_SKI: 'Jet-Ski',
            GO_KARTING: 'Go-Kart',
            BOWLING: 'Bowling',
            KITE_SURFING: 'Kitesurfen',
            KITE_SURFING_SCHOOL: 'Kitesurf-Schule',
            ROCK_CLIMBING: 'Klettern',
            CARRIAGE_RIDES: 'Kutschfahrten',
            MASSAGE: 'Massage',
            MINI_GOLF: 'Minigolf',
            PARAGLIDING: 'Paragliding',
            HORSEBACK_RIDING: 'Reiten',
            SLEDDING: 'Rodeln',
            SWIMMING_POOL: 'Schwimmbad',
            SKI_SLED_RENTAL: 'Ski- und Schlittenverleih',
            SOLARIUM: 'Solarium',
            SUMMER_TOBOGGAN_RUN: 'Sommerrodelbahn',
            PLAYGROUND: 'Spielplatz',
            PETTING_ZOO: 'Tierpark',
            SURFING: 'Surfen',
            SURFING_SCHOOL: 'Surfschule',
            TENNIS: 'Tennis',
            THERMAL_APPLICATIONS: 'Thermalanwendungen',
            WATER_SKIING: 'Wasserski',
            MUDFLAT_HIKING: 'Wattwanderung',
            WELLNESS_OFFERS: 'Wellnessangebote',
            YACHT_CHARTER: 'Yachtcharter',
            ZOO: 'Zoo',
        },
        serviceOffers: {
            BABYSITTING: 'Kinderbetreuung',
            BREAD_ROLL_SERVICE: 'Brötchenservice',
            EC_CARDS: 'EC-Karten',
            SHOPPING_SERVICE: 'Einkaufsservice',
            ENHANCED_CLEANING_METHODS_IN_USE: 'Verbesserte Reinigungsmethoden',
            BREAKFAST_SERVICE: 'Frühstücksservice',
            CHILDREN_PROGRAM: 'Kinderprogramm',
            CREDIT_CARDS: 'Kreditkarten',
            TRANSFER_SERVICE: 'Transferservice',
            LAUNDRY_SERVICE: 'Wäscheservice',
            ROOM_SERVICE: 'Zimmerservice',
        },
        attributes: {
            ALLERGY_FRIENDLY: 'Allergikerfreundlich',
            LOUNGE: 'Lounge',
            ACCESSIBILITY: 'Barrierefrei',
            DISABLED_ACCESSIBLE: 'Rollstuhlgerecht',
            LOCKABLE_BICYCLE_ROOM: 'Abschließbarer Fahrradraum',
            ELEVATOR_LIFT: 'Aufzug',
            FAMILY_FRIENDLY: 'Familienfreundlich',
            SUITABLE_FOR_WORKERS: 'Arbeiterfreundlich',
            COMMUNAL_SAUNA: 'Gemeinschaftssauna',
            COMMUNAL_WHIRLPOOL: 'Gemeinschafts-Whirlpool',
            HOME_OFFICE_WORKATION: 'Homeoffice und Workation',
            CONTACTLESS_CHECKIN_CHECKOUT: 'Kontaktloses Ein- und Auschecken',
            NON_SMOKING_HOUSE: 'Nichtraucherhaus',
            BICYCLE_FRIENDLY: 'Fahrradfreundlich',
            SMOKING_AREA: 'Raucherbereich',
            SKI_DEPOT: 'Skiablage',
            SKI_ROOM: 'Skiraum',
            CONFERENCE_EQUIPMENT: 'Konferenzausstattung',
            DRY_ROOM: 'Trockenraum',
            OVER_ADULTS_ONLY: 'Nur für Erwachsene',
            HIKING_FRIENDLY: 'Wanderfreundlich',
            CONSERVATORY: 'Wintergarten',
        },
        foodSupplies: {
            THREE_QUARTERS_PENSION: 'Dreiviertelpension',
            BAR: 'Bar',
            BEER_GARDEN: 'Biergarten',
            BREAKFAST_AVAILABLE: 'Frühstück verfügbar',
            GRILL_FACILITY: 'Grilleinrichtung',
            HALF_BOARD: 'Halbpension',
            KITCHEN_ALLERGY_FRIENDLY: 'Küche allergikerfreundlich',
            KITCHEN_DIETARY: 'Küche diätisch',
            KITCHEN_REGIONAL_PRODUCTS: 'Küche mit regionalen Produkten',
            KITCHEN_LIGHT_CUISINE: 'Leichte Küche',
            KITCHEN_GOURMET_CUISINE: 'Gourmetküche',
            KITCHEN_VEGETARIAN: 'Vegetarische Küche',
            HOME_PRODUCED_PRODUCTS: 'Hausgemachte Produkte',
            IN_HOUSE_RESTAURANT: 'Hauseigenes Restaurant',
            WINE_CELLAR: 'Weinkeller',
            FULL_BOARD: 'Vollpension',
            WHOLEFOOD: 'Vollwertkost',
        },
        roomTypes: {
            APARTMENT: 'Apartment',
            ONE_ROOM_APARTMENT: 'Einzimmer-Apartment',
            TWO_ROOM_APARTMENT: 'Zweizimmer-Apartment',
            THREE_ROOM_APARTMENT: 'Dreizimmer-Apartment',
            FOUR_ROOM_APARTMENT: 'Vierzimmer-Apartment',
            FIVE_PLUS_ROOM_APARTMENT: 'Fünfzimmer-Apartment und mehr',
            BUNGALOW: 'Bungalow',
            ATTIC_APARTMENT: 'Dachgeschosswohnung',
            DOUBLE_ROOM: 'Doppelzimmer',
            TWIN_ROOM: 'Zweibettzimmer',
            SINGLE_ROOM: 'Einzelzimmer',
            HOLIDAY_HOME: 'Ferienhaus',
            ONE_ROOM_HOLIDAY_HOME: 'Einzimmer-Ferienhaus',
            TWO_ROOM_HOLIDAY_HOME: 'Zweizimmer-Ferienhaus',
            THREE_ROOM_HOLIDAY_HOME: 'Dreizimmer-Ferienhaus',
            FOUR_ROOM_HOLIDAY_HOME: 'Vierzimmer-Ferienhaus',
            FIVE_PLUS_ROOM_HOLIDAY_HOME: 'Fünfzimmer-Ferienhaus und mehr',
            VACATION_RENTAL: 'Ferienwohnung',
            PRIVATE_ROOM: 'Privatzimmer',
            ONE_ROOM_VACATION_RENTAL: 'Einzimmer-Ferienwohnung',
            TWO_ROOM_VACATION_RENTAL: 'Zweizimmer-Ferienwohnung',
            THREE_ROOM_VACATION_RENTAL: 'Dreizimmer-Ferienwohnung',
            FOUR_ROOM_VACATION_RENTAL: 'Vierzimmer-Ferienwohnung',
            FIVE_PLUS_ROOM_VACATION_RENTAL: 'Fünfzimmer-Ferienwohnung und mehr',
            COUNTRY_HOUSE: 'Landhaus',
            MAISONETTE_APARTMENT: 'Maisonette-Apartment',
            MULTI_BED_ROOM: 'Mehrbettzimmer',
            SHIP_DOUBLE_CABIN: 'Schiffsdoppelkabine',
            SHIP_SINGLE_CABIN: 'Schiffseinzelkabine',
            SHIP_HOUSEBOAT: 'Schiffshausboot',
            SHIP_MULTI_BED_CABIN: 'Schiffsmehrbettkabine',
            SHIP_SLEEPING_CABIN: 'Schiffs-Schlafkabine',
            BEACH_BASKET_SLEEPING: 'Strandkorb-Schlafplatz',
            BASEMENT_APARTMENT: 'Kellerwohnung',
            CARAVAN_TRAILER_PARKING: 'Stellplatz für Wohnwagen/Trailer',
            RENTAL_CARAVAN_PARKING: 'Vermietung von Wohnwagenstellplätzen',
            MOBILE_HOME_PARKING: 'Stellplatz für Mobilheim',
            MOTORHOME_PARKING: 'Stellplatz für Wohnmobil',
            TENT_PITCH: 'Zeltplatz',
            STUDIO: 'Studio',
            SUITE: 'Suite',
            VILLA: 'Villa',
        },
        bathEquipments: {
            BATHROOM_WC: 'Badezimmer mit WC',
            BATHTUB: 'Badewanne',
            BIDET: 'Bidet',
            SHOWER: 'Dusche',
            WALK_IN_SHOWER: 'Begehbare Dusche',
            SHOWER_WC: 'Dusche mit WC',
            FLOOR_SHOWER: 'Bodendusche',
            FLOOR_WC: 'Boden-WC',
            GUEST_WC: 'Gäste-WC',
            HAIR_DRYER: 'Haartrockner',
            SOAP: 'Seife',
            TOILET_PAPER: 'Toilettenpapier',
            ADDITIONAL_BATH: 'Zusätzliches Badezimmer',
        },
        kitchenEquipments: {
            OVEN: 'Backofen',
            FREEZER: 'Gefrierschrank',
            COMMUNAL_KITCHEN: 'Gemeinschaftsküche',
            GRILL: 'Grill',
            COFFEE_MACHINE: 'Kaffeemaschine',
            OPEN_KITCHEN: 'Offene Küche',
            PANTRY_MINI_KITCHEN: 'Vorrats-/Miniküche',
            REFRIGERATOR: 'Kühlschrank',
            MICROWAVE: 'Mikrowelle',
            POD_CAPSULE_COFFEE_MACHINE: 'Kaffeemaschine für Kapseln',
            SEPARATE_KITCHEN: 'Separate Küche',
            DISHWASHER: 'Geschirrspüler',
            TOASTER: 'Toaster',
            KETTLE: 'Wasserkocher',
            WINE_COOLER: 'Weinkühler',
        },
        facilities: {
            ALLERGY_FRIENDLY: 'Allergikerfreundlich',
            BALCONY: 'Balkon',
            DISABLED_FRIENDLY: 'Behindertenfreundlich',
            DISABLED_ACCESSIBLE: 'Rollstuhlgerecht',
            MOUNTAIN_VIEW: 'Bergblick',
            DIKE_VIEW: 'Deichblick',
            DOUBLE_BED: 'Doppelbett',
            GROUND_FLOOR: 'Erdgeschoss',
            SINGLE_BED: 'Einzelbett',
            BUNK_BED: 'Etagenbett',
            FIRE_EXTINGUISHER: 'Feuerlöscher',
            FRENCH_BED: 'Französisches Bett',
            SENSORY_IMPAIRED_FRIENDLY: 'Sinnesgeschädigtenfreundlich',
            SEPARATE_BEDS: 'Getrennte Betten',
            HEATING: 'Heizung',
            HIGH_CHAIR: 'Hochstuhl',
            WOODEN_PARQUET_FLOORS: 'Parkettböden aus Holz',
            INFRARED_SAUNA: 'Infrarotsauna',
            INSECT_PROTECTION_MESH: 'Insektenschutzgitter',
            TILE_STOVE: 'Kachelofen',
            FIREPLACE: 'Kamin',
            ETHANOL_GAS_FIREPLACE: 'Ethanol-/Gaskamin',
            AIR_CONDITIONING: 'Klimaanlage',
            AIR_PURIFIER: 'Luftreiniger',
            SEA_VIEW: 'Meerblick',
            NON_SMOKING: 'Nichtraucher',
            PANORAMIC_VIEW: 'Panoramablick',
            POOL: 'Pool',
            HEATED_POOL: 'Beheizter Pool',
            SMOKER_FRIENDLY: 'Raucherfreundlich',
            SMOKE_DETECTOR: 'Rauchmelder',
            TRAVEL_CRIB_CHILD_BED: 'Reisebett/Kinderbett',
            WHEELCHAIR_ACCESSIBLE: 'Rollstuhlgerecht',
            WHEELCHAIR_FRIENDLY: 'Rollstuhlzugänglich',
            SAUNA: 'Sauna',
            SHIP_BERTH: 'Schiffsanlegeplatz',
            SHIP_GALLEY: 'Schiffsküche',
            SHIP_SALON: 'Schiffsalon',
            SOFA_BED: 'Schlafsofa',
            WALL_BED: 'Wandbett',
            DESK: 'Schreibtisch',
            SWIMMING_POOL: 'Swimmingpool',
            LAKE_VIEW: 'Seeblick',
            SEPARATE_PROPERTY: 'Separates Anwesen',
            PERSONAL_BEACH_CHAIR: 'Persönlicher Strandkorb',
            CARPET_FLOORING: 'Teppichboden',
            TERRACE: 'Terrasse',
            STAIR_PROTECTION: 'Treppenschutz',
            BLACKOUT_OPTION: 'Verdunkelungsoption',
            WHIRLPOOL: 'Whirlpool',
            LIVING_SLEEPING_COMBINATION: 'Wohn-Schlaf-Kombination',
        },
        technics: {
            DVD_BLURAY_PLAYER: 'DVD-/Blu-ray-Player',
            CABLE_SATELLITE: 'Kabel-/Satellitenfernsehen',
            MUSIC_SYSTEM: 'Musiksystem',
            SMART_TV: 'Smart-TV',
            GAME_CONSOLE: 'Spielkonsole',
            STREAMING_SERVICES: 'Streaming-Dienste',
            TV: 'Fernseher',
            FLAT_SCREEN_TV: 'Flachbildfernseher',
            WIFI: 'WLAN',
            IRON: 'Bügeleisen',
            DRYER: 'Trockner',
            WASHING_MACHINE: 'Waschmaschine',
        },
        targetGroups: {
            SECLUSION_PEACE: 'Abgeschiedenheit und Ruhe',
            FISHING_HUNTING: 'Angeln und Jagen',
            CAR_RENTAL: 'Autovermietung',
            FARM_STAY: 'Bauernhofurlaub',
            MOUNTAIN_VACATION: 'Bergurlaub',
            SPECIAL_ACCOMMODATIONS: 'Besondere Unterkünfte',
            FAMILY_CHILDREN: 'Familie und Kinder',
            HOLIDAYS: 'Ferien',
            BUSINESS_TRAVELERS: 'Geschäftsreisende',
            GOLF: 'Golf',
            GOURMET: 'Gourmet',
            CLIMBING: 'Klettern',
            CULTURE: 'Kultur',
            COUNTRYSIDE_VACATION: 'Landurlaub',
            LONG_TERM_RENTERS: 'Langzeitmieter',
            LUXURY_VACATION: 'Luxusurlaub',
            MEDICAL_WELLNESS: 'Medizin und Wellness',
            SEA_VIEW: 'Meerblick',
            MERCHANDISING_ITEMS: 'Merchandising-Artikel',
            MOTORCYCLE: 'Motorrad',
            NATIONAL_PARK: 'Nationalpark',
            NATURE: 'Natur',
            PACKAGE_DEALS: 'Pauschalangebote',
            CYCLING: 'Radfahren',
            TRAVEL_GROUPS: 'Reisegruppen',
            HORSEBACK_RIDING: 'Reiten',
            WHEELCHAIR_USERS: 'Rollstuhlfahrer',
            ROMANTIC_GETAWAY: 'Romantisches Wochenende',
            LAKE_VIEW: 'Seeblick',
            SENIORS: 'Senioren',
            SKATING: 'Skaten',
            OTHERS: 'Sonstige',
            SPORTS_AND_GAMES: 'Sport und Spiele',
            CITY_BREAK: 'Städtereise',
            BEACH_HOLIDAY: 'Strandurlaub',
            CONFERENCE_MEETING: 'Tagung und Konferenz',
            DIVING: 'Tauchen',
            TICKETS: 'Tickets',
            LAKE_VACATION: 'Urlaub am See',
            HISTORICAL_BUILDING_VACATION: 'Urlaub in historischem Gebäude',
            DOG_FRIENDLY_VACATION: 'Urlaub mit Hund',
            POOL_VACATION: 'Urlaub mit Pool',
            HIKING_WALKING: 'Wandern und Spazieren',
            WATER_SPORTS: 'Wassersport',
            WINE_VACATION: 'Weinurlaub',
            WELLNESS_FITNESS_HEALTH: 'Wellness, Fitness und Gesundheit',
            WINTER_SPORTS: 'Wintersport',
        },

        objectTypes: {
            APARTHOTEL: 'Aparthotel',
            APARTMENT: 'Apartment',
            APARTMENT_COMPLEX: 'Apartmentkomplex',
            FARM: 'Bauernhof',
            BED_AND_BREAKFAST: 'Bed and Breakfast',
            MOUNTAIN_GUESTHOUSE_HOF: 'Berggasthaus/Hof',
            SPECIAL_ACCOMMODATION: 'Besondere Unterkunft',
            EDUCATIONAL_INSTITUTION: 'Bildungseinrichtung',
            BOATHOUSE: 'Bootshaus',
            BUNGALOW: 'Bungalow',
            CAMPSITE: 'Campingplatz',
            CARAVAN_SITE: 'Wohnwagenstellplatz',
            CHALET: 'Chalet',
            CLUB: 'Club',
            RECREATIONAL_HOME: 'Erholungsheim',
            HOLIDAY_VILLAGE: 'Ferienpark',
            HOLIDAY_HOME: 'Ferienhaus',
            HOLIDAY_RESIDENCE_PARK: 'Ferienwohnpark',
            HOLIDAY_APARTMENT: 'Ferienwohnung',
            HOLIDAY_CENTER: 'Ferienzentrum',
            HOLIDAY_ROOM_PRIVATE_ROOM: 'Ferienzimmer/Privatzimmer',
            FINCA: 'Finca',
            GUEST_APARTMENTS: 'Gästeapartments',
            INN: 'Gasthof',
            MANOR_HOUSE: 'Herrenhaus',
            HAY_HOSTEL: 'Heuherberge',
            HOSTEL: 'Hostel',
            HOTEL: 'Hotel',
            BED_AND_BREAKFAST_HOTEL: 'Hotel mit Frühstück',
            HOTEL_PENSION: 'Hotelpension',
            CABIN: 'Hütte',
            YOUTH_HOSTEL: 'Jugendherberge',
            YOUTH_HOTEL: 'Jugendhotel',
            CLINIC: 'Klinik',
            HEALTH_RESORT: 'Kurort',
            MOTEL: 'Motel',
            GUESTHOUSE: 'Pension',
            PENTHOUSE: 'Penthouse',
            THATCHED_ROOF_HOUSE: 'Reetdachhaus',
            REHABILITATION_CLINIC: 'Rehabilitationsklinik',
            TERRACED_SEMI_DETACHED_HOUSE: 'Reihen-/Doppelhaus',
            RIDING_FARM: 'Reiterhof',
            RESORT_HOTEL: 'Resorthotel',
            SHIP_HOUSEBOAT: 'Schiffshausboot',
            SHIP_SALON: 'Schiffssalon',
            SLEEPING_BEACH_BASKET: 'Schlafstrandkorb',
            CASTLE: 'Schloss',
            TRAINING_CENTER: 'Schulungszentrum',
            SELF_CATERING_COTTAGE: 'Selbstversorgerhütte',
            TINY_HOUSE: 'Tiny House',
            VILLA: 'Villa',
        },
        outdoorEquipments: {
            BOAT_PIER: 'Bootsteg',
            BUS_PARKING: 'Busparkplatz',
            BICYCLE_STORAGE_FACILITY: 'Fahrradstellplatz',
            GARAGE: 'Garage',
            GARDEN_FOR_USE: 'Garten zur Nutzung',
            COMMUNAL_POOL: 'Gemeinschaftspool',
            PRIVATE_BEACH_LAKE_ACCESS: 'Privatstrand/Seezugang',
            PARKING: 'Parkplatz',
            HEATED_POOL: 'Beheizter Pool',
            SWIMMING_POND: 'Schwimmteich',
            GARDEN_SEATING_AREA: 'Gartensitzbereich',
            BEACH_CHAIR: 'Strandkorb',
            UNDERGROUND_GARAGE: 'Tiefgarage',
            FENCED_PROPERTY: 'Umzäuntes Grundstück',
        },
    };

    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    const { user } = useAuth();


    const { id, bookingObjectSeasonProperties, ...restFullState } = category;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState
    });

    useEffect(() => {
        const { id, bookingObjectSeasonProperties, ...restFullState } = category;

        setFullStateEdit({
            ...restFullState
        });

    }, [category]);


    const openDescriptionModal = () => {
        setIsDescriptionModalOpen(true);
    };

    const closeDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
    };

    const submitDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
        saveChanges();
    };

    const saveChanges = async () => {
        try {
            await CategoryService.putCategory(category.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    const toggleCategoryItem = (categoryItem) => {
        setFullStateEdit(prevState => {
            const newCategoryItems = prevState[tag].includes(categoryItem)
                ? prevState[tag].filter(c => c !== categoryItem)
                : [...prevState[tag], categoryItem];

            return {
                ...prevState,
                [tag]: newCategoryItems,
            };
        });
    };


    return (
        <div className="row" style={{zIndex: "1051"}}>
            <div className="col-9 d-flex align-items-center">
                <div className="olap-description-text d-flex flex-wrap gap-2">
                    {fullStateEdit[tag].map((categoryItem) => (
                        <span className={'badge bg-success-subtle text-success badge-border'} key={categoryItem}>
                            {categoryEnums[tag][categoryItem]}{' '}
                        </span>
                    ))}
                </div>
            </div>
            <div className="col-3">
                <div onClick={openDescriptionModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <RiEditLine />
                </div>
                <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isDescriptionModalOpen ? 'show' : ''}`}
                     style={{ display: isDescriptionModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <h5 className='modal-title'>
                                    Kategorie bearbeiten
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeDescriptionModal} />
                            </div>
                            <div className='modal-body'>
                                <div>
                                    <div className={'row'}>
                                        {Object.keys(categoryEnums[tag]).map((categoryItem) => (
                                            <div key={categoryItem} className={'col-lg-6'}>
                                                <label className={"cursor-pointer"}>
                                                    <input
                                                        className={"form-check-input"}
                                                        type='checkbox'
                                                        value={categoryItem}
                                                        checked={fullStateEdit[tag].includes(categoryItem)}
                                                        onChange={() => toggleCategoryItem(categoryItem)}
                                                    />
                                                    <span className={"ms-1"}>{categoryEnums[tag][categoryItem]}</span>
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                            <span className='btn btn-light' onClick={closeDescriptionModal}>
                                Abbrechen
                            </span>
                                <span className='btn btn-success' onClick={submitDescriptionModal}>
                                Speichern
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryCategoriesModal;

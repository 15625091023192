import React from 'react';
import ProfileContentTabs from "./ProfileContentTabs";
import ProfileContentTabDetails from "./ProfileContentTabDetails";

const ProfileContent = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div>
                    <ProfileContentTabs />
                    <ProfileContentTabDetails />
                </div>
            </div>
        </div>
    );
};

export default ProfileContent;
import React, {useEffect, useState} from 'react';
import { Link, useParams } from 'react-router-dom';
import {RiEdit2Line} from "react-icons/ri";
import CategoryService from "../../services/CategoryService";
import {useAuth} from "../../hooks/useAuth";

const ManagementSidebarSubEntry = ({ item, parent }) => {
    const {user} = useAuth();
    const { categoryId } = useParams();
    const [isEditing, setIsEditing] = useState(false);

    const [state, setState] = useState(
        item
    );

    useEffect(() => {
        setState(item)
    }, [item]);

    const change = (e) => {
        const newName = e.target.value;
        setState((prevState) => ({ ...prevState, name: newName }));
    };

    const activateEditing = () => {
        if (parseInt(categoryId) === item.id) {
            setIsEditing(true);
        }
    };

    const saveChanges = async () => {
        try {
            setIsEditing(false);
            await CategoryService.putCategory(item.id, user, state);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    return (
        <li>
            <Link to={`/management/place/${parent.id}/category/${item.id}`}>
                {isEditing ? (
                    <input
                        type="text"
                        className="form-control form-control-edit"
                        value={state.name}
                        onChange={change}
                        onBlur={saveChanges}
                        autoFocus={true}
                    />
                ) : (
                    <span
                        className={`${parseInt(categoryId) === item.id ? 'active' : ''}`}
                        onClick={activateEditing}
                    >
                        {state.name}
                        <i>
                            <RiEdit2Line className='ms-1' />
                        </i>
                    </span>
                )}
            </Link>
        </li>
    );
};

export default ManagementSidebarSubEntry;
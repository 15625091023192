import React from 'react';
import {Link, useLocation } from 'react-router-dom';

const NavLink = ({ icon, to = '/', title = '', badge, watchers}) => {

    const location = useLocation();
    const isActive = location.pathname.startsWith(to);

    return (
        <li className="nav-item">
            <Link
                className={`nav-link menu-link ${isActive ? 'active' : ''}`}
                role='button'
                aria-expanded='false'
                to={to}
            >
                {icon}
                <span data-key='t-dashboards'>{title}</span>
            </Link>
        </li>
    );
};

export default NavLink;

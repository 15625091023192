import React, {useEffect, useState} from "react";
import {useDrag} from "react-dnd";
import ObjectsAndSeasonsDraggableSeason from "./ObjectsAndSeasonsDraggableSeason";
import {useAuth} from "../../hooks/useAuth";
import SeasonService from "../../services/SeasonService";
import Loader from "../../components/Loader";
import {useLocation} from "react-router-dom";

const ObjectsAndSeasonsDraggableCalendar  = ({item}) => {
    const [loading, setLoading] = useState(true);
    const [seasons, setSeasons] = useState([]);
    const { user } = useAuth();

    let location = useLocation();

    const fetchSeasons = async () => {
        const response = await SeasonService.getSeasons(item.id, user);
        setSeasons(response.data);
        setLoading(false);
    };

    useEffect(() => {
        fetchSeasons();
    }, [location]);

    const [{ isDragging }, drag] = useDrag({
        type: 'CALENDAR',
        item: { id: item.id, name: item.name, type: 'CALENDAR', seasons: seasons },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    });
    const opacity = isDragging ? 0.4 : 1

    return (
        <div>
            <div ref={drag} style={{ opacity }} className="card bg-light m-1 draggable">
                <div className="card-body">
                    {item.name} ({item.year})
                </div>
            </div>
            <div className="row ms-2">
                <div className="col-12">
                    {loading ? (
                        <Loader card={false} />
                    ) : (
                        seasons.map((item, index) => (
                            <ObjectsAndSeasonsDraggableSeason key={index} item={item} />
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default ObjectsAndSeasonsDraggableCalendar ;
import React from 'react';
import { Link } from 'react-router-dom';
import LoginFooter from "../parts/LoginFooter";

const ThankYouPage = () => {
    return (
        <div className='auth-page-wrapper pt-5'>
            <div className='auth-one-bg-position auth-one-bg' id='auth-particles'>
                <div className='bg-overlay'></div>

                <div className='shape'>
                    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1440 120'>
                        <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z'></path>
                    </svg>
                </div>
            </div>
            <div className='auth-page-content'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8 col-lg-6 col-xl-5'>
                            <div className='card mt-4'>
                                <div className='card-body p-4'>
                                    <div className='text-center mt-2'>
                                        <h5 className='text-primary'>Thank You!</h5>
                                        <p className='text-muted'>Check your mails to continue.</p>
                                    </div>
                                    <div className='p-2 text-center'>
                                        <p className='mb-0'>
                                            Thank you for your registration. You have received a confirmation email from us, with which you can subsequently set your password.

                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4 text-center'>
                                <p className='mb-0'>
                                    <Link
                                        to='/login'
                                        className='fw-semibold link-white text-primary text-decoration-underline'
                                    >
                                        Back to Login
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoginFooter />
        </div>
    );
};

export default ThankYouPage;

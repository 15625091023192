import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {RiArrowDownSLine} from "react-icons/ri";
import {useAuth} from "../../hooks/useAuth";
import ObjectService from "../../services/ObjectService";
import ObjectsAndSeasonsDnDObjects from "./ObjectsAndSeasonsDnDObjects";
import $ from "jquery";
import {GrObjectGroup} from "react-icons/gr";
import {useDrop} from "react-dnd";
import PropertiesService from "../../services/PropertiesService";
import ObjectsAndSeasonsDnDDeleteModal from "./ObjectsAndSeasonsDnDDeleteModal";

const ObjectsAndSeasonsDnDCategories = ({item, parent, newFetchCategories}) => {
    const [state] = useState({
        bookingProperties: {
        }
    })

    const [errors, setErrors] = useState('');
    const [objects, setObjects] = useState([]);

    let location = useLocation();
    const { user } = useAuth();

    const linkRef = useRef();
    const collapseRef = useRef();

    const [, drop] = useDrop({
        accept: ['CALENDAR', 'SEASON'],
        drop: async (droppedItem) => {
            // Handle the dropped item, e.g., add it to the state or perform some action

            if(droppedItem.type === "CALENDAR") {
                const promises = droppedItem.seasons.map(async (season) => {
                    await addSeasonToCategory(season.id, item.id, season);
                });
                await Promise.all(promises);
                newFetchCategories();
            } else{
                await addSeasonToCategory(droppedItem.id, item.id, droppedItem);
                newFetchCategories();
            }
        },
    });

    const fetchObjects = async () => {
        const response = await ObjectService.getObjects(item.id, user);
        const filteredObjects = response.data.filter((item) => {
            if (!item.channels || item.channels.length === 0) {
                return true;
            }
            return !item.channels[0].channelDataMap.deleted;
        });
        setObjects(filteredObjects);
    };

    useEffect(() => {
        fetchObjects();
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    const newFetch = () => {
        fetchObjects();
    }

    const validate = (season) => {
        const seasonDateRanges = season.dateRanges;
        const categoryDateRanges = item.bookingCategorySeasonProperties.map((property) => property.season.dateRanges).flat();

        for (let i = 0; i < seasonDateRanges.length; i++) {
            for (let j = 0; j < categoryDateRanges.length; j++) {
                if (seasonDateRanges[i].dateFrom <= categoryDateRanges[j].dateTo && seasonDateRanges[i].dateTo >= categoryDateRanges[j].dateFrom) {
                    return false;
                }
            }
        }
        return true;
    };

    const addSeasonToCategory = async (seasonId, categoryId, season) => {
        if (validate(season)){
            try {
                await PropertiesService.postSeasonCategoryProperty(seasonId, categoryId, user, state);
            } catch (err) {
                setErrors(err.response.data.errors);
            }
            return false;
        } else {
            setErrors('Die Objektkategorie hat diese Saison bereits.');
        }
    };

    const deleteSeasonCategory = async (seasonId, categoryId) => {
        try {
            await PropertiesService.deleteSeasonCategoryProperty(seasonId, categoryId, user);
        } catch (err) {
            //TODO
            throw err;
        }
        return false;
    };

    const submitDelete = async (seasonId) => {
        await deleteSeasonCategory(seasonId, item.id);
        newFetchCategories();
    }
    const click = (e) => {
        if (linkRef.current.classList.contains('active')) {
            linkRef.current.classList.remove('active');
            linkRef.current.setAttribute('aria-expanded', 'false');
            $(collapseRef.current).slideUp();
        } else {
            linkRef.current.classList.add('active');
            linkRef.current.setAttribute('aria-expanded', 'true');
            $(collapseRef.current).slideDown();
        }
    };

    useEffect(() => {
        const errorTimer = setTimeout(() => {
            setErrors('');
        }, 3000);

        return () => {
            clearTimeout(errorTimer);
        };
    }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <li className="li-unstyled li-unstyled-30">
            <Link to="">
                <GrObjectGroup className="me-2"/>
                <span>
                    {item.name} (Kategorie)
                </span>
            </Link>
            <span
                className="ms-2 olap-sidebar-button"
                aria-expanded='false'
                ref={linkRef}
                onClick={click}
            >
                <span className="icon"><RiArrowDownSLine /></span>
            </span>
            <div ref={drop} className='droparea'>
                <div className="row">
                    {item.bookingCategorySeasonProperties.map((season, index) => (
                        <div className="col-auto pe-0" key={index}>
                            <div className="card bg-light mb-0">
                                <div className="card-body">
                                    {season.season.name}
                                    <ObjectsAndSeasonsDnDDeleteModal parentName={item.name} item={season} submitDelete={submitDelete} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className='menu-dropdown' ref={collapseRef} style={{display: "none"}}>
                <ul className='sub-menu list-unstyled' id='folderlist-data'>
                    {objects.map((child, idx) => (
                        <ObjectsAndSeasonsDnDObjects newFetch={newFetch} grandparent={parent} parent={item} item={child} key={idx} />
                    ))}
                </ul>
            </div>
            <div className={`toastify ${errors ? 'on' : ''} bg-danger toastify-center toastify-top`} aria-live="polite" style={{transform: "translate(0px, 0px)", top: "15px"}}>Die Objektkategorie "{item.name}" hat diese Saison bereits oder hat überlappende Datumswerte.</div>
        </li>
    )
}

export default ObjectsAndSeasonsDnDCategories;
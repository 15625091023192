import React from 'react';
import {AiFillHeart} from "react-icons/ai";

const LoginFooter = () => {
    return (
        <footer className='footer'>
            <div className='container'>
                <div className='row'>
                    <div className='col-lg-12'>
                        <div className='text-center'>
                            <p className='mb-0 text-muted'>
                                ©2024 Camppartner. Entwickelt mit <AiFillHeart className='text-success' /> von orcas
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default LoginFooter;

import React, { Fragment } from 'react';
import ManagementSidebar from '../ManagementSidebar';
import ObjectManagementHeader from '../ObjectManagementHeader';
import CategoryManagementContent from './CategoryManagementContent';
import Tutorial from "../Tutorial";
import NewObjectNotification from "./NewObjectNotification";

const CategoryManagement = () => {
    return (
        <Fragment>
            <ObjectManagementHeader />
            <div className='chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1'>
                <ManagementSidebar />
                <CategoryManagementContent />
            </div>
            <Tutorial />
            <NewObjectNotification />
        </Fragment>
    );
};

export default CategoryManagement;

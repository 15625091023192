import React, {useEffect, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import serviceService from "../../services/ServiceService";
import { useParams, useLocation } from 'react-router-dom';
import {RiCloseLine, RiEditLine} from "react-icons/ri";
import OlapTooltip from "./OlapTooltip";
import {useOrganization} from "../../hooks/useOrganization";
import {isEmpty} from "../../utils/validation";

const ServiceModal = ({service, onSubmit, seasonIds}) => {
    const [errors, setErrors] = useState({});
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
    const [isServiceDeleteModalOpen, setIsServiceDeleteModalOpen] = useState(false);

    const { user } = useAuth();
    const { placeId, categoryId, objectId } = useParams();
    const {organization} = useOrganization();

    const location = useLocation();
    const path = location.pathname;

    const [state, setState] = useState({
        ...service
    });

    useEffect(() => {
        setState(
            {
                ...service
            }
        );
    }, [service]);

    const openServiceModal = () => {
        setIsServiceModalOpen(true);
    };

    const closeServiceModal = () => {
        setIsServiceModalOpen(false);
    };

    const submitServiceModal = () => {
        if (validate()) {
            setIsServiceModalOpen(false);
            saveChanges();
        }
    };

    const openServiceDeleteModal = () => {
        setIsServiceDeleteModalOpen(true);
    };

    const closeServiceDeleteModal = () => {
        setIsServiceDeleteModalOpen(false);
    };

    const submitServiceDeleteModal = () => {
        setIsServiceDeleteModalOpen(false);
        deleteService();
    };


    const deleteService = async () => {
        try {
            await serviceService.deleteOrganizationService(organization.id, service.id, user);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    const validate = () => {
        let hasErrors = false;
        let errors = {};

        // Validate minAge and maxAge minAge must be less than maxAge
        if (state.ageMin != null && state.ageMin !== '' && state.ageMax != null && state.ageMax !== '') {
            if (state.ageMin >= state.ageMax) {
                errors['ageMin'] = 'Mindestalter muss kleiner als Höchstalter sein';
                hasErrors = true;
            }
        }

        //validate customizableType if is kurtaxe then automatically need to be true
        if (state.customizableType === 'TOURIST_TAX' && !state.addedAutomatically) {
            errors['kurtaxe'] = 'Kurtaxe kann nur ausgewählt werden, wenn die Leistung automatisch gewählt wird.';
            hasErrors = true;
        }

        //validate customizableType if is kurtaxe then hidden need to be false
        if (state.customizableType === 'TOURIST_TAX' && state.hidden) {
            errors['kurtaxeHidden'] = 'Kurtaxe kann nicht ausgewählt werden, wenn die Leistung ausgeblendet wird.';
            hasErrors = true;
        }

        if (state.customizableType === 'CAR' && state.hidden) {
            errors['carHidden'] = 'KFZ-Kennzeichen kann nicht ausgewählt werden, wenn die Leistung ausgeblendet wird.';
            hasErrors = true;
        }

        //validate name can not be empty
        if (isEmpty(state.name[0].text)) {
            errors['name'] = 'Name darf nicht leer sein';
            hasErrors = true;
        }

        setErrors(errors);

        return !hasErrors;

    };

    const saveChanges = async () => {
        state.name[0].text = state.name[0].text.trim();
        state.description[0].text = state.description[0].text.trim();
        try {
            await serviceService.putOrganizationService(organization.id, service.id, user, state);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    const addService = async () => {
        if (path.endsWith('season')) {
            for (const seasonId of seasonIds) {
                if(objectId){
                    try {
                        await serviceService.postObjectSeasonService(objectId, seasonId, service.id, user);
                        onSubmit();
                    } catch (err) {
                        throw err;
                    }
                } else{
                    try {
                        await serviceService.postCategorySeasonService(categoryId, seasonId, service.id, user);
                        onSubmit();
                    } catch (err) {
                        throw err;
                    }
                }
            }
        } else {
            if(objectId){
                try {
                    await serviceService.postObjectServices(objectId, service.id, user);
                    onSubmit();
                } catch (err) {
                    throw err;
                }
            } else{
                try {
                    await serviceService.postCategoryServices(categoryId, service.id, user);
                    onSubmit();
                } catch (err) {
                    throw err;
                }
            }
        }

    }

    const change = (e) => {
        const { name, value, type, checked } = e.target;

        setState((prevState) => {
            if (type === 'checkbox') {
                if (name === 'customerType') {
                    const updatedCustomerType = checked ? 'PRIVATE' : 'BOTH';

                    return {
                        ...prevState,
                        customerType: updatedCustomerType,
                    };
                } else if (name === 'addedAutomatically' && checked) {
                    return {
                        ...prevState,
                        addedAutomatically: true,
                        inclusive: false,
                    };
                } else if (name === 'inclusive' && checked) {
                    return {
                        ...prevState,
                        addedAutomatically: false,
                        inclusive: true,
                        hidden: false,
                    };
                } else if (name === 'hidden' && checked) {
                    return {
                        ...prevState,
                        addedAutomatically: true,
                        inclusive: false,
                        hidden: true,
                    };
                } else {
                    return {
                        ...prevState,
                        [name]: checked,
                    };
                }
            } else if (name === 'name' || name === 'description') {
                return {
                    ...prevState,
                    [name]: [
                        {
                            locale: 'de',
                            text: value,
                        },
                    ],
                };
            } else if (name === 'price') {
                if (value.includes('.')){
                    const split = value.split('.');
                    if (split[1].length > 2) {
                        return {
                            ...prevState
                        };
                    }
                }
                if (value.includes(',')){
                    const split = value.split(',');
                    if (split[1].length > 2) {
                        return {
                            ...prevState
                        };
                    }
                }
                return {
                    ...prevState,
                    [name]: value,
                };
            } else if (name === "ageMin" || name === "ageMax" || name === "maximumBookable"){
                //only positive full numbers
                if (!isNaN(value) && value >= 0 && value % 1 === 0) {
                    return {
                        ...prevState,
                        [name]: value,
                    };
                } else {
                    return {
                        ...prevState
                    };
                }

            } else {
                return {
                    ...prevState,
                    [name]: value,
                };
            }

        });
    };

    const validatePriceOnBlur = (e) => {
        const {name, value} = e.target;
        setState((prevState) => {
            if (name === 'price') {
                if (value === '') {
                    return {
                        ...prevState,
                        price: 0,
                    };
                } else{
                    return {
                        ...prevState
                    };

                }
            }
        });
    }

    const validateAgeOnBlur = (e) => {
        const {name, value} = e.target;
        //maximum 150 if over 150 then 150
        setState((prevState) => {
            if (value === '') {
                return {
                    ...prevState,
                    [name]: '',
                };
            } else if (value > 150) {
                return {
                    ...prevState,
                    [name]: 150,
                };
            } else {
                return {
                    ...prevState
                };
            }
        });
    }

    return (
        <div className={'row'} style={{zIndex: "1051"}}>
            <div className="col-10">
                <div className="olap-description-text d-flex flex-wrap gap-2">
                    {state.price ? `${state.price}€, `: '0€'}<br/>
                    {state.inclusive && 'inkl., '}
                    {state.paymentInterval === 'PER_BOOKING' ? 'pro Buchung, ' : state.paymentInterval === 'PER_DAY' ? 'pro Tag, ' : 'pro Stunde, '}
                    {state.serviceCalculationType === 'PER_BOOKING' ? 'p.B., ' : state.serviceCalculationType === 'PER_PERSON' ? 'p.P., ' : state.serviceCalculationType === 'PER_ROOM_OR_OBJECT' ? 'p.Z., ' : ''}
                    {state.ageMin ? `>=${state.ageMin}, ` : ''}
                    {state.ageMax ? `<=${state.ageMax}, ` : ''}
                    {state.addedAutomatically ? `autom., ` : ''}
                    {state.hidden ? `ausbl., ` : ''}
                    {state.onSite ? `nur v. Ort, ` : ''}
                </div>
            </div>
            <div className={'col-2'}>
                <div className={'row'}>
                    <div className={'col-4'}>
                        <div onClick={addService} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2">
                            +
                        </div>
                    </div>
                    <div className={'col-4'}>
                        <div onClick={openServiceModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                            <RiEditLine />
                        </div>
                        <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isServiceModalOpen ? 'show' : ''}`}
                             style={{ display: isServiceModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                            {isServiceModalOpen && (
                                <div className="modal-dialog modal-lg">
                                    <div className="modal-content">
                                        <div className='modal-header'>
                                            <h5 className='modal-title'>
                                                Leistung bearbeiten
                                            </h5>
                                            <button type='button' className='btn-close' aria-label='Close'
                                                    onClick={closeServiceModal}/>
                                        </div>
                                        <div className='modal-body'>
                                            <div className='row'>
                                                <div className='col-md-12'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='name' className='form-label'>
                                                            Name der Leistung
                                                            <OlapTooltip
                                                                Text={'Der Name der Leistung wird in der Buchungsmaske angezeigt.'}/>
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className={`form-control`}
                                                            name='name'
                                                            value={state.name[0] && state.name[0].text ? state.name[0].text : ''}
                                                            onChange={change}
                                                        />
                                                    </div>
                                                </div>
                                                {errors.name && <div className='col-md-12 mt-0'><div className='invalid-feedback mt-0 mb-3' style={{display: "block"}}>{errors.name}</div></div>}
                                                <div className='col-md-12'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='description' className='form-label'>
                                                            Beschreibung
                                                            <OlapTooltip
                                                                Text={'Der Hinweis, der dem Nutzer für diese Leistung angezeigt wird.'}/>
                                                        </label>
                                                        <textarea
                                                            className='form-control'
                                                            name='description'
                                                            value={state.description[0].text}
                                                            onChange={change}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='price' className='form-label'>
                                                            Preis
                                                            <OlapTooltip
                                                                Text={'Der Preis der Leistung des Objektes oder der Objektkategorie, in einer Saison oder einem bestimmten Tag'}/>
                                                        </label>
                                                        <input
                                                            type='number'
                                                            step='0.01'
                                                            className={`form-control`}
                                                            name='price'
                                                            value={state.price}
                                                            onChange={change}
                                                            onBlur={validatePriceOnBlur}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'e' || e.key === 'E' || e.key === '+') {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='paymentInterval' className='form-label'>
                                                            Berechnungsintervall
                                                            <OlapTooltip
                                                                Text={'Berechnungsintervall, Berechnung erfolgt pro Zeiteinheit'}/>
                                                        </label>
                                                        <select
                                                            className='form-select'
                                                            name='paymentInterval'
                                                            value={state.paymentInterval}
                                                            onChange={change}
                                                        >
                                                            <option value='PER_BOOKING'>pro Buchung</option>
                                                            <option value='PER_DAY'>pro Tag</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='serviceCalculationType' className='form-label'>
                                                            Berechnungseinheit
                                                            <OlapTooltip
                                                                Text={'Berechnung erfolgt pro Berechnungseinheit'}/>
                                                        </label>
                                                        <select
                                                            className='form-select'
                                                            name='serviceCalculationType'
                                                            value={state.serviceCalculationType}
                                                            onChange={change}
                                                        >
                                                            <option value='PER_BOOKING'>pro Buchung</option>
                                                            <option value='PER_OBJECT'>pro Objekt</option>
                                                            <option value='PER_PERSON'>pro Person</option>
                                                            <option value='PER_PERSON_OVER_STANDARD'>pro Person über
                                                                Standard
                                                            </option>
                                                            <option value='PER_PERSON_BELOW_STANDARD'>pro Person unter
                                                                Standard
                                                            </option>
                                                            <option value='ONLY_IF_PET '>Nur wenn Tiere</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='maximumBookable' className='form-label'>
                                                            Maximale Anzahl
                                                            <OlapTooltip
                                                                Text={'Optionale Angabe zur maximal verfügbaren Anzahl dieser Leistung'}/>
                                                        </label>
                                                        <input
                                                            type='number'
                                                            className={`form-control`}
                                                            name='maximumBookable'
                                                            value={state.maximumBookable}
                                                            onChange={change}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',') {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='ageMin' className='form-label'>
                                                            Alter von
                                                            <OlapTooltip
                                                                Text={'Optionale Angabe, ab welchem Gastalter diese Leistung anwendbar ist z. B. "18" für Leistungen für Erwachsene'}/>
                                                        </label>
                                                        <input
                                                            type='number'
                                                            className={`form-control`}
                                                            name='ageMin'
                                                            value={state.ageMin}
                                                            onChange={change}
                                                            onBlur={validateAgeOnBlur}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',') {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='ageMax' className='form-label'>
                                                            Alter bis
                                                            <OlapTooltip
                                                                Text={'Optionale Angabe, bis zu welchem Gastalter diese Leistung anwendbar ist z. B. "3" für Leistungen fürBabys'}/>
                                                        </label>
                                                        <input
                                                            type='number'
                                                            className={`form-control`}
                                                            name='ageMax'
                                                            value={state.ageMax}
                                                            onChange={change}
                                                            onBlur={validateAgeOnBlur}
                                                            onKeyDown={(e) => {
                                                                if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',') {
                                                                    e.preventDefault();
                                                                }
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                                {errors.ageMin && <div className='col-md-12 mt-0'><div className='invalid-feedback mt-0 mb-3' style={{display: "block"}}>{errors.ageMin}</div></div>}
                                                <div className='col-md-6'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='addedAutomatically'
                                                               className='form-check-label'>
                                                            Automatisch gewählt
                                                            <OlapTooltip
                                                                Text={'Leistung wird bei jeder Buchung automatisch mit ausgewählt.'}/>
                                                        </label>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input ms-2 cursor-pointer'
                                                            name='addedAutomatically'
                                                            id={'addedAutomatically'}
                                                            checked={state.addedAutomatically}
                                                            onChange={change}
                                                            disabled={state.hidden || state.onSite}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='inclusive' className='form-check-label'>
                                                            Inklusive
                                                            <OlapTooltip
                                                                Text={'Leistung ist im Übernachtungspreis enthalten. Bedeutet auch keine Bettensteuer/keine Kurtaxe/keine Provision.'}/>
                                                        </label>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input ms-2 cursor-pointer'
                                                            name='inclusive'
                                                            id={'inclusive'}
                                                            checked={state.inclusive}
                                                            onChange={change}
                                                            disabled={state.hidden || state.onSite}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='hidden' className='form-check-label'>
                                                            Ausblenden
                                                            <OlapTooltip
                                                                Text={'Leistung wird mit in den Übernachtungspreis eingerechnet und ausgeblendet, wird also nicht als separate Position ausgegeben'}/>
                                                        </label>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input ms-2 cursor-pointer'
                                                            id={'hidden'}
                                                            name='hidden'
                                                            checked={state.hidden}
                                                            onChange={change}
                                                            disabled={state.inclusive || state.onSite}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='onSite' className='form-check-label'>
                                                            Nur vor Ort
                                                            <OlapTooltip
                                                                Text={'Leistung wird als mögliche Leistung angezeigt, kann aber online nicht gebucht werden'}/>
                                                        </label>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input ms-2 cursor-pointer'
                                                            id={'onSite'}
                                                            name='onSite'
                                                            checked={state.onSite}
                                                            onChange={change}
                                                            disabled={state.inclusive || state.addedAutomatically || state.hidden}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='tax' className='form-label'>
                                                            Steuer
                                                            <OlapTooltip Text={'Leistung ist steuerpflichtig (nur notwendig, wenn der Rechnungsersteller/Vermieter umsatzsteuerpflichtig ist)'}/>
                                                        </label>
                                                        <select
                                                            className='form-select'
                                                            name='tax'
                                                            value={state.tax}
                                                            onChange={change}
                                                        >
                                                            <option value={0}>0%</option>
                                                            <option value={7}>7%</option>
                                                            <option value={19}>19%</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className='col-md-12 d-none'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='customerType' className='form-check-label'>
                                                            Nicht für geschäftliche Buchungen
                                                            <OlapTooltip
                                                                Text={'Die Leistung wird nicht berechnet, wenn die Buchung eine geschäftliche Buchung ist.'}/>
                                                        </label>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input ms-2 cursor-pointer'
                                                            id={'customerType'}
                                                            value={'PRIVATE'}
                                                            name='customerType'
                                                            checked={state.customerType.includes('PRIVATE')}
                                                            onChange={change}
                                                        />
                                                    </div>
                                                </div>
                                                <div className='col-md-12'>
                                                    <div className={'mb-3'}>
                                                        <label htmlFor='customizableType' className='form-label'>
                                                            Zusätzliches Feld
                                                            <OlapTooltip
                                                                Text={'Hier können Sie ein zusätzliches Feld angeben, indem Ihre Kunden zum Beispiel ein KFZ Kennzeichen angeben können. Für Kurtaxe legen sie bitte ein extra Service "Kurtaxe" an. Der Preis darf nicht 0€ sein.'}/>
                                                        </label>
                                                        <select
                                                            className='form-select'
                                                            name='customizableType'
                                                            value={state.customizableType}
                                                            onChange={change}
                                                        >
                                                            <option value='NONE'>Keine</option>
                                                            <option value='CAR'>KFZ Kennzeichen</option>
                                                            <option value='TOURIST_TAX'>Kurtaxe</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                {errors.kurtaxe && <div className='col-md-12 mt-0'><div className='invalid-feedback mt-0 mb-3' style={{display: "block"}}>{errors.kurtaxe}</div></div>}
                                                {errors.kurtaxeHidden && <div className='col-md-12 mt-0'><div className='invalid-feedback mt-0 mb-3' style={{display: "block"}}>{errors.kurtaxeHidden}</div></div>}
                                                {errors.carHidden && <div className='col-md-12 mt-0'><div className='invalid-feedback mt-0 mb-3' style={{display: "block"}}>{errors.carHidden}</div></div>}

                                            </div>
                                        </div>
                                        <div className='modal-footer'>
                                        <span className='btn btn-light' onClick={closeServiceModal}>
                                            Abbrechen
                                        </span>
                                            <span className='btn btn-success' onClick={submitServiceModal}>
                                            Speichern
                                        </span>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>

                    </div>
                    <div className={'col-4'}>
                        <div onClick={openServiceDeleteModal}
                             className="btn-olap-modal btn-info btn btn-olap-success btn-danger btn-lg p-2"
                             data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas"
                             aria-controls="theme-settings-offcanvas">
                            <RiCloseLine/>
                        </div>
                        <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"
                             className={`modal fade ${isServiceDeleteModalOpen ? 'show' : ''}`}
                             style={{
                                 display: isServiceDeleteModalOpen ? 'block' : 'none',
                                 zIndex: '100000 !important'
                             }}>
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className='modal-header'>
                                        <h5 className='modal-title'>
                                            {state.name[0] && state.name[0].text ? state.name[0].text : ''} löschen
                                        </h5>
                                        <button type='button' className='btn-close' aria-label='Close'
                                                onClick={closeServiceDeleteModal}/>
                                    </div>
                                    <div className='modal-body'>
                                        <div>
                                            Wollen Sie
                                            wirklich {state.name[0] && state.name[0].text ? state.name[0].text : ''} löschen?
                                        </div>
                                    </div>
                                    <div className='modal-footer'>
                                        <span className='btn btn-light' onClick={closeServiceDeleteModal}>
                                            Abbrechen
                                        </span>
                                        <span className='btn btn-success' onClick={submitServiceDeleteModal}>
                                            Löschen
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceModal;

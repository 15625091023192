import React, {Fragment, useEffect, useState} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import OlapManagementHeader from "./OlapManagementHeader";
import OlapManagementObjectCard from "./OlapManagementObjectCard";
import OlapManagementTable from "./OlapManagementTable";
import OlapTutorial from "./OlapTutorial";

const OlapManagement = () => {
    const { view } = useParams();
    const [viewMode, setViewMode] = useState("seasons");
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const location = useLocation();


    const handleViewModeChange = (view) => {
        if (view === "months") {
            setViewMode("months");
        } else if (view === "object") {
            setViewMode("object");
        } else {
            setViewMode("seasons");
        }
    };

    const handleSidebarChange = () => {
        setSidebarOpen(!sidebarOpen);
    }

    useEffect(() => {
        if (view) {
            handleViewModeChange(view);
        }
    }, [view]);

    useEffect(() => {
        if (view) {
            handleViewModeChange(view);
        }
    }, [location]);

    return (
        <Fragment>
            <OlapManagementHeader />
            <OlapManagementObjectCard viewMode={viewMode} handleSidebarChange={handleSidebarChange} viewModeChange={handleViewModeChange} />
            <OlapManagementTable sidebarOpen={sidebarOpen} handleSidebarChange={handleSidebarChange} viewMode={viewMode}/>
            <OlapTutorial />
        </Fragment>
    );
};

export default OlapManagement;
import React, { useState } from 'react';
import {RiAddLine, RiCloseLine, RiEdit2Line} from "react-icons/ri";
import OrganizationService from "../../services/OrganizationService";
import {useAuth} from "../../hooks/useAuth";

const OrganisationEditModal = ({ onEditSubmit = () => {}, organisation }) => {
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [isContactOpen, setIsContactOpen] = useState(false);
    const { user } = useAuth();

    const openEditModal = () => {
        setIsEditModalOpen(true);
    };

    const closeModal = () => {
        setIsEditModalOpen(false);
    };

    const [state, setState] = useState(organisation);

    const change = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const addAdress = () => {
        setState({
            ...state,
            addresses: [
                ...state.addresses,
                {
                    street: '',
                    postalCode: '',
                    city: '',
                    state: 'Baden-Württemberg',
                    type: 'HOME',
                    postBox: '',
                }
            ],
        });
    };

    const removeAdress = (index) => {
        setState({
            ...state,
            addresses: [
                ...state.addresses.slice(0, index),
                ...state.addresses.slice(index + 1),
            ],
        });
    };

    const addContact = () => {
        setState({
            ...state,
            contactPersons: [
                ...state.contactPersons,
                {
                    firstname: '',
                    lastname: '',
                    salutation: 'MR',
                    title: '',
                    phoneNumbers: [
                        {
                            number: '',
                            type: 'MOBILE',
                        }
                    ],
                    homepages: [
                        {
                            url: '',
                            type: 'WORK',
                        }
                    ],
                    emails: [
                        {
                            address: '',
                            type: 'WORK',
                        }
                    ],
                }
            ],
        });
    };

    const removeContact = (index) => {
        setState({
            ...state,
            contactPersons: [
                ...state.contactPersons.slice(0, index),
                ...state.contactPersons.slice(index + 1),
            ],
        });
    };

    const changeAddress = (e, index) => {
        const { name, value } = e.target;
        const updatedAddresses = [...state.addresses];
        updatedAddresses[index][name] = value;
        setState({ ...state, addresses: updatedAddresses });
    };

    const changeContact = (e, index) => {
        const { name, value } = e.target;
        const updatedContacts = [...state.contactPersons];
        updatedContacts[index][name] = value;
        setState({ ...state, contactPersons: updatedContacts });
    };

    const changeContactOpen = () => {
        if(!isContactOpen){
            setState({
                ...state,
                contactPersons: [
                    {
                        firstname: '',
                        lastname: '',
                        salutation: '',
                        title: '',
                        phoneNumbers: [
                            {
                                number: '',
                                type: 'MOBILE',
                            }
                        ],
                        homepages: [
                            {
                                url: '',
                                type: 'WORK',
                            }
                        ],
                        emails: [
                            {
                                address: '',
                                type: 'WORK',
                            }
                        ],
                    }
                ],
            });
        } else{
            setState({
                ...state,
                contactPersons: [],
            });
        }
        setIsContactOpen(!isContactOpen);
    }

    const changeContactPhone = (e, index, indexPhone) => {
        const { name, value } = e.target;
        const updatedContacts = [...state.contactPersons];
        updatedContacts[index].phoneNumbers[indexPhone][name] = value;
        setState({ ...state, contactPersons: updatedContacts });
    }

    const changeContactMail = (e, index, indexMail) => {
        const { name, value } = e.target;
        const updatedContacts = [...state.contactPersons];
        updatedContacts[index].emails[indexMail][name] = value;
        setState({ ...state, contactPersons: updatedContacts });
    }

    const changeContactHomepage = (e, index, indexHomepage) => {
        const { name, value } = e.target;
        const updatedContacts = [...state.contactPersons];
        updatedContacts[index].homepages[indexHomepage][name] = value;
        setState({ ...state, contactPersons: updatedContacts });
    }

    const addPhone = (index) => {
        const updatedContacts = [...state.contactPersons];
        updatedContacts[index].phoneNumbers.push({
            number: '',
            type: '',
        });
        setState({ ...state, contactPersons: updatedContacts });
    }

    const addMail = (index) => {
        const updatedContacts = [...state.contactPersons];
        updatedContacts[index].emails.push({
            address: '',
            type: '',
        });
        setState({ ...state, contactPersons: updatedContacts });
    }

    const addHomepage = (index) => {
        const updatedContacts = [...state.contactPersons];
        updatedContacts[index].homepages.push({
            url: '',
            type: '',
        });
        setState({ ...state, contactPersons: updatedContacts });
    }

    const removePhone = (index, indexPhone) => {
        const updatedContacts = [...state.contactPersons];
        updatedContacts[index].phoneNumbers = [
            ...updatedContacts[index].phoneNumbers.slice(0, indexPhone),
            ...updatedContacts[index].phoneNumbers.slice(indexPhone + 1),
        ];
        setState({ ...state, contactPersons: updatedContacts });
    }

    const removeMail = (index, indexMail) => {
        const updatedContacts = [...state.contactPersons];
        updatedContacts[index].emails = [
            ...updatedContacts[index].emails.slice(0, indexMail),
            ...updatedContacts[index].emails.slice(indexMail + 1),
        ];
        setState({ ...state, contactPersons: updatedContacts });
    }

    const removeHomepage = (index, indexHomepage) => {
        const updatedContacts = [...state.contactPersons];
        updatedContacts[index].homepages = [
            ...updatedContacts[index].homepages.slice(0, indexHomepage),
            ...updatedContacts[index].homepages.slice(indexHomepage + 1),
        ];
        setState({ ...state, contactPersons: updatedContacts });
    }

    const submit = async (e) => {
        e.preventDefault();
        try {
            await OrganizationService.putOrganization(organisation.id, user, state);
            onEditSubmit();
            closeModal();
        } catch (err) {
            //TODO
            throw err;
        }
        return false;
    };

    return (
        <span>
            <span className='editbox text-success text-success-hover' onClick={openEditModal}><RiEdit2Line /></span>
            <div
                id='organisationModal'
                className={`modal fade ${isEditModalOpen ? 'show' : ''}`}
                tabIndex='-1'
                style={{ display: isEditModalOpen ? 'block' : 'none' }}
                aria-hidden={!isEditModalOpen}
            >
                <div className='modal-dialog modal-xxl'>
                    <form onSubmit={submit}>
                    <div className='modal-dialog modal-xxl'>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title' id='newAddressesModal'>
                                    Neue Organisation erstellen
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeModal}/>
                            </div>
                            <div className='modal-body'>
                                <div className={'row'}>
                                    <div className={`${isContactOpen ? 'col-4' : 'col-6'}  border-end p-4`}>
                                        <h6 className='modal-title' id='newOrganizationModal'>
                                            Organisationsdaten
                                        </h6>
                                        <div className={'mb-2'}>
                                            <label className='form-label'>
                                                Organisationsname
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='name'
                                                value={state.name}
                                                onChange={change}
                                            />
                                        </div>
                                        <div className={'mb-2'}>
                                            <label className='form-label'>
                                                Zusätzlicher Organisationsname
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='name2'
                                                value={state.name2}
                                                onChange={change}
                                            />
                                        </div>
                                        <div className={'mb-2'}>
                                            <label className='form-label'>
                                                Telefonnummer
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='phone'
                                                value={state.phone}
                                                onChange={change}
                                            />
                                        </div>
                                        <div className={'mb-2'}>
                                            <label className='form-label'>
                                                E-Mail
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='email'
                                                value={state.email}
                                                onChange={change}
                                            />
                                        </div>
                                        <div className={'mb-4'}>
                                            <label className='form-label'>
                                                Homepage
                                            </label>
                                            <input
                                                type='text'
                                                className='form-control'
                                                name='homepage'
                                                value={state.homepage}
                                                onChange={change}
                                            />
                                        </div>
                                        <div className={'mb-2 d-flex'}>
                                            <input className="form-check-input me-1" type="checkbox" id="contactCheck"
                                                   name="contactCheck" checked={isContactOpen}
                                                   onChange={changeContactOpen}/>
                                            <label className="form-check-label d-flex align-items-center"
                                                   htmlFor="contactCheck">
                                                <span className="flex-grow-1 ms-2">Möchten Sie Kontaktpersonen hinzufügen?</span>
                                            </label>
                                        </div>

                                    </div>
                                    <div className={`${isContactOpen ? 'col-4 border-end' : 'col-6'} p-4`}>
                                        {state.addresses.map((address, index) => {
                                            return (
                                                <div
                                                    className={`${index !== 0 ? 'pt-3 border-top border-top-dashed mt-4' : ''}`}
                                                    key={'address' + index}>
                                                    <div className={'d-flex justify-content-between'}>
                                                        <h6 className='modal-title' id='newAddressesModal'>
                                                            {index + 1}. Adresse
                                                        </h6>
                                                        {index === 0 && (
                                                            <span
                                                                className='btn text-success btn-ghost-success p-0 pe-1 ps-1 border-0'
                                                                onClick={addAdress}>
                                                                <RiAddLine/>
                                                            </span>
                                                        )}
                                                        {index !== 0 && (
                                                            <span
                                                                className='btn text-danger btn-ghost-danger p-0 pe-1 ps-1 border-0'
                                                                onClick={() => removeAdress(index)}>
                                                                <RiCloseLine/>
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className={'mb-2'}>
                                                        <label className='form-label'>
                                                            Straße
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            name='street'
                                                            value={state.addresses[index].street}
                                                            onChange={(e) => changeAddress(e, index)}
                                                        />
                                                    </div>
                                                    <div className={'mb-2'}>
                                                        <div className={'row'}>
                                                            <div className={'col-6'}>
                                                                <label className='form-label'>
                                                                    Postleitzahl
                                                                </label>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    name='postalCode'
                                                                    value={state.addresses[index].postalCode}
                                                                    onChange={(e) => changeAddress(e, index)}
                                                                />
                                                            </div>
                                                            <div className={'col-6'}>
                                                                <label className='form-label'>
                                                                    Stadt
                                                                </label>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    name='city'
                                                                    value={state.addresses[index].city}
                                                                    onChange={(e) => changeAddress(e, index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'mb-2'}>
                                                        <label className='form-label'>
                                                            Bundesland
                                                        </label>
                                                        <select
                                                            className='form-control'
                                                            name='state'
                                                            value={state.addresses[index].state}
                                                            onChange={(e) => changeAddress(e, index)}
                                                        >
                                                            <option value='Baden-Württemberg'>
                                                                Baden-Württemberg
                                                            </option>
                                                            <option value='Bayern'>
                                                                Bayern
                                                            </option>
                                                            <option value='Berlin'>
                                                                Berlin
                                                            </option>
                                                            <option value='Brandenburg'>
                                                                Brandenburg
                                                            </option>
                                                            <option value='Bremen'>
                                                                Bremen
                                                            </option>
                                                            <option value='Hamburg'>
                                                                Hamburg
                                                            </option>
                                                            <option value='Hessen'>
                                                                Hessen
                                                            </option>
                                                            <option value='Mecklenburg-Vorpommern'>
                                                                Mecklenburg-Vorpommern
                                                            </option>
                                                            <option value='Niedersachsen'>
                                                                Niedersachsen
                                                            </option>
                                                            <option value='Nordrhein-Westfalen'>
                                                                Nordrhein-Westfalen
                                                            </option>
                                                            <option value='Rheinland-Pfalz'>
                                                                Rheinland-Pfalz
                                                            </option>
                                                            <option value='Saarland'>
                                                                Saarland
                                                            </option>
                                                            <option value='Sachsen'>
                                                                Sachsen
                                                            </option>
                                                            <option value='Sachsen-Anhalt'>
                                                                Sachsen-Anhalt
                                                            </option>
                                                            <option value='Schleswig-Holstein'>
                                                                Schleswig-Holstein
                                                            </option>
                                                            <option value='Thüringen'>
                                                                Thüringen
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className={'mb-2'}>
                                                        <label className='form-label'>
                                                            Land
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            name='country'
                                                            value={state.addresses[index].country}
                                                            onChange={(e) => changeAddress(e, index)}
                                                        />
                                                    </div>
                                                    <div className={'mb-2'}>
                                                        <label className='form-label'>
                                                            Typ
                                                        </label>
                                                        <select
                                                            className='form-control'
                                                            name='type'
                                                            value={state.addresses[index].type}
                                                            onChange={(e) => changeAddress(e, index)}
                                                        >
                                                            <option value='HOME'>
                                                                Privatadresse
                                                            </option>
                                                            <option value='WORK'>
                                                                Arbeitsadresse
                                                            </option>
                                                            <option value='BILLING'>
                                                                Rechnungsadresse
                                                            </option>
                                                            <option value='DELIVERY'>
                                                                Lieferadresse
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div className={'mb-2'}>
                                                        <label className='form-label'>
                                                            Postfach (optional)
                                                        </label>
                                                        <input
                                                            type='text'
                                                            className='form-control'
                                                            name='postBox'
                                                            value={state.addresses[index].postBox}
                                                            onChange={(e) => changeAddress(e, index)}
                                                        />
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                    <div className={'col-4 p-4'}>
                                        {state.contactPersons.map((contact, index) => {
                                            return (
                                                <div
                                                    className={`${index !== 0 ? 'pt-3 border-top border-top-dashed mt-4' : ''}`}
                                                    key={'contact' + index}>
                                                    <div className={'d-flex justify-content-between'}>
                                                        <h6 className='modal-title' id='newAddressesModal'>
                                                            {index + 1}. Kontaktperson
                                                        </h6>
                                                        {index === 0 && (
                                                            <span
                                                                className='btn text-success btn-ghost-success p-0 pe-1 ps-1 border-0'
                                                                onClick={addContact}>
                                                                <RiAddLine/>
                                                            </span>
                                                        )}
                                                        {index !== 0 && (
                                                            <span
                                                                className='btn text-danger btn-ghost-danger p-0 pe-1 ps-1 border-0'
                                                                onClick={() => removeContact(index)}>
                                                                <RiCloseLine/>
                                                            </span>
                                                        )}
                                                    </div>
                                                    <div className={'mb-2'}>
                                                        <div className={'row'}>
                                                            <div className={'col-6'}>
                                                                <label className='form-label'>
                                                                    Vorname
                                                                </label>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    name='firstname'
                                                                    value={state.contactPersons[index].firstname}
                                                                    onChange={(e) => changeContact(e, index)}
                                                                />
                                                            </div>
                                                            <div className={'col-6'}>
                                                                <label className='form-label'>
                                                                    Nachname
                                                                </label>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    name='lastname'
                                                                    value={state.contactPersons[index].lastname}
                                                                    onChange={(e) => changeContact(e, index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className={'mb-2'}>
                                                        <div className={'row'}>
                                                            <div className={'col-6'}>
                                                                <label className='form-label'>
                                                                    Anrede
                                                                </label>
                                                                <select
                                                                    className='form-control'
                                                                    name='salutation'
                                                                    value={state.contactPersons[index].salutation}
                                                                    onChange={(e) => changeContact(e, index)}
                                                                >
                                                                    <option value='MR'>
                                                                        Herr
                                                                    </option>
                                                                    <option value='MRS'>
                                                                        Frau
                                                                    </option>
                                                                    <option value='DIVERS'>
                                                                        Firma
                                                                    </option>
                                                                </select>
                                                            </div>
                                                            <div className={'col-6'}>
                                                                <label className='form-label'>
                                                                    Titel
                                                                </label>
                                                                <input
                                                                    type='text'
                                                                    className='form-control'
                                                                    name='title'
                                                                    value={state.contactPersons[index].title}
                                                                    onChange={(e) => changeContact(e, index)}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className={'mb-2 pt-3 border-top border-top-dashed mt-4'}>
                                                        <div className={'d-flex justify-content-between mb-2'}>
                                                            <label className='form-label mb-0'>
                                                                Telefonnummern
                                                            </label>
                                                            <span
                                                                className='btn text-success btn-ghost-success p-0 pe-1 ps-1 border-0'
                                                                onClick={() => addPhone(index)}>
                                                                <RiAddLine/>
                                                            </span>
                                                        </div>
                                                        {state.contactPersons[index].phoneNumbers.map((number, indexPhone) => {
                                                            return (
                                                                <div className={'mb-2 row'}
                                                                     key={'phone' + index + indexPhone}>
                                                                    <div className={'col-3'}>
                                                                        <select
                                                                            className='form-control'
                                                                            name='type'
                                                                            value={state.contactPersons[index].phoneNumbers[indexPhone].type}
                                                                            onChange={(e) => changeContactPhone(e, index, indexPhone)}
                                                                        >
                                                                            <option value='MOBILE'>
                                                                                Mobil
                                                                            </option>
                                                                            <option value='WORK'>
                                                                                Arbeit
                                                                            </option>
                                                                            <option value='HOME'>
                                                                                Privat
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div
                                                                        className={`${indexPhone === 0 ? 'col-9' : 'col-8'}`}>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control'
                                                                            name='number'
                                                                            value={state.contactPersons[index].phoneNumbers[indexPhone].number}
                                                                            onChange={(e) => changeContactPhone(e, index, indexPhone)}
                                                                        />
                                                                    </div>
                                                                    {indexPhone !== 0 && (
                                                                        <div
                                                                            className={'col-1 p-0 d-flex align-items-center'}>
                                                                            <span
                                                                                className='btn text-danger btn-ghost-danger p-0 pe-1 ps-1 border-0'
                                                                                onClick={() => removePhone(index, indexPhone)}>
                                                                                <RiCloseLine/>
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className={'mb-2 pt-3 border-top border-top-dashed mt-4'}>
                                                        <div className={'d-flex justify-content-between mb-2'}>
                                                            <label className='form-label mb-0'>
                                                                E-Mails
                                                            </label>
                                                            <span
                                                                className='btn text-success btn-ghost-success p-0 pe-1 ps-1 border-0'
                                                                onClick={() => addMail(index)}>
                                                                <RiAddLine/>
                                                            </span>
                                                        </div>
                                                        {state.contactPersons[index].emails.map((email, indexMail) => {
                                                            return (
                                                                <div className={'mb-2 row'}
                                                                     key={'mail' + index + indexMail}>
                                                                    <div className={'col-3'}>
                                                                        <select
                                                                            className='form-control'
                                                                            name='type'
                                                                            value={state.contactPersons[index].emails[indexMail].type}
                                                                            onChange={(e) => changeContactMail(e, index, indexMail)}
                                                                        >
                                                                            <option value='WORK'>
                                                                                Arbeit
                                                                            </option>
                                                                            <option value='HOME'>
                                                                                Privat
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div
                                                                        className={`${indexMail === 0 ? 'col-9' : 'col-8'}`}>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control'
                                                                            name='address'
                                                                            value={state.contactPersons[index].emails[indexMail].address}
                                                                            onChange={(e) => changeContactMail(e, index, indexMail)}
                                                                        />
                                                                    </div>
                                                                    {indexMail !== 0 && (
                                                                        <div
                                                                            className={'col-1 p-0 d-flex align-items-center'}>
                                                                                <span
                                                                                    className='btn text-danger btn-ghost-danger p-0 pe-1 ps-1 border-0'
                                                                                    onClick={() => removeMail(index, indexMail)}>
                                                                                    <RiCloseLine/>
                                                                                </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                    <div className={'mb-2 pt-3 border-top border-top-dashed mt-4'}>
                                                        <div className={'d-flex justify-content-between mb-2'}>
                                                            <label className='form-label mb-0'>
                                                                Homepages
                                                            </label>
                                                            <span
                                                                className='btn text-success btn-ghost-success p-0 pe-1 ps-1 border-0'
                                                                onClick={() => addHomepage(index)}>
                                                                <RiAddLine/>
                                                            </span>
                                                        </div>
                                                        {state.contactPersons[index].homepages.map((homepage, indexHomepage) => {
                                                            return (
                                                                <div className={'mb-2 row'}
                                                                     key={'homepage' + index + indexHomepage}>
                                                                    <div className={'col-3'}>
                                                                        <select
                                                                            className='form-control'
                                                                            name='type'
                                                                            value={state.contactPersons[index].homepages[indexHomepage].type}
                                                                            onChange={(e) => changeContactHomepage(e, index, indexHomepage)}
                                                                        >
                                                                            <option value='WORK'>
                                                                                Arbeit
                                                                            </option>
                                                                            <option value='HOME'>
                                                                                Privat
                                                                            </option>
                                                                        </select>
                                                                    </div>
                                                                    <div
                                                                        className={`${indexHomepage === 0 ? 'col-9' : 'col-8'}`}>
                                                                        <input
                                                                            type='text'
                                                                            className='form-control'
                                                                            name='url'
                                                                            value={state.contactPersons[index].homepages[indexHomepage].url}
                                                                            onChange={(e) => changeContactHomepage(e, index, indexHomepage)}
                                                                        />
                                                                    </div>
                                                                    {indexHomepage !== 0 && (
                                                                        <div
                                                                            className={'col-1 p-0 d-flex align-items-center'}>
                                                                            <span
                                                                                className='btn text-danger btn-ghost-danger p-0 pe-1 ps-1 border-0'
                                                                                onClick={() => removeHomepage(index, indexHomepage)}>
                                                                                <RiCloseLine/>
                                                                            </span>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-light' onClick={closeModal}>
                                    Abbrechen
                                </button>
                                <button type='submit' className='btn btn-success'>
                                    Speichern
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
                </div>
            </div>
        </span>
    );
};

export default OrganisationEditModal;

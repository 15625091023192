import React, {useEffect, useState} from "react";
import {RiBookLine} from 'react-icons/ri';
import {Link, useParams} from "react-router-dom";

const OlapManagementObjectCard = ({viewModeChange, viewMode, handleSidebarChange, item}) => {

    return (
        <div className='card olap-card'>
            <div className='card-header d-none'>
                <div className='dataTables_wrapper'>
                    <div id="model-datatables_filter" className="dataTables_filter">
                        <label>
                            Search:
                            <input type="search" className="form-control form-control-sm" placeholder="" aria-controls="model-datatables" />
                        </label>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                    <div className="row">
                        <div className="col-6 pe-2 ps-2 dataTables_length">
                            <div className="row olap-row">
                                <div className="col-auto pe-2 ps-2 dataTables_length">
                                    <div className="d-none d-md-block">
                                        <span onClick={handleSidebarChange} className="btn-olap btn btn-success create-folder-modal flex-shrink-0">
                                            <RiBookLine />
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center pe-2 ps-2 col-auto dataTables_length">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item d-flex">
                                            <h4>
                                                <Link to="#">Test Yolo 2</Link>
                                            </h4>
                                        </li>
                                        <li className="breadcrumb-item d-flex">
                                            <h4>
                                                <Link to="#">DefaultKategorie</Link>
                                            </h4>
                                        </li>
                                        <li className="breadcrumb-item active d-flex">
                                            <h4>
                                                <Link to="#">Prefix Test 106</Link>
                                            </h4>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 justify-content-end pe-3 ps-2 dataTables_length d-flex">
                            <button onClick={() => viewModeChange("seasons")} className={("ms-2 btn-olap btn btn-success") + (viewMode === "seasons" ? " btn-toggled" : "")}>Zur Saison-Ansicht</button>
                            <button onClick={() => viewModeChange("months")} className={("ms-2 btn-olap btn btn-success") + (viewMode === "months" ? " btn-toggled" : "")}>Zur Tages-Ansicht</button>
                            <button onClick={() => viewModeChange("object")} className={("ms-2 btn-olap btn btn-success") + (viewMode === "object" ? " btn-toggled" : "")}>Zur Objekt-Ansicht</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default OlapManagementObjectCard;
import React from 'react';
import defaultAvatar from '../../assets/img/default-avatar.webp';
import { RiBuildingLine, RiOrganizationChart} from 'react-icons/ri';
import { useAuth } from '../../hooks/useAuth';
import { useOrganization } from '../../hooks/useOrganization';

const ProfileHeader = () => {
    const { user } = useAuth();
    const { organization } = useOrganization();

    return (
        <div className='pt-4 mb-4 mb-lg-3 pb-lg-4 profile-wrapper'>
            <div className='row g-4'>
                <div className='col-auto'>
                    <div className='avatar-lg'>
                        <img src={defaultAvatar} alt='user-img' className='img-thumbnail rounded-circle' />
                    </div>
                </div>
                <div className='col'>
                    <div className='p-2'>
                        <h3 className='text-white mb-1'>{user.email}</h3>
                        {organization !== null && <p className='text-white text-opacity-75'>{organization.role}</p>}
                        <div className='hstack text-white-50 gap-1'>
                            <div>
                                <RiOrganizationChart className='ri-building-line me-1 text-white text-opacity-75 fs-16 align-middle' />
                                {`Aktuelle Organisation: ${organization === null ? 'Keine' : organization.name}`} - ID: {organization === null ? 'Keine' : organization.id}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileHeader;

import React, {Fragment} from "react";
import ObjectAndSeasonsHeader from "./ObjectsAndSeasonsHeader";
import ObjectsAndSeasonsDnDTest from "./ObjectsAndSeasonsDnDTest";
import {Link} from 'react-router-dom';

const ObjectsAndSeasons = () => {
    return (
        <Fragment>
            <ObjectAndSeasonsHeader/>
            <div className="card">
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h4 className="card-title m-0">
                        Ziehen Sie einen Saisonkalender von der linken Seite auf die Objektkategorien oder Objekte, um den Saisonkalender zuzuordnen. (Drag and Drop)
                    </h4>
                    <Link to={'/olap'}>
                        <span className='btn btn-success'>
                            Zu den Eigenschaften
                        </span>
                    </Link>
                </div>
            </div>
            <ObjectsAndSeasonsDnDTest />
        </Fragment>
    );
}

export default ObjectsAndSeasons;
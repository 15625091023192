import React, {useEffect, useState} from 'react';
import { RiQuestionLine, RiCloseLine } from 'react-icons/ri';
import {useSearchParams} from "react-router-dom";

const OlapTutorial = () => {
    const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);

    const openTutorialModal = () => {
        setIsTutorialModalOpen(true);
    };

    const closeTutorialModal = () => {
        setIsTutorialModalOpen(false);
    };

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('first') === 'true') {
            openTutorialModal();
        }
    }, []);

    return (
        <div className="customizer-setting d-none d-md-block" style={{zIndex: "1050"}}>
            <div onClick={openTutorialModal} className="btn-info rounded-pill shadow-lg btn btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                <RiQuestionLine className="mdi mdi-spin mdi-cog-outline fs-22" />
            </div>
            <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isTutorialModalOpen ? 'show' : ''}`}
                 style={{ display: isTutorialModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-body p-5">
                            <div className="mt-4">
                                <h4 className="mb-3">Willkommen in der OLAP Tabelle!</h4>
                                <p className="text-muted mb-4">Hier können Sie Ihre OLAP Tabelle erstellen und verwalten.</p>
                                <div className="hstack gap-2 justify-content-end">
                                    <span onClick={closeTutorialModal} className="btn btn-link link-success fw-medium keychainify-checked"
                                          data-bs-dismiss="modal"><RiCloseLine className="ri-close-line me-1 align-middle" />Schließen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OlapTutorial;

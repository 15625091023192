import React, {Fragment, useEffect, useState} from 'react';
import OlapManagementHeader from "./Management/OlapManagementHeader";
import {Link, useLocation} from "react-router-dom";
import {useOrganization} from "../../hooks/useOrganization";
import {useAuth} from "../../hooks/useAuth";
import PlaceService from "../../services/PlaceService";
import Loader from "../../components/Loader";
import AlertCard from "../../components/AlertCard";
import OlapPlaces from "./OlapPlaces";
import OlapList from "./OlapList";
import {RiBookLine} from "react-icons/ri";
import SimpleBar from "simplebar-react";
const Olap = () => {
    return (
        <Fragment>
            <OlapManagementHeader/>
            <div className='card olap-card'>
                <div className='card-header d-none'>
                    <div className='dataTables_wrapper'>
                        <div id="model-datatables_filter" className="dataTables_filter">
                            <label>
                                Search:
                                <input type="search" className="form-control form-control-sm" placeholder="" aria-controls="model-datatables" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className='card-body'>
                    <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div className="row">
                            <div className="col-7 pe-2 ps-2 dataTables_length">
                                <div className="row olap-row">
                                    <div className="d-flex align-items-center pe-2 ps-2 col-auto dataTables_length">
                                        <ol className="breadcrumb m-0">
                                            <li className="breadcrumb-item d-flex">
                                                <h4 className="m-0">
                                                    <Link to="#">Wähle einen Platz, eine Kategorie oder ein Objekt aus.</Link>
                                                </h4>
                                            </li>
                                        </ol>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`row`}>
                <div className={`col-sm-2`}>
                    <div className='card'>
                        <div className='card-body'>
                            <SimpleBar>
                                <OlapList/>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Olap;
import React, {Fragment, useState} from "react";
import OlapMiniTutorial from "./OlapMiniTutorial";
import OlapEditInput from "./OlapEditInput";
import OlapDiscountModal from "./OlapDiscountModal";
import OlapServiceModal from "./OlapServiceModal";
import OlapList from "../OlapList";
import OlapDescriptionModal from "./OlapDescriptionModal";
import OlapCategoryModal from "./OlapCategoryModal";
import {RiArrowDownSLine, RiEditLine} from "react-icons/ri";
import OlapDocumentModal from "./OlapDocumentModal";

const OlapManagementTable = ({viewMode, sidebarOpen, handleSidebarChange}) => {

    const optionsDataMonths = {
        'Eigenschaften': [
            'Basispreis für Standardbelegung',
        ],
        'Leistungen': [
            { type: 'Benutzerdefiniert', services: [] },
            { type: 'Vordefiniert', services: ['Aufbettungszuschlag', 'Frühstück', 'Kinderfrühstück', 'Parkplatz', 'all inclusive', 'Bettwäsche', 'Haus-/Kleintier', 'Hochstuhl', 'Babybett', 'Wäschepaket'] },
        ],
        'Rabatte': [
            { type: 'Benutzerdefiniert', discounts: [] },
            { type: 'Vordefiniert', discounts: ['Last Minute', 'Early Bird'] },
        ],
    };

    const optionsDataSeasons = {
        'Eigenschaften': [
            'Basispreis für Standardbelegung',
            'Mindestaufenthalt',
            'Buchungslücken füllen ab',
            'Karenztage (nach Buchung)',
            'Mögliche Anreisewochentage',
            'Mögliche Abreisewochentage',
            'Anreise von',
            'Anreise bis',
            'Abreise von',
            'Abreise bis',
        ],
        'Kategorien': [
            'Zielgruppe',
            'Attribute',
            'Gastronomie',
            'Außenanlage',
            'Freizeitangebote'
        ],
        'Leistungen': [
            { type: 'Benutzerdefiniert', services: [] },
            { type: 'Vordefiniert', services: ['Aufbettungszuschlag', 'Frühstück', 'Kinderfrühstück', 'Parkplatz', 'all inclusive', 'Bettwäsche', 'Haus-/Kleintier', 'Hochstuhl', 'Babybett', 'Wäschepaket'] },
        ],
        'Rabatte': [
            { type: 'Benutzerdefiniert', discounts: [] },
            { type: 'Vordefiniert', discounts: ['Last Minute', 'Early Bird'] },
        ],
    };

    const optionsDataObject = {
        'Eigenschaften': [
            'Basispreis für Standardbelegung',
            'Standardbelegung',
            'Maximalbelegung',
            'Davon Baby',
            'Mindestaufenthalt',
            'Buchungslücken füllen ab',
            'Karenztage (nach Buchung)',
            'Mögliche Anreisewochentage',
            'Mögliche Abreisewochentage',
            'Anreise von',
            'Anreise bis',
            'Abreise von',
            'Abreise bis',
        ],
        'Beschreibungstexte': [
            'Objektbeschreibung',
            'Beschreibung der Anfahrt',
            'Beschreibung der Umgebung',
            'Information zur Kaution',
            'Information zur Anreise',
            'Information zur Abreise',
            'Information zu Haustieren',
        ],
        'Dokumente': [
            'Buchungsbestätigung',
            'Optionsbestätigung',
            'Zahlungsbestätigung (Anzahlung, Restzahlung, für Vermittler)',
            'Buchungsmitteilung',
            'Angebot',
        ],
        'Kategorien': [
            'Zielgruppe',
            'Objekttyp',
            'Raumtyp',
            'Schlafmöglichkeiten',
            'Typ des Bades/der Bäder',
            'Kücheneigenschaften',
            'Ausstattung',
            'Geräte',
            'Attribute',
            'Gastronomie',
            'Außenanlage',
            'Freizeitangebote',
            'Umweltmaßnahmen',
            'Entfernungen',
        ],
        'Medien': [
            'Bilder',
            'Videos',
        ],
        'Leistungen': [
            { type: 'Benutzerdefiniert', services: [] },
            { type: 'Vordefiniert', services: ['Last Aufbettungszuschlag', 'Frühstück', 'Kinderfrühstück', 'Parkplatz', 'all inclusive', 'Bettwäsche', 'Haus-/Kleintier', 'Hochstuhl', 'Babybett', 'Wäschepaket'] },
        ],
        'Rabatte': [
            { type: 'Benutzerdefiniert', discounts: [] },
            { type: 'Vordefiniert', discounts: ['Last Minute', 'Early Bird'] },
        ],
    };

    const optionTextData = {
        'Basispreis f&uml;r Standardbelegung': 'Standardpreis pro Übernachtung',
        'Mindestaufenthalt': 'Anzahl an Übernachtungen, die mindestens gebucht werden müssen',
        'Buchungsl&uuml;cken f&uuml;llen ab': 'Test Text für Buchungslücken füllen ab',
        'M&ouml;gliche Anreisewochentage': 'Test Text für Mögliche Anreisewochentage',
        'M&ouml;gliche Abreisewochentage': 'Test Text für Mögliche Abreisewochentage',
        'Karenztage (nach Buchung)': 'Test Text für Karenztage (nach Buchung)',
        'Standardbelegung': 'Test Text für Standardbelegung',
        'Maximalbelegung': 'Test Text für Maximalbelegung',
        'Davon Baby': 'Test Text für Davon Baby',
        'Beschreibungstexte': 'Test Text für Beschreibungstexte',
        'Attribute': 'Test Text für Attribute',
        'Kategorien': 'Test Text für Kategorien',
        'Bilder': 'Test Text für Bilder',
        'Bezeichnung': 'Test Text für Bezeichnung',
        'Berechnung': 'Test Text für Berechnung',
        'Einheit': 'Test Text für Einheit',
        'Verwendung': 'Test Text für Verwendung',
        'Maximale Anzahl': 'Test Text für Maximale Anzahl',
        'Alter von': 'Test Text für Alter von',
        'Alter bis': 'Test Text für Alter bis',
        'inklusive': 'Test Text für inklusive',
        'nur vor Ort buchbar': 'Test Text für nur vor Ort buchbar',
        'Nettopreis': 'Test Text für Nettopreis',
        'automatisch ausgew&auml;hlt': 'Test Text für automatisch ausgewählt',
        'Hinweis': 'Test Text für Hinweis',
        'Aufbettungszuschlag': 'Test Text für Aufbettungszuschlag',
        'Fr&uuml;hst&uuml;ck': 'Test Text für Frühstück',
        'Kinderfr&uuml;hst&uuml;ck': 'Test Text für Kinderfrühstück',
        'Parkplatz': 'Test Text für Parkplatz',
        'All inclusive': 'Test Text für All inclusive',
        'Babybett': 'Test Text für Babybett',
        'Last Minute': 'Test Text für Last Minute',
        'Early Bird': 'Test Text für Early Bird',
    };

    const tableHeadersSeasons = [
        { name: 'Hauptsaison', periods: ['01.06 - 31.10'] },
        { name: 'Nebensaison', periods: ['01.01-30.05', '01.11 - 31.12'] },
    ];

    const tableHeadersObject = [
        { name: 'Campingzelt 2', type: 'Objekt'}
    ];

    const tableHeaders = [
        'Mo 01.',
        'Di 02.',
        'Mi 03.',
        'Do 04.',
        'Fr 05.',
        'Sa 06.',
        'So 07.',
        'Mo 08.',
        'Di 09.',
        'Mi 10.',
        'Do 11.',
        'Fr 12.',
        'Sa 13.',
        'So 14.',
        'Mo 15.',
        'Di 16.',
        'Mi 17.',
        'Do 18.',
        'Fr 19.',
        'Sa 20.',
        'So 21.',
        'Mo 22.',
        'Di 23.',
        'Mi 24.',
        'Do 25.',
        'Fr 26.',
        'Sa 27.',
        'So 28.',
        'Mo 29.',
        'Di 30.',
        'Mi 31.',
        'Do 01.',
        'Fr 02.',
        'Sa 03.',
        'So 04.',
        'Mo 05.',
        'Di 06.',
        'Mi 07.',
        'Do 08.',
        'Fr 09.',
        'Sa 10.',
        'So 11.',
        'Mo 12.',
        'Di 13.',
        'Mi 14.',
        'Do 15.',
    ];

    const [selectedOption, setSelectedOption] = useState("Eigenschaften");
    const [hoveredColumn, setHoveredColumn] = useState(null);
    const [openDiscount, setOpenDiscount] = useState(false);
    const [openService, setOpenService] = useState(false);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const handleDiscount = () => {
        setOpenDiscount(!openDiscount);
    };

    const handleService = () => {
        setOpenService(!openService);
    };

    const getEditor = () => {
        switch (selectedOption) {
            case "Leistungen":
                return <OlapServiceModal />;
            case "Rabatte":
                return <OlapDiscountModal />;
            case "Beschreibungstexte":
                return <OlapDescriptionModal />;
            case "Dokumente":
                return <OlapDocumentModal />;
            case "Kategorien":
                return <OlapCategoryModal />;
            default:
                return <OlapEditInput />;
        }
    };

    const generateTableRows = () => {
        var data = [];
        if(viewMode === "months"){
            data = optionsDataMonths[selectedOption];
        } else if(viewMode === "object"){
            data = optionsDataObject[selectedOption];
        } else {
            data = optionsDataSeasons[selectedOption];
        }

        if (!data) return null;

        if(selectedOption === "Rabatte"){
            return(
                <Fragment key="rabatte-fragment">
                    <tr key="benutzerdefiniert-rabatte">
                        <td className="dtr-control">
                            Benutzerdefinierte Rabatte
                        </td>
                    </tr>
                    {data[0].discounts.map((rabatt, index) => (
                        <tr key={`benutzerdefiniert-rabatt-${index}`}>
                            <td className="dtr-control ps-5">
                                {rabatt}
                            </td>
                            {viewMode === "months" ? (
                                Array(46).fill().map((_, dayIndex) => (
                                    <td
                                        key={dayIndex}
                                        onMouseEnter={() => setHoveredColumn(dayIndex)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        {getEditor("Rabatte")}
                                    </td>
                                ))
                            ) : (
                                viewMode === "object" ? (
                                    <td>
                                        {getEditor("Rabatte")}
                                    </td>
                                ) : (
                                    tableHeadersSeasons.map((season, seasonIndex) => (
                                        <td
                                            key={seasonIndex}
                                            onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                            onMouseLeave={() => setHoveredColumn(null)}
                                        >
                                            {getEditor("Rabatte")}
                                        </td>
                                    ))
                                )
                            )}
                        </tr>
                    ))}
                    <tr key="benutzerdefiniert-rabatte-plus">
                        <td className="dtr-control ps-5">
                            <div className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2">
                                Rabatt hinzufügen +
                            </div>
                        </td>
                    </tr>
                    <tr key="vordefiniert-rabatte">
                        <td className="dtr-control">
                            Vordefinierte Rabatte
                            <span
                                className={`ms-2 olap-open-button ${openDiscount ? 'active' : ''}`}
                                onClick={handleDiscount}
                            >
                                <span className="icon"><RiArrowDownSLine /></span>
                            </span>
                        </td>
                    </tr>
                    {data[1].discounts.map((rabatt, index) => (
                        <tr key={`vordefiniert-rabatt-${index}`} className={`${openDiscount ? 'd-table-row' : 'd-none'}`}>
                            <td className="dtr-control ps-5">
                                {rabatt}
                            </td>
                            {viewMode === "months" ? (
                                Array(46).fill().map((_, dayIndex) => (
                                    <td
                                        key={dayIndex}
                                        onMouseEnter={() => setHoveredColumn(dayIndex)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        {getEditor("Rabatte")}
                                    </td>
                                ))
                            ) : (
                                viewMode === "object" ? (
                                    <td>
                                        {getEditor("Rabatte")}
                                    </td>
                                ) : (
                                    tableHeadersSeasons.map((season, seasonIndex) => (
                                        <td
                                            key={seasonIndex}
                                            onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                            onMouseLeave={() => setHoveredColumn(null)}
                                        >
                                            {getEditor("Rabatte")}
                                        </td>
                                    ))
                                )
                            )}
                        </tr>
                    ))}
                </Fragment>
            );
        }

        else if(selectedOption === "Leistungen"){
            return(
                <Fragment key="rabatte-fragment">
                    <tr key="benutzerdefiniert-leistung">
                        <td className="dtr-control">
                            Benutzerdefinierte Leistungen
                        </td>
                    </tr>
                    {data[0].services.map((leistung, index) => (
                        <tr key={`benutzerdefiniert-leistung-${index}`}>
                            <td className="dtr-control ps-5">
                                {leistung}
                            </td>
                            {viewMode === "months" ? (
                                Array(46).fill().map((_, dayIndex) => (
                                    <td
                                        key={dayIndex}
                                        onMouseEnter={() => setHoveredColumn(dayIndex)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        {getEditor("Leistungen")}
                                    </td>
                                ))
                            ) : (
                                viewMode === "object" ? (
                                    <td>
                                        {getEditor("Leistungen")}
                                    </td>
                                ) : (
                                    tableHeadersSeasons.map((season, seasonIndex) => (
                                        <td
                                            key={seasonIndex}
                                            onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                            onMouseLeave={() => setHoveredColumn(null)}
                                        >
                                            {getEditor("Leistungen")}
                                        </td>
                                    ))
                                )
                            )}
                        </tr>
                    ))}
                    <tr key="benutzerdefiniert-leistung-plus">
                        <td className="dtr-control ps-5">
                            <div className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2">
                                Leistung hinzufügen +
                            </div>
                        </td>
                    </tr>
                    <tr key="vordefiniert-leistung">
                        <td className="dtr-control">
                            Vordefinierte Leistungen
                            <span
                                className={`ms-2 olap-open-button ${openService ? 'active' : ''}`}
                                onClick={handleService}
                            >
                                <span className="icon"><RiArrowDownSLine /></span>
                            </span>
                        </td>
                    </tr>
                    {data[1].services.map((leistung, index) => (
                        <tr key={`vordefiniert-leistung-${index}`} className={`${openService ? 'd-table-row' : 'd-none'}`}>
                            <td className="dtr-control ps-5">
                                {leistung}
                            </td>
                            {viewMode === "months" ? (
                                Array(46).fill().map((_, dayIndex) => (
                                    <td
                                        key={dayIndex}
                                        onMouseEnter={() => setHoveredColumn(dayIndex)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        {getEditor("Leistungen")}
                                    </td>
                                ))
                            ) : (
                                viewMode === "object" ? (
                                    <td>
                                        {getEditor("Leistungen")}
                                    </td>
                                ) : (
                                    tableHeadersSeasons.map((season, seasonIndex) => (
                                        <td
                                            key={seasonIndex}
                                            onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                            onMouseLeave={() => setHoveredColumn(null)}
                                        >
                                            {getEditor("Leistungen")}
                                        </td>
                                    ))
                                )
                            )}
                        </tr>
                    ))}
                </Fragment>
            );
        }

        else{
            return data.map((label, index) => (
                <tr key={index}>
                    <td className="dtr-control">
                        <div className="rowTitle">
                            {label.type || label}
                            <OlapMiniTutorial Title={label.type || label} Text={optionTextData[label.type || label]} />
                        </div>
                    </td>
                    {viewMode === "months" ? (
                        Array(46).fill().map((_, dayIndex) => (
                            <td
                                key={dayIndex}
                                onMouseEnter={() => setHoveredColumn(dayIndex)}
                                onMouseLeave={() => setHoveredColumn(null)}
                            >
                                {getEditor(label)}
                            </td>
                        ))
                    ) : (
                        viewMode === "object" ? (
                            <td>
                                {getEditor(label)}
                            </td>
                        ) : (
                            tableHeadersSeasons.map((season, seasonIndex) => (
                                <td
                                    key={seasonIndex}
                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                    onMouseLeave={() => setHoveredColumn(null)}
                                >
                                    {getEditor(label)}
                                </td>
                            ))
                        )
                    )}
                </tr>
            ));
        }
    };

    return (
        <div className={`row`}>
            <div className={`col-sm-2 ${sidebarOpen ? '' : 'd-none'}`}>
                <div className='card'>
                    <div className='card-body'>
                        <OlapList handleSidebarChange={handleSidebarChange}/>
                    </div>
                </div>
            </div>
            <div className={`${sidebarOpen ? 'col-sm-10' : 'col-sm-12'}`}>
                <div className="card">
                    <div className="card-body">
                        <div className="olap-table">
                            <table id="alternative-pagination" className={`${viewMode === "seasons" ? "season-view" : ""} ${viewMode === "months" ? "month-view" : ""} table nowrap dt-responsive align-middle olap-table-hover table-bordered dataTable no-footer dtr-inline`}>
                                <thead>
                                <tr>
                                    <th className="dtr-control">
                                        <div className="dataTables_length">
                                            <select name="model-datatables_length" aria-controls="model-datatables" className="form-select form-select-sm" onChange={handleOptionChange} value={selectedOption}>
                                                {viewMode === "months" && (
                                                    <Fragment>
                                                        <option>Eigenschaften</option>
                                                        <option>Leistungen</option>
                                                        <option>Rabatte</option>
                                                    </Fragment>
                                                )}
                                                {viewMode === "seasons" && (
                                                    <Fragment>
                                                        <option>Eigenschaften</option>
                                                        <option>Kategorien</option>
                                                        <option>Leistungen</option>
                                                        <option>Rabatte</option>
                                                    </Fragment>
                                                )}
                                                {viewMode === "object" && (
                                                    <Fragment>
                                                        <option>Eigenschaften</option>
                                                        <option>Beschreibungstexte</option>
                                                        <option>Dokumente</option>
                                                        <option>Kategorien</option>
                                                        <option>Medien</option>
                                                        <option>Leistungen</option>
                                                        <option>Rabatte</option>
                                                    </Fragment>
                                                )}
                                            </select>
                                        </div>
                                    </th>
                                    {viewMode === "months" ? (
                                        <Fragment>
                                            <th colSpan="31">
                                                <div className="table-month">
                                                    <span>Januar 2024</span>
                                                </div>
                                            </th>
                                            <th colSpan="15">
                                                <div className="table-month">
                                                    <span>Februar 2024</span>
                                                </div>
                                            </th>
                                        </Fragment>
                                    ) : viewMode === "object" ? (
                                        <th colSpan={tableHeadersObject.length}>
                                            <div className="table-month">
                                                <span>{tableHeadersObject[0].type}</span>
                                            </div>
                                        </th>
                                    ) : (
                                        tableHeadersSeasons.map((season, seasonIndex) => (
                                            <th
                                                key={seasonIndex}
                                                colSpan={season.periods.length}
                                                className={seasonIndex === hoveredColumn ? 'hovered' : ''}
                                            >
                                                <div className="table-month">
                                                    <span>{season.name}</span>
                                                </div>
                                            </th>
                                        ))
                                    )}

                                </tr>
                                <tr>
                                    <th className="dtr-control">
                                        {selectedOption}
                                    </th>
                                    {
                                        viewMode === "months" ? (
                                            tableHeaders.map((header, index) => (
                                                <th
                                                    key={index}
                                                    className={index === hoveredColumn ? 'hovered' : ''}
                                                >
                                                    {header}
                                                </th>
                                            ))
                                        ) : viewMode === "object" ? (
                                            <th colSpan={tableHeadersObject.length}>
                                                <div className="table-month">
                                                    <span>{tableHeadersObject[0].name}</span>
                                                </div>
                                            </th>
                                        ): (
                                            tableHeadersSeasons.map((season, seasonIndex) => (
                                                <th key={seasonIndex}  className={seasonIndex === hoveredColumn ? 'hovered' : ''}>
                                                    {season.periods.map((period, periodIndex) => (
                                                        <span
                                                            key={periodIndex}
                                                        >
                                                                 <span style={{ display: periodIndex === 0 ? 'none' : 'inline' }}> &</span> {period}
                                                            </span>
                                                    ))}
                                                </th>
                                            ))
                                        )
                                    }
                                </tr>
                                </thead>
                                <tbody>
                                {generateTableRows()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );

}

export default OlapManagementTable;
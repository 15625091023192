import {RiCheckLine, RiCloseCircleLine} from "react-icons/ri";
import React from "react";

const WizardSidebar = ({step}) => {
    return (
        <div className="nav flex-column custom-nav nav-pills">
            <span className={`nav-link ${step === 0 && 'active'} ${step >= 1 && 'done'}`}>
                <span className="step-title me-2">
                    <i className="step-icon me-2">
                        {step >= 0 ? (
                            <RiCheckLine  />
                            ) : (
                                <RiCloseCircleLine />
                                )
                        }
                    </i>
                    Schritt 1
                </span>
                Saisonkalender
            </span>
            <span className={`nav-link d-none ${step === 1 && 'active'} ${step >= 1 && 'done'}`}>
                <span className="step-title me-2">
                    <i className="step-icon me-2">
                        {step >= 1 ? (
                            <RiCheckLine  />
                            ) : (
                                <RiCloseCircleLine />
                                )
                        }
                    </i>
                    Schritt 2
                </span>
                Vermieten Sie das ganze Jahr?
            </span>
            <span className={`nav-link d-none ${step === 1 && 'active'} ${step >= 1 && 'done'}`}>
                <span className="step-title me-2">
                    <i className="step-icon me-2">
                        {step >= 1 ? (
                            <RiCheckLine  />
                            ) : (
                                <RiCloseCircleLine />
                                )
                        }
                    </i>
                    Schritt 3
                </span>
                Welche Regionen?
            </span>
            <span className={`nav-link ${step === 1 && 'active'} ${step >= 1 && 'done'}`}>
                <span className="step-title me-2">
                    <i className="step-icon me-2">
                        {step >= 1 ? (
                            <RiCheckLine  />
                        ) : (
                            <RiCloseCircleLine />
                        )
                        }
                    </i>
                    Schritt 2
                </span>
                Saisons
            </span>
        </div>
    );
};

export default WizardSidebar;

import React, {useEffect, useState} from 'react';
import { RiCloseLine } from 'react-icons/ri';

const OlapServiceModal = () => {
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
    const [editState, setEditState] = useState({
        bezeichnung: '',
        einheit: '',
        verwendung: '',
        max: '',
        ageFrom: '',
        ageTo: '',
        inklusive: '',
        whereBooking: '',
        netto: '',
        hinweis: '',
        provisionierbar: '',
        active: '',
    });

    const openServiceModal = () => {
        setIsServiceModalOpen(true);
    };

    const closeServiceModal = () => {
        setIsServiceModalOpen(false);
    };

    const submitServiceModal = () => {
        setIsServiceModalOpen(false);
    };

    const change = (e) => {
        setEditState({ ...editState, [e.target.name]: e.target.value });
    };

    return (
        <div className="row" style={{zIndex: "1050"}}>
            <div className="col-9">

            </div>
            <div className="col-3">
                <div onClick={openServiceModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    auswählen und hinzufügen
                </div>
                <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isServiceModalOpen ? 'show' : ''}`}
                     style={{ display: isServiceModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <h5 className='modal-title'>
                                    Leistungen
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeServiceModal} />
                            </div>
                            <div className='modal-body'>
                                <div>
                                    <label className='form-label mt-2'>
                                        Bezeichnung
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='name'
                                        value={editState.bezeichnung}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Einheit
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='beschreibung'
                                        value={editState.einheit}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Verwendung
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='beschreibung'
                                        value={editState.verwendung}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Maximale Anzahl
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='beschreibung'
                                        value={editState.max}
                                        onChange={change}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label className='form-label mt-2'>
                                            Alter von
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='from'
                                            value={editState.ageFrom}
                                            onChange={change}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <label className='form-label mt-2'>
                                            Alter bis
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='to'
                                            value={editState.ageTo}
                                            onChange={change}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Inkusive
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='maxDay'
                                        value={editState.inklusive}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Nur vor Ort buchbar
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='minDay'
                                        value={editState.whereBooking}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Nettopreis
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='active'
                                        value={editState.netto}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Hinweis
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='active'
                                        value={editState.hinweis}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Aktiviert
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='active'
                                        value={editState.active}
                                        onChange={change}
                                    />
                                </div>
                            </div>
                            <div className='modal-footer'>
                            <span type='button' className='btn btn-light' onClick={closeServiceModal}>
                                Abbrechen
                            </span>
                                <span className='btn btn-success' onClick={submitServiceModal}>
                                Speichern
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OlapServiceModal;

import React, {useState, useEffect} from 'react';
import {RiCloseLine, RiEdit2Line, RiHotelBedLine, RiTableLine} from 'react-icons/ri';
import ObjectService from "../../../services/ObjectService";
import {useAuth} from "../../../hooks/useAuth";
import {Link} from 'react-router-dom';

const CategoryManagementsObject = ({ item, onCheckboxChange, isSelected, categoryId, placeId, onSubmit }) => {
    const {user} = useAuth();
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [state, setState] = useState(
        item
    );

    useEffect(() => {
        setState(item);
    }, [item]); // eslint-disable-line react-hooks/exhaustive-deps

    const change = (e) => {
        const newName = e.target.value;
        setState((prevState) => ({ ...prevState, name: newName }));
    };

    const activateEditing = () => {
        setIsEditing(true);
    };

    const saveChanges = async () => {
        try {
            setIsEditing(false);
            await ObjectService.putObject(item.id, user, state);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    const handleCheckboxChange = (e) => {
        const isChecked = e.target.checked;
        onCheckboxChange(item.id, isChecked);
    };

    useEffect(() => {
        if (isSelected !== undefined) {
            setState((prevState) => ({
                ...prevState,
                isSelected,
            }));
        }
    }, [isSelected]); // eslint-disable-line react-hooks/exhaustive-deps

    const openDeleteModal = () => {
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const submitDelete = async (e) => {
        e.preventDefault();
        try {
            setIsDeleteModalOpen(false);
            await ObjectService.deleteObject(item.id, user);
            onSubmit();
        } catch (err) {
            //TODO
            throw err;
        }
    };

    return (
        <tr>
            <td style={{width: "75%"}}>
                <input className='form-control filelist-id' type='hidden' value='1' id='filelist-1' />
                <div className='d-flex align-items-center'>
                    <div className='flex-shrink-0 fs-17 me-2 filelist-icon'>
                        <input
                            className='form-check-input cursor-pointer object-delete-marker'
                            type='checkbox'
                            value={`object-delete-marker-${item.id}`}
                            name='object-delete-marker'
                            id={`object-delete-marker-${item.id}`}
                            onChange={handleCheckboxChange}
                            checked={state.isSelected || false}
                        />
                    </div>
                    <div
                        onClick={activateEditing}
                        className='flex-grow-1 filelist-name'
                    >
                        <RiHotelBedLine className="text-success me-2" style={{fontSize: "18px"}}/>
                        {isEditing ? (
                            <input
                                type="text"
                                className="form-control form-control-edit"
                                value={state.name}
                                onChange={change}
                                onBlur={saveChanges}
                                autoFocus={true}
                            />
                        ) : (
                            state.name
                        )}
                        <small className='text-muted'>
                            {item.channels.length > 0 && item.channels[0].channelType === "CPO" ? " (" + item.channels[0].channelDataMap.cpoId + " - " + item.channels[0].channelDataMap.name + ")" : ''}
                        </small>
                    </div>
                </div>
            </td>
            <td>
                <div className='d-flex gap-3 justify-content-center'>
                    <Link to={`/olap/place/${placeId}/category/${categoryId}/object/${item.id}`} className='text-secondary btn btn-ghost-secondary btn-icon btn-sm cp-tooltip'>
                        <RiTableLine className='ri-more-fill align-bottom' />
                        <span className="tooltiptext">Eigenschaften</span>
                    </Link>

                    <span
                        className='text-success btn btn-ghost-success btn-icon btn-sm cp-tooltip'
                        onClick={activateEditing}
                    >
                        <RiEdit2Line className='ri-more-fill align-bottom' />
                        <span className="tooltiptext">Bearbeiten</span>
                    </span>
                    <span
                        className='text-danger btn btn-ghost-danger btn-icon btn-sm cp-tooltip'
                        onClick={openDeleteModal}
                    >
                        <RiCloseLine className='ri-more-fill align-bottom' />
                        <span className="tooltiptext">Löschen</span>
                    </span>
                    <div
                        className={`modal fade ${isDeleteModalOpen ? 'show' : ''}`}
                        style={{ display: isDeleteModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                        aria-hidden={!isDeleteModalOpen}
                    >
                        <div className='modal-dialog'>
                            <div className='modal-content'>
                                <div className='modal-header'>
                                    <h5 className='modal-title'>Objekt "{item.name}" löschen</h5>
                                    <button
                                        type='button'
                                        className='btn-close'
                                        aria-label='Close'
                                        onClick={closeDeleteModal}
                                    />
                                </div>
                                <div className='modal-body'>
                                    <div>
                                        Wollen Sie wirklich Objekt "{item.name}" löschen?<br/>
                                        <br/>
                                        Das Objekt kann nach dem Löschen nicht wieder hergestellt werden.
                                    </div>
                                </div>
                                <div className='modal-footer'>
                                    <button type='button' className='btn btn-light' onClick={closeDeleteModal}>
                                        Abbrechen
                                    </button>
                                    <button type='submit' className='btn btn-danger' onClick={submitDelete}>
                                        Löschen
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
    );
};

export default CategoryManagementsObject;

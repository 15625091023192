import React, {Fragment, useEffect, useState} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import OlapManagementHeader from "../Management/OlapManagementHeader";
import OlapTutorial from "../Management/OlapTutorial";
import {useAuth} from "../../../hooks/useAuth";
import Loader from "../../../components/Loader";
import PlaceService from "../../../services/PlaceService";
import PlacePropertiesNavbar from "./PlacePropertiesNavbar";
import PlacePropertiesTable from "./PlacePropertiesTable";

const PlaceProperties = () => {
    const [loading, setLoading] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const { placeId } = useParams();
    const [place, setPlace] = useState({});
    const location = useLocation();
    const { user } = useAuth();

    const fetchPlace = async () => {
        try {
            setLoading(true);
            const response = await PlaceService.getPlace(placeId, user);
            setPlace(response.data);
            setLoading(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    useEffect(() => {
        fetchPlace();
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSidebarChange = () => {
        setSidebarOpen(!sidebarOpen);
    }

    const fetchPlaceWithoutLoad = async () => {
        try {
            const response = await PlaceService.getPlace(placeId, user);
            setPlace(response.data);
            setLoading(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    const onSubmit = async () => {
        await fetchPlaceWithoutLoad();
    }

    return (
        <Fragment>
            <OlapManagementHeader />
            {loading ? (
                <Loader card={false} />
            ) : (
                <Fragment>
                    <PlacePropertiesNavbar handleSidebarChange={handleSidebarChange} item={place} />
                    <PlacePropertiesTable item={place} sidebarOpen={sidebarOpen} onSubmit={onSubmit} />
                </Fragment>
            )}
            <OlapTutorial />
        </Fragment>
    );
};

export default PlaceProperties;
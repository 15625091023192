import React, {useEffect, useState} from 'react';
import { useAuth } from '../../../hooks/useAuth';
import CategoryService from "../../../services/CategoryService";

const CategoryBookable = ({ tag, category, onSubmit }) => {
    const { user } = useAuth();

    const { id, bookingObjectSeasonProperties, ...restFullState } = category;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState
    });

    useEffect(() => {
        const { id, bookingObjectSeasonProperties, ...restFullState } = category;

        setFullStateEdit({
            ...restFullState
        });

    }, [category]);

    const change = (e) => {
        let newValue = null;

        newValue = e.target.checked;

        setFullStateEdit((prevState) => ({
            ...prevState,
            [tag]: newValue,
        }));
    };

    const saveChanges = async () => {
        try {
            await CategoryService.putCategory(category.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    return (
        <div className='text'>
            Ist die Kategorie buchbar?
            <label className='form-check-label ms-2' htmlFor='bookable'>
                Ja
            </label>
            <input
                id='bookable'
                type='checkbox'
                className='form-check-input ms-2 cursor-pointer'
                value='bookable'
                onChange={change}
                onBlur={saveChanges}
                checked={fullStateEdit[tag]}
            />
        </div>
    );
};

export default CategoryBookable;
import axios from 'axios';
import config from '../config.json';

class OrganizationService {
    static async getOrganizations(user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/organizations`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postOrganization(user, state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/organizations`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putOrganization(orgId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/organizations/${orgId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteOrganization(orgId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/organizations/${orgId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }
}

export default OrganizationService;

import React from "react";

const ObjectAndSeasonsHeader = () => {
    return (
        <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <h4 className='mb-sm-0'>Objekte und Saisons zusammenführen</h4>
        </div>
    );
}

export default ObjectAndSeasonsHeader;
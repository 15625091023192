import React from 'react';

const Loader = ({ card = true }) => {
    return (
        <div className={card ? 'card' : ''}>
            <div className={card ? 'card-body text-center' : 'text-center'}>
                <div className='spinner-border text-secondary' role='status'>
                    <span className='sr-only'>Loading...</span>
                </div>
            </div>
        </div>
    );
};

export default Loader;

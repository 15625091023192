import React, { useEffect, useState } from 'react';
import CategoryManagementsObject from './CategoryManagementsObject';
import { useLocation, useParams } from 'react-router-dom';
import ObjectService from '../../../services/ObjectService';
import { useAuth } from '../../../hooks/useAuth';
import Loader from '../../../components/Loader';

const CategoryManagementObjects = () => {
    const { categoryId, placeId } = useParams();
    const { user } = useAuth();
    const [loading, setLoading] = useState(true);
    const [selectedObjects, setSelectedObjects] = useState([]);

    let location = useLocation();

    const [objects, setObjects] = useState([]);
    const [sortedObjects, setSortedObjects] = useState([]);

    const fetch = async () => {
        try {
            const response = await ObjectService.getObjects(categoryId, user);
            const filteredObjects = response.data.filter((item) => {
                if (!item.channels || item.channels.length === 0) {
                    return true;
                }
                return !item.channels[0].channelDataMap.deleted;
            });
            setObjects(filteredObjects);
            setLoading(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    useEffect(() => {
        fetch();
        setSelectedObjects([]);
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    //sort objects by name
    useEffect(() => {
        if (objects.length > 0) {
            const sortedObjects = objects.sort((a, b) => {
                if (a.name[0].text < b.name[0].text) {
                    return -1;
                }
                if (a.name[0].text > b.name[0].text) {
                    return 1;
                }
                return 0;
            });
            setSortedObjects(sortedObjects);
        }
    }, [location, objects]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleCheckboxChange = (objectId, isChecked) => {
        if (isChecked) {
            setSelectedObjects((prevSelected) => [...prevSelected, objectId]);
        } else {
            setSelectedObjects((prevSelected) =>
                prevSelected.filter((id) => id !== objectId)
            );
        }
    };

    const toggleSelectAll = () => {
        if (selectedObjects.length === objects.length) {
            setSelectedObjects([]);
        } else {
            const allObjectIds = objects.map((item) => item.id);
            setSelectedObjects(allObjectIds);
        }
    };

    const multiDelete = () => {
        selectedObjects.forEach(async (objectId) => {
            try {
                await ObjectService.deleteObject(objectId, user);
                fetch();
            } catch (err) {
                throw err;
            }
        });
        setSelectedObjects([]);
    }

    const onSubmit = () => {
        fetch();
    }

    return (
        <div className='table-list'>
            <div className='d-flex align-items-center mb-3'>
                <h5 className='flex-grow-1 fs-16 mb-0' id='filetype-title'>
                    Objekte
                </h5>
                <div className="flex-shrink-0">
                    <button onClick={multiDelete} className="btn btn-danger createFile-modal" disabled={selectedObjects.length === 0}>
                        {selectedObjects.length} markierte Objekte löschen
                    </button>
                </div>
            </div>
            <div className='table-responsive'>
                <table className='table align-middle table-nowrap mb-0'>
                    <thead className='table-active'>
                        <tr>
                            <th scope='col'>
                                <div className="d-flex align-items-md-center">
                                    <div className='flex-shrink-0 fs-17 me-2 filelist-icon'>
                                        <input
                                            className="form-check-input  cursor-pointer object-delete-marker"
                                            type="checkbox"
                                            id='selectAllCheckbox'
                                            checked={selectedObjects.length === objects.length}
                                            onChange={toggleSelectAll}
                                        />
                                    </div>
                                    <div className='flex-grow-1 filelist-name'>Name</div>
                                </div>
                            </th>
                            <th scope='col' className='text-center'>
                                Aktionen
                            </th>
                        </tr>
                    </thead>
                    <tbody id='file-list'>
                        {loading ? (
                            <tr>
                                <td colSpan={3}>
                                    <Loader />
                                </td>
                            </tr>
                        ) : (
                            sortedObjects.map((item) => <CategoryManagementsObject
                                onCheckboxChange={handleCheckboxChange}
                                categoryId={categoryId}
                                item={item}
                                placeId={placeId}
                                onSubmit={onSubmit}
                                key={item.id}
                                isSelected={selectedObjects.includes(item.id)}
                            />)
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default CategoryManagementObjects;

import React, {useEffect, useRef, useState} from 'react';
import { RiFolderFill, RiHotelFill, RiUserLocationLine, RiMore2Fill } from 'react-icons/ri';
import {Link} from 'react-router-dom';
import $ from "jquery";
import PlaceService from "../../../services/PlaceService";
import {useOrganization} from "../../../hooks/useOrganization";
import {useAuth} from "../../../hooks/useAuth";

const PlaceCard = ({ item, fetchPlaces }) => {
    const {organization} = useOrganization();
    const {user} = useAuth();

    const dropdownRef = useRef();
    const dropdownContentRef = useRef();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [errors, setErrors] = useState('');
    const [isEditing, setIsEditing] = useState(false);
    const [state, setState] = useState(
        item
    );

    useEffect(() => {
        setState(item)
    }, [item]); // eslint-disable-line react-hooks/exhaustive-deps

    const change = (e) => {
        const newName = e.target.value;
        setState((prevState) => ({ ...prevState, name: newName }));
    };

    const activateEditing = () => {
        setIsEditing(true);
    };

    const saveChanges = async () => {
        try {
            setIsEditing(false);
            await PlaceService.putPlace(item.id, user, state);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    const click = () => {
        if (dropdownRef.current.classList.contains('show')) {
            dropdownRef.current.classList.remove('show');
            $(dropdownContentRef.current).slideUp();
        } else {
            dropdownRef.current.classList.add('show');
            $(dropdownContentRef.current).slideDown();
        }
    };

    const clickEdit = () => {
        click();
        activateEditing();
    };

    const openDeleteModal = () => {
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        click();
    };

    const submitDelete = async (e) => {
        e.preventDefault();
        try {
            setIsDeleteModalOpen(false);
            click();
            await PlaceService.deletePlace(item.id, user);
            fetchPlaces();
        } catch (err) {
            setErrors(err.response.data.errors);
        }
    };


    useEffect(() => {
        const errorTimer = setTimeout(() => {
            setErrors('');
        }, 5000);

        return () => {
            clearTimeout(errorTimer);
        };
    }, [errors]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='col-xxl-3 col-6 folder-card'>
            <div className='card bg-light shadow-none'>
                <div className="dropdown-container-absolute">
                    <div className='d-flex mb-1'>
                        <div className='dropdown'>
                            <button
                                className='btn btn-ghost-primary btn-icon btn-sm dropdown'
                                type='button'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                                onClick={click}
                                ref={dropdownRef}
                            >
                                <RiMore2Fill className='fs-16' />
                            </button>
                            <ul ref={dropdownContentRef} className='dropdown-menu dropdown-menu-end' style={{zIndex: "1054"}}>
                                <li>
                                    <span
                                        className='dropdown-item edit-folder-list'
                                        data-bs-toggle='modal'
                                        role='button'
                                        onClick={clickEdit}
                                    >
                                        Bearbeiten
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <span className='dropdown-item' data-bs-toggle='modal' role='button' onClick={openDeleteModal}>
                                        Löschen
                                    </span>
                                        <div
                                            className={`modal fade ${isDeleteModalOpen ? 'show' : ''}`}
                                            style={{ display: isDeleteModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                                            aria-hidden={!isDeleteModalOpen}
                                        >
                                            <div className='modal-dialog'>
                                                <form onSubmit={submitDelete}>
                                                    <div className='modal-content'>
                                                        <div className='modal-header'>
                                                            <h5 className='modal-title'>Platz "{item.name}" löschen</h5>
                                                            <button
                                                                type='button'
                                                                className='btn-close'
                                                                aria-label='Close'
                                                                onClick={closeDeleteModal}
                                                            />
                                                        </div>
                                                        <div className='modal-body'>
                                                            <div>
                                                                Wollen Sie wirklich Platz "{item.name}" löschen?<br/>
                                                                <br/>
                                                                Der Platz kann nach dem Löschen nicht wieder hergestellt werden.
                                                            </div>
                                                        </div>
                                                        <div className='modal-footer'>
                                                            <button type='button' className='btn btn-light' onClick={closeDeleteModal}>
                                                                Abbrechen
                                                            </button>
                                                            <button type='submit' className='btn btn-danger'>
                                                                Löschen
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={`toastify ${errors.SQL ? 'on' : ''} bg-danger toastify-center toastify-top`} aria-live="polite" style={{transform: "translate(0px, 0px)", top: "15px"}}>Der Platz: {state.name} hat noch Objektkategorien. Bitte vorher löschen.</div>
                <Link to={`/management/place/${item.id}`}>
                    <div className='card-body'>
                        <div className='d-flex mb-1'>
                            <div className='form-check form-check-danger mb-3 fs-15 flex-grow-1'></div>
                        </div>

                        <div className='text-center'>
                            <div className='mb-2'>
                                <RiUserLocationLine className='text-success display-5' />
                            </div>
                            <h6 className='fs-15 folder-name'  onClick={activateEditing}>
                                {isEditing ? (
                                    <input
                                        type="text"
                                        className="form-control form-control-edit text-center"
                                        value={state.name}
                                        onChange={change}
                                        onBlur={saveChanges}
                                        autoFocus={true}
                                    />
                                ) : (
                                    state.name
                                )}
                            </h6>
                        </div>
                        <div className='hstack mt-4 text-muted'>

                        </div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default PlaceCard;

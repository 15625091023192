import React from 'react';
import SimpleBar from 'simplebar-react';
import ObjectManagementMenu from './ObjectManagementMenu';

const ObjectManagementContent = ({ children, title = '' }) => {
    return (
        <div className='file-manager-content w-100 p-3 py-0'>
            <SimpleBar className='mx-n3 pt-4 px-4 file-manager-content-scroll'>
                <div id='folder-list' className='mb-2'>
                    <div className='row justify-content-beetwen g-2 mb-3'>
                        <div className='col'>
                            <div className='d-flex align-items-center'>
                                <div className='flex-shrink-0 me-2 d-block d-lg-none'>
                                    <button
                                        type='button'
                                        className='btn btn-soft-success btn-icon btn-sm fs-16 file-menu-btn'
                                    >
                                        <i className='ri-menu-2-fill align-bottom'></i>
                                    </button>
                                </div>
                                <div className='flex-grow-1'>
                                    <h5 className='fs-16 mb-0'>{title}</h5>
                                </div>
                            </div>
                        </div>

                        <div className='col-auto'>
                            <div className='d-flex gap-2 align-items-start'>
                                <ObjectManagementMenu />
                            </div>
                        </div>
                    </div>
                    {children}
                </div>
            </SimpleBar>
        </div>
    );
};

export default ObjectManagementContent;

import React, {useEffect, useState} from 'react';
import {RiEdit2Line, RiFolder2Line} from 'react-icons/ri';
import { Link, useParams } from 'react-router-dom';
import ManagementSidebarSubEntry from './ManagementSidebarSubEntry';
import {useOrganization} from "../../hooks/useOrganization";
import PlaceService from "../../services/PlaceService";
import { useLocation } from 'react-router-dom';
import {useAuth} from "../../hooks/useAuth";
import CategoryService from "../../services/CategoryService";

const ManagementSidebarEntry = ({ item }) => {
    const { organization } = useOrganization();
    const {user} = useAuth();
    const { placeId } = useParams();
    const [isEditing, setIsEditing] = useState(false);
    const [state, setState] = useState(
        item
    );

    useEffect(() => {
        setState(item)
    }, [item]);

    let location = useLocation();

    const [categories, setCategories] = useState([]);

    const fetch = async () => {
        try {
            const response = await CategoryService.getCategories(item.id, user);
            const filteredCategories = response.data.filter((item) => {
                if (!item.channels || item.channels.length === 0) {
                    return true;
                }
                return !item.channels[0].channelDataMap.deleted;
            });
            setCategories(filteredCategories);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    useEffect(() => {
        fetch();
    }, [location]);


    const change = (e) => {
        const newName = e.target.value;
        setState((prevState) => ({ ...prevState, name: newName }));
    };

    const activateEditing = () => {
        if (parseInt(placeId) === item.id) {
            setIsEditing(true);
        }
    };

    const saveChanges = async () => {
        try {
            setIsEditing(false);
            await PlaceService.putPlace(item.id, user, state);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    return (
        <li>
            <Link
                data-bs-toggle='collapse'
                role='button'
                aria-expanded='false'
                aria-controls='collapseExample'
                className={`${parseInt(placeId) === item.id ? 'active' : ''}`}
                to={`/management/place/${item.id}`}
            >
                <RiFolder2Line className='me-2' />
                {isEditing ? (
                    <input
                        type="text"
                        className="form-control form-control-edit"
                        value={state.name}
                        onChange={change}
                        onBlur={saveChanges}
                        autoFocus={true}
                    />
                ) : (
                    <span
                        className='file-list-link'
                        onClick={activateEditing}
                    >
                        {state.name}
                        <i>
                            <RiEdit2Line className='ms-1' />
                        </i>
                    </span>
                    )}
            </Link>
            <div className={`collapse ${parseInt(placeId) === item.id ? 'show' : ''}`}>
                <ul className='sub-menu list-unstyled'>
                    {categories.map((child) => (
                            <ManagementSidebarSubEntry item={child} parent={item} key={child.id} />
                        ))}
                </ul>
            </div>
        </li>
    );
};

export default ManagementSidebarEntry;

import React from 'react';
import { useOrganization } from '../../hooks/useOrganization';

const ObjectManagementHeader = ({ place = '', category = '' }) => {
    const { organization } = useOrganization();

    return (
        <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <h4 className='mb-sm-0'>{organization.name}</h4>
            {place !== '' && (
                <div className='page-title-right'>
                    <ol className='breadcrumb m-0'>
                        <li className='breadcrumb-item'>
                            <span className=''>{place}</span>
                        </li>
                        <li className='breadcrumb-item active'>{category}</li>
                    </ol>
                </div>
            )}
        </div>
    );
};

export default ObjectManagementHeader;

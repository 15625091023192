import React, { useState } from 'react';
import { isEmpty } from '../../utils/validation';
import { useNavigate, useParams } from 'react-router-dom';
import CategoryService from '../../services/CategoryService';
import { useAuth } from '../../hooks/useAuth';
import { useOrganization } from '../../hooks/useOrganization';

const AddCategoryModal = ({ onClickClose = () => {} }) => {
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [errors, setErrors] = useState({
        name: false,
    });

    const { placeId } = useParams();
    const { user } = useAuth();
    const { organization } = useOrganization();
    const navigate = useNavigate();

    const openCategoryModal = () => {
        setIsCategoryModalOpen(true);
    };

    const closeCategoryModal = () => {
        setIsCategoryModalOpen(false);
        onClickClose();
    };

    const [addCategoryState, setAddCategoryState] = useState({
        name: '',
        bookingProperties: {blocked: false},
        bookable: false
    });

    const change = (e) => {
        setAddCategoryState({ ...addCategoryState, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let hasErrors = false;
        let errors = {};
        for (const key in addCategoryState) {
            if (isEmpty(addCategoryState[key])) {
                errors[key] = `${key} is required`;
                hasErrors = true;
            }
        }
        if (hasErrors) {
            setErrors(errors);
        }
        return !hasErrors;
    };

    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                onClickClose();
                setIsCategoryModalOpen(false);
                const response = await CategoryService.postCategory(placeId, user, organization, addCategoryState);
                setAddCategoryState({
                    name: '',
                    bookingProperties: {blocked: false},
                    bookable: false
                });
                navigate(`/management/place/${response.placeId}/category/${response.categoryId}`, { replace: true });
            } catch (err) {
                //TODO
                throw err;
            }
        }
        return false;
    };
    return (
        <div>
            <span className='dropdown-item viewfile-list' role='button' onClick={openCategoryModal}>
                Objektkategorie
            </span>
            <div
                className={`modal fade ${isCategoryModalOpen ? 'show' : ''}`}
                style={{ display: isCategoryModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                aria-hidden={!isCategoryModalOpen}
            >
                <div className='modal-dialog'>
                    <form onSubmit={submit}>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Neue Objektkategorie hinzufügen</h5>
                                <button
                                    type='button'
                                    className='btn-close'
                                    aria-label='Close'
                                    onClick={closeCategoryModal}
                                />
                            </div>
                            <div className='modal-body'>
                                <div>
                                    <label className='form-label'>Objektkategoriename</label>
                                    <input
                                        type='text'
                                        className={`form-control ${errors.name && 'is-invalid'}`}
                                        name='name'
                                        value={addCategoryState.name}
                                        onChange={change}
                                    />
                                    <div className='invalid-feedback'>{errors.name}</div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-light' onClick={closeCategoryModal}>
                                    Abbrechen
                                </button>
                                <button type='submit' className='btn btn-success'>
                                    Speichern
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddCategoryModal;

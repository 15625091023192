import React, {useEffect, useRef, useState} from "react";
import {useLocation, Link, useParams} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import CategoryService from "../../services/CategoryService";
import Loader from "../../components/Loader";
import AlertCard from "../../components/AlertCard";
import OlapCategories from "./OlapCategories";
import { RiUserLocationLine, RiArrowDownSLine  } from 'react-icons/ri';
import $ from "jquery";

const OlapPlaces = ({ item, handleSidebarChange }) => {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    const { placeId } = useParams();

    let location = useLocation();

    const linkRef = useRef();
    const collapseRef = useRef();

    const { user } = useAuth();

    const fetchCategories = async () => {
        const response = await CategoryService.getCategories(item.id, user);
        const filteredCategories = response.data.filter((item) => {
            if (!item.channels || item.channels.length === 0) {
                return true;
            }
            return !item.channels[0].channelDataMap.deleted;
        });
        setCategories(filteredCategories);
        setLoading(false);
    };

    useEffect(() => {
        fetchCategories();
    }, [location]);

    const click = (e) => {
        if (linkRef.current.classList.contains('active')) {
            linkRef.current.classList.remove('active');
            linkRef.current.setAttribute('aria-expanded', 'false');
            $(collapseRef.current).slideUp();
        } else {
            linkRef.current.classList.add('active');
            linkRef.current.setAttribute('aria-expanded', 'true');
            $(collapseRef.current).slideDown();
        }
    };

    return (
        <ul className="col-lg-12 list-unstyled file-manager-menu">
            <li>
                <Link to={`/olap/place/${item.id}`} className={`${placeId === item.id.toString() ? 'active' : ''}`}>
                    <RiUserLocationLine className="me-2"/>
                    <span>
                        {item.name}
                    </span>
                </Link>
                <span className="ms-2 active olap-sidebar-button" aria-expanded='false' ref={linkRef} onClick={click}>
                    <span className="icon"><RiArrowDownSLine /></span>
                </span>
                <div className='menu-dropdown' ref={collapseRef}>
                    {loading ? (
                        <Loader card={false} />
                    ) : (
                        <ul className='sub-menu list-unstyled' id='folderlist-data'>
                            {categories.length === 0 && <AlertCard>In diesem Platz gibt es noch keine Objektkategorien.</AlertCard>}
                            {categories.map((child, idx) => (
                                <OlapCategories parent={item} item={child} key={idx} handleSidebarChange={handleSidebarChange}/>
                            ))}
                        </ul>
                    )}
                </div>
            </li>
        </ul>
    );
}

export default OlapPlaces;
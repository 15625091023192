import React, { useState } from 'react';
import { isEmpty } from '../../utils/validation';
import { useNavigate } from 'react-router-dom';
import PlaceService from '../../services/PlaceService';
import { useOrganization } from '../../hooks/useOrganization';
import { useAuth } from '../../hooks/useAuth';

const AddPlaceModal = ({ onClickClose = () => {} }) => {
    const [isPlaceModalOpen, setIsPlaceModalOpen] = useState(false);
    const [errors, setErrors] = useState({
        name: false,
    });

    const { organization } = useOrganization();
    const { user } = useAuth();

    const navigate = useNavigate();

    const openPlaceModal = () => {
        setIsPlaceModalOpen(true);
    };

    const closePlaceModal = () => {
        setIsPlaceModalOpen(false);
        onClickClose();
    };

    const [addPlaceState, setAddPlaceState] = useState({
        name: '',
    });

    const change = (e) => {
        setAddPlaceState({ ...addPlaceState, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let hasErrors = false;
        let errors = {};
        for (const key in addPlaceState) {
            if (isEmpty(addPlaceState[key])) {
                errors[key] = `${key} is required`;
                hasErrors = true;
            }
        }
        if (hasErrors) {
            setErrors(errors);
        }
        return !hasErrors;
    };

    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                onClickClose();
                setIsPlaceModalOpen(false);
                const response = await PlaceService.postPlace(organization.id, user, addPlaceState);
                setAddPlaceState({
                    name: '',
                });
                navigate(`/management/place/${response.data.id}`, { replace: true });
            } catch (err) {
                //TODO
                throw err;
            }
        }
        return false;
    };

    return (
        <div>
            <span className='dropdown-item viewfile-list' role='button' onClick={openPlaceModal}>
                Platz
            </span>
            <div
                className={`modal fade ${isPlaceModalOpen ? 'show' : ''}`}
                style={{ display: isPlaceModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                aria-hidden={!isPlaceModalOpen}
            >
                <div className='modal-dialog'>
                    <form onSubmit={submit}>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title' id='myModalLabel'>
                                    Neuen Platz hinzufügen
                                </h5>
                                <button
                                    type='button'
                                    className='btn-close'
                                    aria-label='Close'
                                    onClick={closePlaceModal}
                                />
                            </div>
                            <div className='modal-body'>
                                <div>
                                    <label htmlFor='organisationname' className='form-label'>
                                        Platzname
                                    </label>
                                    <input
                                        type='text'
                                        className={`form-control ${errors.name && 'is-invalid'}`}
                                        name='name'
                                        value={addPlaceState.name}
                                        onChange={change}
                                    />
                                    <div className='invalid-feedback'>{errors.name}</div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-light' onClick={closePlaceModal}>
                                    Abbrechen
                                </button>
                                <button type='submit' className='btn btn-success'>
                                    Speichern
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddPlaceModal;

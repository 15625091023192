import React, {useEffect, useState} from 'react';
import OlapList from "../OlapList";
import CategorySeasonEditInput from "./CategorySeasonEditInput";
import CategorySeasonTimeInput from "./CategorySeasonTimeInput";
import CategorySeasonDaysCheckboxes from "./CategorySeasonDaysCheckboxes";
import {useOrganization} from "../../../hooks/useOrganization";
import {useAuth} from "../../../hooks/useAuth";
import {useLocation} from "react-router-dom";
import serviceService from "../../../services/ServiceService";
import OlapWiki from "../Management/OlapWiki";
import OlapOrganizationServices from "../OlapOrganizationServices";
import CategorySeasonServiceModal from "./CategorySeasonServiceModal";
import SimpleBar from "simplebar-react";

const CategroySeasonPropertiesTable = ({sidebarOpen, handleSidebarChange, item, onSubmit}) => {
    const [selectedOption, setSelectedOption] = useState("Eigenschaften");
    const [hoveredColumn, setHoveredColumn] = useState(null);
    const [seasonProperties, setSeasonProperties] = useState(
        item.bookingCategorySeasonProperties ? item.bookingCategorySeasonProperties : item.bookingCategorySeasonProperties
    );

    const [organizationServices, setOrganizationServices] = useState([]);
    const [seasonServices, setSeasonServices] = useState([]);
    const [seasonFormattedServices, setSeasonFormattedServices] = useState([]);

    const {organization} = useOrganization();
    const {user} = useAuth();
    let location = useLocation();

    useEffect(() => {
        setSeasonProperties(
            item.bookingCategorySeasonProperties ? item.bookingCategorySeasonProperties : item.bookingCategorySeasonProperties
        );
    }, [item]);

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const fetchOrganizationServices = async () => {
        const response = await serviceService.getOrganizationServices(organization.id, user);
        setOrganizationServices(response.data);
    }

    const fetchSeasonServices = async (season) => {
        const response = await serviceService.getCategorySeasonServices(item.id, season.season.id, user); //das hier ist ein array von services

        //hier sollen die einzelnen services einzelnt in ein object gepusht werden

        for (let i = 0; i < response.data.length; i++) {
            const service = response.data[i];
            setSeasonServices(prevSeasonServices => [...prevSeasonServices, service]);
        }
    }

    const formatSeasonServices = () => {
        const formattedServices = {};

        seasonServices.forEach(seasonService => {
            if (seasonService  && seasonService.service.id in formattedServices) {
                formattedServices[seasonService.service.id].seasonIds.push({id: seasonService.season.id ? seasonService.season.id : '', price: seasonService.price});
            } else {
                formattedServices[seasonService.service.id] = {
                    service: seasonService,
                    seasonIds: [{id: seasonService.season.id ? seasonService.season.id : '', price: seasonService.price}]
                };
            }
        });
        return Object.values(formattedServices);
    };

    const submitService = () => {
        setSeasonServices([]);
        fetchOrganizationServices();
        item.bookingCategorySeasonProperties.forEach(season => {
            fetchSeasonServices(season);
        });
    }

    useEffect(() => {
        setSeasonServices([]);
        fetchOrganizationServices();
        item.bookingCategorySeasonProperties.forEach(season => {
            fetchSeasonServices(season);
        });
    }, [location]);

    useEffect(() => {
        const formattedServices = formatSeasonServices();
        setSeasonFormattedServices(formattedServices);
    }, [seasonServices]);

    //sort seasonProperties by season name
    seasonProperties.sort((a, b) => {
        if (a.season.name < b.season.name) {
            return -1;
        }
        if (a.season.name > b.season.name) {
            return 1;
        }
        return 0;
    });

    return (
        <div className={`row`}>
            <div className={`col-sm-2 ${sidebarOpen ? '' : 'd-none'}`}>
                <div className='card'>
                    <div className='card-body'>
                        <SimpleBar>
                            <OlapList handleSidebarChange={handleSidebarChange}/>
                        </SimpleBar>
                    </div>
                </div>
            </div>
            <div className={`${sidebarOpen ? 'col-sm-10' : 'col-sm-12'}`}>
                <div className="card">
                    <div className="card-body">
                        <div className="olap-table">
                            <table id="alternative-pagination" className={`season-view table nowrap dt-responsive align-middle olap-table-hover table-bordered dataTable no-footer dtr-inline`}>
                                <thead>
                                    <tr>
                                        <th className="dtr-control">
                                            <div className="dataTables_length">
                                                <select name="model-datatables_length" aria-controls="model-datatables"
                                                        className="form-select form-select-sm"
                                                        onChange={handleOptionChange} value={selectedOption}>
                                                    <option>Eigenschaften</option>
                                                    <option>Leistungen</option>
                                                </select>
                                            </div>
                                        </th>
                                        {
                                            seasonProperties && seasonProperties.map((seasonProperty, seasonPropertyIndex) => (
                                                <th
                                                    key={seasonPropertyIndex}
                                                    className={seasonPropertyIndex === hoveredColumn ? 'hovered' : ''}
                                                >
                                                    <div className="table-month">
                                                        <span>{seasonProperty.season.name}</span>
                                                    </div>
                                                </th>
                                            ))
                                        }
                                        {
                                            seasonProperties.length === 0 && (
                                                <th className="dtr-control">
                                                    <div className="table-month">
                                                        <span>Es wurde keine Saison gefunden.</span>
                                                    </div>
                                                </th>
                                            )
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                Basispreis für Standardbelegung
                                                <OlapWiki tag={'basispreis-f%C3%BCr-standardbelegung'}/>
                                            </div>
                                        </td>
                                        {
                                            seasonProperties.map((season, seasonIndex) => (
                                                <td
                                                    key={seasonIndex}
                                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                    onMouseLeave={() => setHoveredColumn(null)}
                                                >
                                                    <CategorySeasonEditInput tag={"price"} seasonProperty={season} category={item} onSubmit={onSubmit}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                Mindestaufenthalt
                                                <OlapWiki tag={'mindestaufenthalt'}/>
                                            </div>
                                        </td>
                                        {
                                            seasonProperties.map((season, seasonIndex) => (
                                                <td
                                                    key={seasonIndex}
                                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                    onMouseLeave={() => setHoveredColumn(null)}
                                                >
                                                    <CategorySeasonEditInput tag={"minimumStay"} seasonProperty={season} category={item} onSubmit={onSubmit}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                Buchungslücken füllen ab
                                                <OlapWiki tag={'buchungsl%C3%BCcken-f%C3%BCllen-ab'}/>
                                            </div>
                                        </td>
                                        {
                                            seasonProperties.map((season, seasonIndex) => (
                                                <td
                                                    key={seasonIndex}
                                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                    onMouseLeave={() => setHoveredColumn(null)}
                                                >
                                                    <CategorySeasonEditInput tag={"gapFill"} seasonProperty={season} category={item} onSubmit={onSubmit}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                Karenztage (nach Buchung)
                                                <OlapWiki tag={'karenztage-nach-buchung'}/>
                                            </div>
                                        </td>
                                        {
                                            seasonProperties.map((season, seasonIndex) => (
                                                <td
                                                    key={seasonIndex}
                                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                    onMouseLeave={() => setHoveredColumn(null)}
                                                >
                                                    <CategorySeasonEditInput tag={"daysBlockedAfterBooking"} seasonProperty={season} category={item} onSubmit={onSubmit}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                Anreise von
                                                <OlapWiki tag={'anreise-von'}/>
                                            </div>
                                        </td>
                                        {
                                            seasonProperties.map((season, seasonIndex) => (
                                                <td
                                                    key={seasonIndex}
                                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                    onMouseLeave={() => setHoveredColumn(null)}
                                                >
                                                    <CategorySeasonTimeInput tag={"arrivalTimeFrom"} seasonProperty={season} category={item} onSubmit={onSubmit}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                Anreise bis
                                                <OlapWiki tag={'anreise-bis'}/>
                                            </div>
                                        </td>
                                        {
                                            seasonProperties.map((season, seasonIndex) => (
                                                <td
                                                    key={seasonIndex}
                                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                    onMouseLeave={() => setHoveredColumn(null)}
                                                >
                                                    <CategorySeasonTimeInput tag={"arrivalTimeTo"} seasonProperty={season} category={item} onSubmit={onSubmit}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                Abreise von
                                                <OlapWiki tag={'abreise-von'}/>
                                            </div>
                                        </td>
                                        {
                                            seasonProperties.map((season, seasonIndex) => (
                                                <td
                                                    key={seasonIndex}
                                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                    onMouseLeave={() => setHoveredColumn(null)}
                                                >
                                                    <CategorySeasonTimeInput tag={"departureTimeFrom"} seasonProperty={season} category={item} onSubmit={onSubmit}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                Abreise bis
                                                <OlapWiki tag={'abreise-bis'}/>
                                            </div>
                                        </td>
                                        {
                                            seasonProperties.map((season, seasonIndex) => (
                                                <td
                                                    key={seasonIndex}
                                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                    onMouseLeave={() => setHoveredColumn(null)}
                                                >
                                                    <CategorySeasonTimeInput tag={"departureTimeTo"} seasonProperty={season} category={item} onSubmit={onSubmit}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                Ankunftstage
                                                <OlapWiki tag={'m%C3%B6gliche-anreisewochentage'}/>
                                            </div>
                                        </td>
                                        {
                                            seasonProperties.map((season, seasonIndex) => (
                                                <td
                                                    key={seasonIndex}
                                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                    onMouseLeave={() => setHoveredColumn(null)}
                                                >
                                                    <CategorySeasonDaysCheckboxes tag={"arrivalDays"} seasonProperty={season} category={item} onSubmit={onSubmit}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                Abreisetage
                                                <OlapWiki tag={'m%C3%B6gliche-abreisewochentage'}/>
                                            </div>
                                        </td>
                                        {
                                            seasonProperties.map((season, seasonIndex) => (
                                                <td
                                                    key={seasonIndex}
                                                    onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                    onMouseLeave={() => setHoveredColumn(null)}
                                                >
                                                    <CategorySeasonDaysCheckboxes tag={"departureDays"} seasonProperty={season} category={item} onSubmit={onSubmit}/>
                                                </td>
                                            ))
                                        }
                                    </tr>
                                    {
                                        seasonFormattedServices.map((seasonService, seasonServiceIndex) => (
                                            <tr className={`serviceRow ${selectedOption === "Leistungen" ? "" : "d-none"}`}>
                                                <td className="dtr-control">
                                                    <div className="rowTitle">
                                                        {seasonService.service.service.name[0].text}
                                                    </div>
                                                </td>
                                                {
                                                    seasonProperties.map((season, seasonIndex) => (
                                                        <td
                                                            key={seasonIndex}
                                                            onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                            onMouseLeave={() => setHoveredColumn(null)}
                                                        >
                                                            {seasonService.seasonIds.some(seasonIdObj => seasonIdObj.id === season.season.id) && (
                                                                <CategorySeasonServiceModal
                                                                    serviceItem={seasonService.service}
                                                                    seasonId={season.season.id}
                                                                    categoryId={item.id}
                                                                    price={seasonService.seasonIds.find(seasonIdObj => seasonIdObj.id === season.season.id).price}
                                                                    onSubmit={submitService}
                                                                />
                                                            )}
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        ))
                                    }
                                    {
                                        seasonFormattedServices.length === 0 && (
                                            <tr
                                                className={`serviceRow ${selectedOption === "Leistungen" ? "" : "d-none"}`}>
                                                <td className="dtr-control">
                                                    <div className="rowTitle">
                                                        <div>
                                                            Leistungen
                                                        </div>
                                                    </div>
                                                </td>
                                                {
                                                    seasonProperties.map((season, seasonIndex) => (
                                                        <td
                                                            key={seasonIndex}
                                                            onMouseEnter={() => setHoveredColumn(seasonIndex)}
                                                            onMouseLeave={() => setHoveredColumn(null)}
                                                        >
                                                            Es wurden noch keine Leistungen für diese Saison hinterlegt. Unter "Leistungsvorlage der Organisation" kann gestartet werden.
                                                        </td>
                                                    ))
                                                }
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                {
                    selectedOption === "Leistungen" && (
                        <OlapOrganizationServices services={organizationServices} onSubmit={submitService}/>
                    )
                }

            </div>
        </div>
    );
}
export default CategroySeasonPropertiesTable;
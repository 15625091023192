import React, {useEffect, useState} from 'react';
import PropertiesService from '../../../services/PropertiesService';  // Updated service name
import { useAuth } from '../../../hooks/useAuth';

const ObjectSeasonTimeInput = ({ tag, objectId, category, seasonProperties, onSubmit }) => {
    const { user } = useAuth();
    const { season, ...restFullState } = seasonProperties;
    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState
    });

    useEffect(() => {
        const {season, ...restFullState } = seasonProperties;

        setFullStateEdit({
            ...restFullState
        });
    }, [seasonProperties]);

    const change = (e) => {
        const newValue = e.target.value;
        setFullStateEdit((prevState) => ({
            ...prevState,
            bookingProperties: {
                ...prevState.bookingProperties,
                [tag]: newValue,
            },
        }));
    };


    const saveChanges = async () => {
        try {
            await PropertiesService.updateSeasonObjectProperty(seasonProperties.season.id, objectId, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    return (
        <div className='text-center olap-input d-flex'>
            {fullStateEdit.bookingProperties[tag] !== null ? '' : (
                <span className='me-2' style={{color: '#868993'}}>{category.bookingProperties[tag]}</span>
            )}
            <input
                type='time'
                className='form-control form-control-edit text-center'
                value={fullStateEdit.bookingProperties[tag] ? fullStateEdit.bookingProperties[tag] : ''}
                onChange={change}
                onBlur={saveChanges}
            />

        </div>
    );
};

export default ObjectSeasonTimeInput;
import React, {useEffect, useState} from 'react';
import { RiQuestionLine, RiCloseLine } from 'react-icons/ri';
import {useSearchParams} from "react-router-dom";

const Tutorial = () => {
    const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);

    const openTutorialModal = () => {
        setIsTutorialModalOpen(true);
    };

    const closeTutorialModal = () => {
        setIsTutorialModalOpen(false);
    };

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('first') === 'true') {
            openTutorialModal();
        }
    }, []);

    return (
        <div className="customizer-setting d-none d-md-block" style={{zIndex: "1050"}}>
            <div onClick={openTutorialModal} className="btn-info rounded-pill shadow-lg btn btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                <RiQuestionLine className="mdi mdi-spin mdi-cog-outline fs-22" />
            </div>
            <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isTutorialModalOpen ? 'show' : ''}`}
                 style={{ display: isTutorialModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-body p-5">
                            <div className="mt-4">
                                <h4 className="mb-3">Willkommen in der Objektstruktur-Ansicht!</h4>
                                <p className="text-muted mb-4">Hier können Sie Ihre vermietbaren Objekte erstellen und verwalten, sie in Objektkategorien gruppieren und sie einem Platz, einem Hotel o.ä. zuordnen. Dazu ist die Objektstruktur immer in drei Ebenen aufgeteilt:</p>
                                <p className="text-muted mb-4">Unterste Ebene/Stufe:</p>
                                <ul>
                                    <li>
                                        Hier sind die zu vermietenden Objekte/Einheiten wie z.B.:
                                    </li>
                                    <ul>
                                        <li>
                                            Eine Ferienwohnung
                                        </li>
                                        <li>
                                            oder ein Ferienhaus (wenn es nur als eine Einheit gemietet werden kann).
                                        </li>
                                        <li>
                                            Ein Zimmer in einem Hotel oder
                                        </li>
                                        <li>
                                            ein Apartment in einem Apartmentkomplex
                                        </li>
                                        <li>
                                            Ein Bungalow,
                                        </li>
                                        <li>
                                            ein Tiny House,
                                        </li>
                                        <li>
                                            ein Dauercampingplatz,
                                        </li>
                                        <li>
                                            ein Kurzzeitcampingplatz bzw. ein Stellplatz für ein Zelt,
                                        </li>
                                        <li>
                                            ein Stellplatz für ein Wohnmobil,
                                        </li>
                                        <li>
                                            ein Anlegeplatz für ein Boot,
                                        </li>
                                        <li>
                                            usw.
                                        </li>
                                    </ul>
                                </ul>
                                <p className="text-muted mb-4">Mittlere Ebene/Stufe:</p>
                                <ul>
                                    <li>
                                        Hier sind die Objektkategorien. Sie ermöglichen die eindeutige Gruppierung von Objekten (jedes Objekt kann nur eine Objektkategorie haben).
                                    </li>
                                    <ul>
                                        <li>
                                            Das Ferienhaus, wenn es mehrere Ferienwohnungen enthält.
                                        </li>
                                        <li>
                                            Die Zimmertypen in einem Hotel wie Doppelzimmer, Junior Suite usw.
                                        </li>
                                        <li>
                                            Die verschiedenen Arten von Objekten auf einem Campingplatz wie Dauercampingplätze, Kurzzeitcampingplätze, Wohnmobilstellplätze, Bootsanlegeplätze, Bungalows, usw.
                                        </li>
                                    </ul>
                                </ul>
                                <p className="text-muted mb-4">Oberste Ebene/Stufe:</p>
                                <ul>
                                    <li>Hier ist die rechtliche Einheit bzw. die Adresse.</li>
                                    <ul>
                                        <li>
                                            Alles was eine eigene Adresse hat, insbesondere mit eigener Anfahrtbeschreibung, nennen wir Platz, unabhängig davon, ob es zur selben rechtlichen Einheit gehört. Insbesondere Objekte in verschiedenen Orten sind verschiedene Plätze. Auch verschiedene Hotels der selben Kette sind verschiedene Plätze. Ebenso verschiedene Campingplätze, die zum selben Anbieter gehören sind verschiedene Plätze, auch wenn sie unter der gleichen Adresse zu finden sind. Gibt es eigenständige rechtliche Organisationen unter der selben Adresse aber mit unterschiedlichen zu vermietenden Objekten/Einheiten sind das auch verschiedene Plätze.
                                        </li>
                                    </ul>
                                </ul>
                                <div className="hstack gap-2 justify-content-end">
                                    <span onClick={closeTutorialModal} className="btn btn-link link-success fw-medium keychainify-checked"
                                       data-bs-dismiss="modal"><RiCloseLine className="ri-close-line me-1 align-middle" />Schließen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tutorial;

class MockService {
    static async getCalendarData() {
        return [
            {
                title: 'Januar 2024',
                days: 31,
                startDay: 'Mo',
                data: [
                    { day: 1, season: 'firstSeasonStart', density: 'veryHighDensity' },
                    { day: 2, season: 'firstSeason', density: 'veryHighDensity' },
                    { day: 3, season: 'firstSeason', density: 'veryHighDensity' },
                    { day: 4, season: 'firstSeason', density: 'highDensity' },
                    { day: 5, season: 'firstSeason', density: 'highDensity' },
                    { day: 6, season: 'firstSeason', density: 'lowDensity' },
                    { day: 7, season: 'firstSeason', density: 'lowDensity' },
                    { day: 8, season: 'firstSeason', density: 'lowDensity' },
                    { day: 9, season: 'firstSeason', density: 'lowDensity' },
                    { day: 10, season: 'firstSeason', density: 'lowDensity' },
                    { day: 11, season: 'firstSeason', density: 'lowDensity' },
                    { day: 12, season: 'firstSeason', density: 'lowDensity' },
                    { day: 13, season: 'firstSeasonEnd secondSeasonStart', density: 'lowDensity' },
                    { day: 14, season: 'secondSeason', density: 'veryLowDensity' },
                    { day: 15, season: 'secondSeason', density: 'veryLowDensity' },
                    { day: 16, season: 'secondSeason', density: 'veryLowDensity' },
                    { day: 17, season: 'secondSeason', density: 'veryLowDensity' },
                    { day: 18, season: 'secondSeason', density: 'veryLowDensity' },
                    { day: 19, season: 'secondSeasonEnd thirdSeasonStart', density: 'veryLowDensity' },
                    { day: 20, season: 'thirdSeason', density: 'veryLowDensity' },
                    { day: 21, season: 'thirdSeason', density: 'veryLowDensity' },
                    { day: 22, season: 'thirdSeason', density: 'veryLowDensity' },
                    { day: 23, season: 'thirdSeason', density: 'veryLowDensity' },
                    { day: 24, season: 'thirdSeasonEnd fourthSeasonStart', density: 'veryLowDensity' },
                    { day: 25, season: 'fourthSeason', density: 'veryLowDensity' },
                    { day: 26, season: 'fourthSeason', density: 'veryLowDensity' },
                    { day: 27, season: 'fourthSeason', density: 'veryLowDensity' },
                    { day: 28, season: 'fourthSeason', density: 'veryLowDensity' },
                    { day: 29, season: 'fourthSeason', density: 'veryLowDensity' },
                    { day: 30, season: 'fourthSeason', density: 'veryLowDensity' },
                    { day: 31, season: 'fourthSeasonEnd', density: 'veryLowDensity' },
                ],
            },
            {
                title: 'Februar 2024',
                days: 29,
                startDay: 'Do',
                data: [
                    { day: 1, season: '', density: 'lowDensity' },
                    { day: 2, season: '', density: 'lowDensity' },
                    { day: 3, season: '', density: 'veryLowDensity' },
                    { day: 4, season: '', density: 'veryLowDensity' },
                    { day: 5, season: '', density: 'lowDensity' },
                    { day: 6, season: '', density: 'lowDensity' },
                    { day: 7, season: '', density: 'lowDensity' },
                    { day: 8, season: '', density: 'lowDensity' },
                    { day: 9, season: '', density: 'lowDensity' },
                    { day: 10, season: '', density: 'lowDensity' },
                    { day: 11, season: '', density: 'lowDensity' },
                    { day: 12, season: '', density: 'midDensity' },
                    { day: 13, season: '', density: 'midDensity' },
                    { day: 14, season: '', density: 'midDensity' },
                    { day: 15, season: '', density: 'midDensity' },
                    { day: 16, season: '', density: 'midDensity' },
                    { day: 17, season: '', density: 'lowDensity' },
                    { day: 18, season: '', density: 'lowDensity' },
                    { day: 19, season: '', density: 'lowDensity' },
                    { day: 20, season: '', density: 'lowDensity' },
                    { day: 21, season: '', density: 'lowDensity' },
                    { day: 22, season: '', density: 'lowDensity' },
                    { day: 23, season: '', density: 'lowDensity' },
                    { day: 24, season: '', density: 'veryLowDensity' },
                    { day: 25, season: '', density: 'veryLowDensity' },
                    { day: 26, season: '', density: 'veryLowDensity' },
                    { day: 27, season: '', density: 'veryLowDensity' },
                    { day: 28, season: '', density: 'veryLowDensity' },
                    { day: 29, season: '', density: 'veryLowDensity' },
                ],
            },
            {
                title: 'März 2024',
                days: 31,
                startDay: 'Fr',
                data: [
                    { day: 1, season: '', density: 'veryLowDensity' },
                    { day: 2, season: '', density: 'veryLowDensity' },
                    { day: 3, season: '', density: 'veryLowDensity' },
                    { day: 4, season: '', density: 'veryLowDensity' },
                    { day: 5, season: '', density: 'veryLowDensity' },
                    { day: 6, season: '', density: 'veryLowDensity' },
                    { day: 7, season: '', density: 'veryLowDensity' },
                    { day: 8, season: '', density: 'veryLowDensity' },
                    { day: 9, season: '', density: 'veryLowDensity' },
                    { day: 10, season: '', density: 'veryLowDensity' },
                    { day: 11, season: '', density: 'veryLowDensity' },
                    { day: 12, season: '', density: 'veryLowDensity' },
                    { day: 13, season: '', density: 'veryLowDensity' },
                    { day: 14, season: '', density: 'veryLowDensity' },
                    { day: 15, season: '', density: 'veryLowDensity' },
                    { day: 16, season: '', density: 'veryLowDensity' },
                    { day: 17, season: '', density: 'veryLowDensity' },
                    { day: 18, season: '', density: 'lowDensity' },
                    { day: 19, season: '', density: 'lowDensity' },
                    { day: 20, season: '', density: 'lowDensity' },
                    { day: 21, season: '', density: 'lowDensity' },
                    { day: 22, season: '', density: 'lowDensity' },
                    { day: 23, season: '', density: 'midDensity' },
                    { day: 24, season: '', density: 'midDensity' },
                    { day: 25, season: '', density: 'veryHighDensity' },
                    { day: 26, season: '', density: 'veryHighDensity' },
                    { day: 27, season: '', density: 'veryHighDensity' },
                    { day: 28, season: '', density: 'veryHighDensity' },
                    { day: 29, season: '', density: 'highDensity' },
                    { day: 30, season: '', density: 'highDensity' },
                    { day: 31, season: '', density: 'highDensity' },
                ],
            },
            {
                title: 'April 2024',
                days: 30,
                startDay: 'Mo',
                data: [
                    { day: 1, season: '', density: 'highDensity' },
                    { day: 2, season: '', density: 'highDensity' },
                    { day: 3, season: '', density: 'highDensity' },
                    { day: 4, season: '', density: 'highDensity' },
                    { day: 5, season: '', density: 'highDensity' },
                    { day: 6, season: '', density: 'highDensity' },
                    { day: 7, season: '', density: 'lowDensity' },
                    { day: 8, season: '', density: 'lowDensity' },
                    { day: 9, season: '', density: 'lowDensity' },
                    { day: 10, season: '', density: 'lowDensity' },
                    { day: 11, season: '', density: 'lowDensity' },
                    { day: 12, season: '', density: 'lowDensity' },
                    { day: 13, season: '', density: 'lowDensity' },
                    { day: 14, season: '', density: 'lowDensity' },
                    { day: 15, season: '', density: 'lowDensity' },
                    { day: 16, season: '', density: 'lowDensity' },
                    { day: 17, season: '', density: 'lowDensity' },
                    { day: 18, season: '', density: 'lowDensity' },
                    { day: 19, season: '', density: 'lowDensity' },
                    { day: 20, season: '', density: 'veryLowDensity' },
                    { day: 21, season: '', density: 'veryLowDensity' },
                    { day: 22, season: '', density: 'veryLowDensity' },
                    { day: 23, season: '', density: 'veryLowDensity' },
                    { day: 24, season: '', density: 'veryLowDensity' },
                    { day: 25, season: '', density: 'veryLowDensity' },
                    { day: 26, season: '', density: 'veryLowDensity' },
                    { day: 27, season: '', density: 'veryLowDensity' },
                    { day: 28, season: '', density: 'veryLowDensity' },
                    { day: 29, season: '', density: 'veryLowDensity' },
                    { day: 30, season: '', density: 'veryLowDensity' },
                ],
            },
            {
                title: 'Mai 2024',
                days: 31,
                startDay: 'Mi',
                data: [
                    { day: 1, season: '', density: '' },
                    { day: 2, season: '', density: '' },
                    { day: 3, season: '', density: '' },
                    { day: 4, season: '', density: '' },
                    { day: 5, season: '', density: '' },
                    { day: 6, season: '', density: '' },
                    { day: 7, season: '', density: '' },
                    { day: 8, season: '', density: '' },
                    { day: 9, season: '', density: '' },
                    { day: 10, season: '', density: '' },
                    { day: 11, season: '', density: '' },
                    { day: 12, season: '', density: '' },
                    { day: 13, season: '', density: '' },
                    { day: 14, season: '', density: '' },
                    { day: 15, season: '', density: '' },
                    { day: 16, season: '', density: '' },
                    { day: 17, season: '', density: '' },
                    { day: 18, season: '', density: '' },
                    { day: 19, season: '', density: '' },
                    { day: 20, season: '', density: '' },
                    { day: 21, season: '', density: '' },
                    { day: 22, season: '', density: '' },
                    { day: 23, season: '', density: '' },
                    { day: 24, season: '', density: '' },
                    { day: 25, season: '', density: '' },
                    { day: 26, season: '', density: '' },
                    { day: 27, season: '', density: '' },
                    { day: 28, season: '', density: '' },
                    { day: 29, season: '', density: '' },
                    { day: 30, season: '', density: '' },
                    { day: 31, season: '', density: '' },
                ],
            },
            {
                title: 'Juni 2024',
                days: 30,
                startDay: 'Sa',
                data: [
                    { day: 1, season: '', density: '' },
                    { day: 2, season: '', density: '' },
                    { day: 3, season: '', density: '' },
                    { day: 4, season: '', density: '' },
                    { day: 5, season: '', density: '' },
                    { day: 6, season: '', density: '' },
                    { day: 7, season: '', density: '' },
                    { day: 8, season: '', density: '' },
                    { day: 9, season: '', density: '' },
                    { day: 10, season: '', density: '' },
                    { day: 11, season: '', density: '' },
                    { day: 12, season: '', density: '' },
                    { day: 13, season: '', density: '' },
                    { day: 14, season: '', density: '' },
                    { day: 15, season: '', density: '' },
                    { day: 16, season: '', density: '' },
                    { day: 17, season: '', density: '' },
                    { day: 18, season: '', density: '' },
                    { day: 19, season: '', density: '' },
                    { day: 20, season: '', density: '' },
                    { day: 21, season: '', density: '' },
                    { day: 22, season: '', density: '' },
                    { day: 23, season: '', density: '' },
                    { day: 24, season: '', density: '' },
                    { day: 25, season: '', density: '' },
                    { day: 26, season: '', density: '' },
                    { day: 27, season: '', density: '' },
                    { day: 28, season: '', density: '' },
                    { day: 29, season: '', density: '' },
                    { day: 30, season: '', density: '' },
                ],
            },
            {
                title: 'Juli 2024',
                days: 31,
                startDay: 'Mo',
                data: [
                    { day: 1, season: '', density: '' },
                    { day: 2, season: '', density: '' },
                    { day: 3, season: '', density: '' },
                    { day: 4, season: '', density: '' },
                    { day: 5, season: '', density: '' },
                    { day: 6, season: '', density: '' },
                    { day: 7, season: '', density: '' },
                    { day: 8, season: '', density: '' },
                    { day: 9, season: '', density: '' },
                    { day: 10, season: '', density: '' },
                    { day: 11, season: '', density: '' },
                    { day: 12, season: '', density: '' },
                    { day: 13, season: '', density: '' },
                    { day: 14, season: '', density: '' },
                    { day: 15, season: '', density: '' },
                    { day: 16, season: '', density: '' },
                    { day: 17, season: '', density: '' },
                    { day: 18, season: '', density: '' },
                    { day: 19, season: '', density: '' },
                    { day: 20, season: '', density: '' },
                    { day: 21, season: '', density: '' },
                    { day: 22, season: '', density: '' },
                    { day: 23, season: '', density: '' },
                    { day: 24, season: '', density: '' },
                    { day: 25, season: '', density: '' },
                    { day: 26, season: '', density: '' },
                    { day: 27, season: '', density: '' },
                    { day: 28, season: '', density: '' },
                    { day: 29, season: '', density: '' },
                    { day: 30, season: '', density: '' },
                    { day: 31, season: '', density: '' },
                ],
            },
            {
                title: 'August 2024',
                days: 31,
                startDay: 'Do',
                data: [
                    { day: 1, season: '', density: '' },
                    { day: 2, season: '', density: '' },
                    { day: 3, season: '', density: '' },
                    { day: 4, season: '', density: '' },
                    { day: 5, season: '', density: '' },
                    { day: 6, season: '', density: '' },
                    { day: 7, season: '', density: '' },
                    { day: 8, season: '', density: '' },
                    { day: 9, season: '', density: '' },
                    { day: 10, season: '', density: '' },
                    { day: 11, season: '', density: '' },
                    { day: 12, season: '', density: '' },
                    { day: 13, season: '', density: '' },
                    { day: 14, season: '', density: '' },
                    { day: 15, season: '', density: '' },
                    { day: 16, season: '', density: '' },
                    { day: 17, season: '', density: '' },
                    { day: 18, season: '', density: '' },
                    { day: 19, season: '', density: '' },
                    { day: 20, season: '', density: '' },
                    { day: 21, season: '', density: '' },
                    { day: 22, season: '', density: '' },
                    { day: 23, season: '', density: '' },
                    { day: 24, season: '', density: '' },
                    { day: 25, season: '', density: '' },
                    { day: 26, season: '', density: '' },
                    { day: 27, season: '', density: '' },
                    { day: 28, season: '', density: '' },
                    { day: 29, season: '', density: '' },
                    { day: 30, season: '', density: '' },
                    { day: 31, season: '', density: '' },
                ],
            },
            {
                title: 'September 2024',
                days: 30,
                startDay: 'So',
                data: [
                    { day: 1, season: '', density: '' },
                    { day: 2, season: '', density: '' },
                    { day: 3, season: '', density: '' },
                    { day: 4, season: '', density: '' },
                    { day: 5, season: '', density: '' },
                    { day: 6, season: '', density: '' },
                    { day: 7, season: '', density: '' },
                    { day: 8, season: '', density: '' },
                    { day: 9, season: '', density: '' },
                    { day: 10, season: '', density: '' },
                    { day: 11, season: '', density: '' },
                    { day: 12, season: '', density: '' },
                    { day: 13, season: '', density: '' },
                    { day: 14, season: '', density: '' },
                    { day: 15, season: '', density: '' },
                    { day: 16, season: '', density: '' },
                    { day: 17, season: '', density: '' },
                    { day: 18, season: '', density: '' },
                    { day: 19, season: '', density: '' },
                    { day: 20, season: '', density: '' },
                    { day: 21, season: '', density: '' },
                    { day: 22, season: '', density: '' },
                    { day: 23, season: '', density: '' },
                    { day: 24, season: '', density: '' },
                    { day: 25, season: '', density: '' },
                    { day: 26, season: '', density: '' },
                    { day: 27, season: '', density: '' },
                    { day: 28, season: '', density: '' },
                    { day: 29, season: '', density: '' },
                    { day: 30, season: '', density: '' },
                ],
            },
            {
                title: 'Oktober 2024',
                days: 31,
                startDay: 'Di',
                data: [
                    { day: 1, season: '', density: '' },
                    { day: 2, season: '', density: '' },
                    { day: 3, season: '', density: '' },
                    { day: 4, season: '', density: '' },
                    { day: 5, season: '', density: '' },
                    { day: 6, season: '', density: '' },
                    { day: 7, season: '', density: '' },
                    { day: 8, season: '', density: '' },
                    { day: 9, season: '', density: '' },
                    { day: 10, season: '', density: '' },
                    { day: 11, season: '', density: '' },
                    { day: 12, season: '', density: '' },
                    { day: 13, season: '', density: '' },
                    { day: 14, season: '', density: '' },
                    { day: 15, season: '', density: '' },
                    { day: 16, season: '', density: '' },
                    { day: 17, season: '', density: '' },
                    { day: 18, season: '', density: '' },
                    { day: 19, season: '', density: '' },
                    { day: 20, season: '', density: '' },
                    { day: 21, season: '', density: '' },
                    { day: 22, season: '', density: '' },
                    { day: 23, season: '', density: '' },
                    { day: 24, season: '', density: '' },
                    { day: 25, season: '', density: '' },
                    { day: 26, season: '', density: '' },
                    { day: 27, season: '', density: '' },
                    { day: 28, season: '', density: '' },
                    { day: 29, season: '', density: '' },
                    { day: 30, season: '', density: '' },
                    { day: 31, season: '', density: '' },
                ],
            },
            {
                title: 'November 2024',
                days: 30,
                startDay: 'Fr',
                data: [
                    { day: 1, season: '', density: '' },
                    { day: 2, season: '', density: '' },
                    { day: 3, season: '', density: '' },
                    { day: 4, season: '', density: '' },
                    { day: 5, season: '', density: '' },
                    { day: 6, season: '', density: '' },
                    { day: 7, season: '', density: '' },
                    { day: 8, season: '', density: '' },
                    { day: 9, season: '', density: '' },
                    { day: 10, season: '', density: '' },
                    { day: 11, season: '', density: '' },
                    { day: 12, season: '', density: '' },
                    { day: 13, season: '', density: '' },
                    { day: 14, season: '', density: '' },
                    { day: 15, season: '', density: '' },
                    { day: 16, season: '', density: '' },
                    { day: 17, season: '', density: '' },
                    { day: 18, season: '', density: '' },
                    { day: 19, season: '', density: '' },
                    { day: 20, season: '', density: '' },
                    { day: 21, season: '', density: '' },
                    { day: 22, season: '', density: '' },
                    { day: 23, season: '', density: '' },
                    { day: 24, season: '', density: '' },
                    { day: 25, season: '', density: '' },
                    { day: 26, season: '', density: '' },
                    { day: 27, season: '', density: '' },
                    { day: 28, season: '', density: '' },
                    { day: 29, season: '', density: '' },
                    { day: 30, season: '', density: '' },
                ],
            },
            {
                title: 'Dezember 2024',
                days: 31,
                startDay: 'So',
                data: [
                    { day: 1, season: '', density: '' },
                    { day: 2, season: '', density: '' },
                    { day: 3, season: '', density: '' },
                    { day: 4, season: '', density: '' },
                    { day: 5, season: '', density: '' },
                    { day: 6, season: '', density: '' },
                    { day: 7, season: '', density: '' },
                    { day: 8, season: '', density: '' },
                    { day: 9, season: '', density: '' },
                    { day: 10, season: '', density: '' },
                    { day: 11, season: '', density: '' },
                    { day: 12, season: '', density: '' },
                    { day: 13, season: '', density: '' },
                    { day: 14, season: '', density: '' },
                    { day: 15, season: '', density: '' },
                    { day: 16, season: '', density: '' },
                    { day: 17, season: '', density: '' },
                    { day: 18, season: '', density: '' },
                    { day: 19, season: '', density: '' },
                    { day: 20, season: '', density: '' },
                    { day: 21, season: '', density: '' },
                    { day: 22, season: '', density: '' },
                    { day: 23, season: '', density: '' },
                    { day: 24, season: '', density: '' },
                    { day: 25, season: '', density: '' },
                    { day: 26, season: '', density: '' },
                    { day: 27, season: '', density: '' },
                    { day: 28, season: '', density: '' },
                    { day: 29, season: '', density: '' },
                    { day: 30, season: '', density: '' },
                    { day: 31, season: '', density: '' },
                ],
            },
        ]
    }
}

export default MockService;

import React, {Fragment, useState} from "react";
import {RiCloseLine} from "react-icons/ri";

const ObjectsAndSeasonsDnDDeleteModal = ({parentName, item, submitDelete}) => {
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const openDeleteModal = () => {
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
    };

    const submit = async (e) => {
        await submitDelete(item.season.id);
        setIsDeleteModalOpen(false);
    }

    return(
        <Fragment>
            <span
                className='text-danger btn-icon btn-sm'
                type='button'
                onClick={openDeleteModal}
            >
                <RiCloseLine className='ri-more-fill align-bottom' />
            </span>
            <div
                className={`modal fade ${isDeleteModalOpen ? 'show' : ''}`}
                style={{ display: isDeleteModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                aria-hidden={!isDeleteModalOpen}
            >
                <div className='modal-dialog'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>"{parentName}" und "{item.season.name}" trennen</h5>
                            <button
                                type='button'
                                className='btn-close'
                                aria-label='Close'
                                onClick={closeDeleteModal}
                            />
                        </div>
                        <div className='modal-body'>
                            <div>
                                Wollen Sie wirklich "{parentName}" und "{item.season.name}" trennen? Sie löschen damit auch alle eingetragenen Daten zu dieser Abhängigkeit.
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-light' onClick={closeDeleteModal}>
                                Abbrechen
                            </button>
                            <button type='submit' className='btn btn-danger' onClick={submit}>
                                Löschen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default ObjectsAndSeasonsDnDDeleteModal;
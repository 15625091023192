import React, {useEffect, useState} from 'react';
import PropertiesService from '../../../services/PropertiesService';  // Updated service name
import { useAuth } from '../../../hooks/useAuth';

const CategroySeasonEditInput = ({ tag, seasonProperty, category, onSubmit }) => {
    const { user } = useAuth();

    const { season, ...restFullState } = seasonProperty;
    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState,
    });

    useEffect(() => {
        const {season, ...restFullState } = seasonProperty;

        setFullStateEdit({
            ...restFullState
        });

    }, [seasonProperty]);

    const change = (e) => {
        let newValue = null; // Standardmäßig auf null setzen
        let value = e.target.value;

        if (tag === "price") {
            value = value.replace(/\s/g, '');

            if (value.split('.').length > 2 || value.split(',').length > 2) {
                return;
            }

            if (value.split('.')[1] && value.split('.')[1].length > 2 || value.split(',')[1] && value.split(',')[1].length > 2) {
                return;
            }
            const parsedFloatValue = parseFloat(value);
            newValue = isNaN(parsedFloatValue) ? null : parsedFloatValue;
        } else {
            value = value.replace(/\s/g, '');
            const parsedIntValue = parseInt(value, 10);
            newValue = isNaN(parsedIntValue) ? null : parsedIntValue;
        }

        setFullStateEdit((prevState) => ({
            ...prevState,
            bookingProperties: {
                ...prevState.bookingProperties,
                [tag]: newValue,
            },
        }));
    };

    const saveChanges = async () => {
        try {
            await PropertiesService.updateSeasonCategoryProperty(seasonProperty.season.id, category.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    return (
        <div className='text-center olap-input'>
            <input
                placeholder={category.bookingProperties[tag]}
                type='number'
                className='form-control form-control-edit text-center'
                value={fullStateEdit.bookingProperties[tag] ? fullStateEdit.bookingProperties[tag] : ''}
                onChange={change}
                onBlur={saveChanges}
                onKeyDown={(e) => {
                    if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-') {
                        e.preventDefault();
                    }
                    if (tag !== 'price' && e.key === '.') {
                        e.preventDefault();
                    }
                    if (tag !== 'price' && e.key === ',') {
                        e.preventDefault();
                    }
                }}
            />
        </div>
    );
};

export default CategroySeasonEditInput;
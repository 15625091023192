import React, {useEffect, useRef, useState} from 'react';
import {RiCloseLine, RiHazeLine, RiMore2Fill} from "react-icons/ri";
import moment from "moment";
import {useAuth} from "../../hooks/useAuth";
import SeasonService from "../../services/SeasonService";
import $ from "jquery";
import {isEmpty} from "../../utils/validation";

const SaisonCard = ({calendarId, saisonItem, onSubmit, calendar, saisons}) => {
    const [isSeasonModalOpen, setIsSeasonModalOpen] = useState(false);
    const [saison, setSaison] = useState({
        name: "Season 1",
        dateRanges: [],
        ...saisonItem
    });
    const {user} = useAuth();
    const [errors, setErrors] = useState({});

    const handleDateRangeAdd = () => {
        setSaison((prevState) => ({
            ...prevState,
            dateRanges: [
                ...prevState.dateRanges,
                {
                    dateFrom: '',
                    dateTo: ''
                }
            ],
        }));
    };

    const handleDateRangeRemove = (e) => {
        let index = parseInt((e.target.dataset && e.target.dataset.index && e.target.dataset.index) || (e.target.parentElement && e.target.parentElement.dataset && e.target.parentElement.dataset.index));
        let idx = -1;
        for (let d=0; d < saison.dateRanges.length; d++) {
            if (!saison.dateRanges[d].hasOwnProperty('id')) {
                if (index === 0) {
                    idx = d;
                    break;
                }
                index--;
            }
        }
        if (idx > -1) {
            setSaison((prevState) => {
                const newDateRanges = [
                    ...prevState.dateRanges
                ];
                newDateRanges.splice(idx, 1);
                return {
                    ...prevState,
                    dateRanges: newDateRanges
                };
            });
        }
    };

    const dropdownRef = useRef();
    const dropdownContentRef = useRef();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const click = () => {
        if (dropdownRef.current.classList.contains('show')) {
            dropdownRef.current.classList.remove('show');
            $(dropdownContentRef.current).slideUp();
        } else {
            dropdownRef.current.classList.add('show');
            $(dropdownContentRef.current).slideDown();
        }
    };

    const openSeasonModal = () => {
        setIsSeasonModalOpen(true);
    };

    const closeSeasonModal = () => {
        setIsSeasonModalOpen(false);
        onSubmit();
    };

    const openDeleteModal = () => {
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        click();
        onSubmit();
    };

    const submitDelete = async (e) => {
        e.preventDefault();
        try {
            setIsDeleteModalOpen(false);
            click();
            await SeasonService.deleteSeason(saison.id, user);
            onSubmit();
        } catch (err) {
            setErrors(err.response.data.errors);
        }
    };

    const validate = () => {
        let hasErrors = false;
        let errors = {};
        let year = calendar.year.toString();
        let dateRanges = saison.dateRanges;
        let dateRangesLength = dateRanges.length;

        if (isEmpty(saison.name)) {
            errors.name = 'Es muss ein Saisonname angegeben werden.';
            hasErrors = true;
        }

        if (saison.dateRanges.length === 0) {
            errors.dateRanges = 'Es muss mindestens ein Zeitraum ausgewählt werden.';
            hasErrors = true;
        }

        //check if date range year is calendar.year
        for (let i = 0; i < dateRangesLength; i++) {
            let dateFrom = dateRanges[i].dateFrom;
            let dateTo = dateRanges[i].dateTo;
            if (dateFrom && dateTo) {
                let dateFromYear = dateFrom.split('-')[0];
                let dateToYear = dateTo.split('-')[0];
                if (dateFromYear !== year || dateToYear !== year) {
                    errors.dateRangeYear = 'Es muss das gleiche Jahr wie der Kalender sein.';
                    hasErrors = true;
                }
            }
        }

        //check if date ranges overlap
        for (let i = 0; i < dateRangesLength; i++) {
            let dateFrom = dateRanges[i].dateFrom;
            let dateTo = dateRanges[i].dateTo;
            if (dateFrom && dateTo) {
                for (let j = i + 1; j < dateRangesLength; j++) {
                    let dateFrom2 = dateRanges[j].dateFrom;
                    let dateTo2 = dateRanges[j].dateTo;
                    if (dateFrom2 && dateTo2) {
                        if ((dateFrom <= dateFrom2 && dateFrom2 <= dateTo) || (dateFrom <= dateTo2 && dateTo2 <= dateTo) || (dateFrom2 <= dateFrom && dateFrom <= dateTo2) || (dateFrom2 <= dateTo && dateTo <= dateTo2)) {
                            errors.dateRange = 'Es gibt überlappende Zeiträume.';
                            hasErrors = true;
                        }
                    }
                }
            }
        }

        //check if date ranges overlap with existing seasons except current season
        for (let i = 0; i < saisons.length; i++) {
            let saisonsDateRanges = saisons[i].dateRanges;
            let saisonsDateRangesLength = saisonsDateRanges.length;
            if (saisons[i].id !== saison.id) {
                for (let j = 0; j < dateRangesLength; j++) {
                    let dateFrom = dateRanges[j].dateFrom;
                    let dateTo = dateRanges[j].dateTo;
                    if (dateFrom && dateTo) {
                        for (let k = 0; k < saisonsDateRangesLength; k++) {
                            let dateFrom2 = saisonsDateRanges[k].dateFrom;
                            let dateTo2 = saisonsDateRanges[k].dateTo;
                            if (dateFrom2 && dateTo2) {
                                if ((dateFrom <= dateFrom2 && dateFrom2 <= dateTo) || (dateFrom <= dateTo2 && dateTo2 <= dateTo) || (dateFrom2 <= dateFrom && dateFrom <= dateTo2) || (dateFrom2 <= dateTo && dateTo <= dateTo2)) {
                                    errors.dateRangeOtherSaison = 'Es gibt überlappende Zeiträume mit einer anderen Saison.';
                                    hasErrors = true;
                                }
                            }
                        }
                    }
                }
            }
        }

        //check that in date range dateFrom is before dateTo
        for (let i = 0; i < dateRangesLength; i++) {
            let dateFrom = dateRanges[i].dateFrom;
            let dateTo = dateRanges[i].dateTo;
            if (dateFrom && dateTo) {
                if (dateFrom >= dateTo) {
                    errors.dateRangeStart = 'Das Startdatum muss vor dem Enddatum liegen.';
                    hasErrors = true;
                }
            }
        }

        setErrors(errors);

        return !hasErrors;
    };

    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                await SeasonService.putSeasons(saison.id, user, saison);
                setIsSeasonModalOpen(false);
                onSubmit();
            } catch (err) {
                setErrors(err.response.data.errors);
            }
        }
        return false;
    };

    useEffect(() => {
        const errorTimer = setTimeout(() => {
            setErrors('');
        }, 4000);

        return () => {
            clearTimeout(errorTimer);
        };
    }, [errors]);

    return(
        <div className='col-xxl-3 col-6 folder-card'>
            <div className='card bg-light shadow-none'>
                <div className="dropdown-container-absolute">
                    <div className='d-flex mb-1'>
                        <div className='dropdown'>
                            <button
                                aria-expanded='false'
                                className='btn btn-ghost-primary btn-icon btn-sm dropdown'
                                data-bs-toggle='dropdown'
                                onClick={click}
                                ref={dropdownRef}
                                type='button'
                            >
                                <RiMore2Fill className='fs-16' />
                            </button>
                            <ul ref={dropdownContentRef} className='dropdown-menu dropdown-menu-end' style={{zIndex: "1054"}}>
                                <li>
                                    <div>
                                        <span className='dropdown-item' data-bs-toggle='modal' role='button' onClick={openDeleteModal}>
                                            Löschen
                                        </span>
                                        <div
                                            aria-hidden={!isDeleteModalOpen}
                                            className={`modal fade ${isDeleteModalOpen ? 'show' : ''}`}
                                            style={{ display: isDeleteModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                                        >
                                            <div className='modal-dialog'>
                                                <div className='modal-content'>
                                                    <div className='modal-header'>
                                                        <h5 className='modal-title'>Saison "{saison.name}" löschen</h5>
                                                        <button
                                                            aria-label='Close'
                                                            className='btn-close'
                                                            onClick={closeDeleteModal}
                                                            type='button'
                                                        />
                                                    </div>
                                                    <div className='modal-body'>
                                                        <div>
                                                            Wollen Sie wirklich Saison "{saison.name}" löschen?<br/>
                                                            <br/>
                                                            Alle zugeordneten Preise und Eigenschaften gehen verloren. Die Saison und die Zuordnungen können nach dem Löschen nicht wieder hergestellt
                                                            werden.
                                                        </div>
                                                    </div>
                                                    <div className='modal-footer'>
                                                        <button type='button' className='btn btn-light' onClick={closeDeleteModal}>
                                                            Abbrechen
                                                        </button>
                                                        <button type='submit' className='btn btn-danger' onClick={submitDelete}>
                                                            Löschen
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='card-body'  onClick={openSeasonModal}>
                    <div className='text-center'>
                        <div className='mb-2 mt-2'>
                            <RiHazeLine className='text-success display-5' />
                        </div>
                        <h6 className='fs-15 folder-name'>
                            {saison.name}
                        </h6>
                    </div>
                    <div className='mt-2 text-muted text-center'>
                        {saison.dateRanges.map((dateRange, index) => (
                            <div className="row" key={index}>
                                <div className="col-xl-12">
                                    {moment(dateRange.dateFrom).format('DD.MM.YYYY')} - {moment(dateRange.dateTo).format('DD.MM.YYYY')}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div
                aria-hidden={!isSeasonModalOpen}
                className={`modal fade ${isSeasonModalOpen ? 'show' : ''}`}
                style={{ display: isSeasonModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
            >
                <div className='modal-dialog modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='myModalLabel'>
                                Saison "{saison.name}" bearbeiten
                            </h5>
                            <button
                                aria-label='Close'
                                className='btn-close'
                                onClick={closeSeasonModal}
                                type='button'
                            />
                        </div>
                        <div className='modal-body'>
                            <div className='row mb-2'>
                                <div className="text-muted fw-medium mb-2 required">Saisonname</div>
                                <div>
                                    <input
                                        className='form-control'
                                        name='seasonname'
                                        onChange={(e) => {
                                            setSaison((prevState) => ({...prevState, name: e.target.value}));
                                        }}
                                        required={true}
                                        type='text'
                                        value={saison.name}
                                    />
                                </div>
                                <div className='row ps-3 pt-1'>
                                    <div
                                        className="col-xl-12 text-danger">{errors.hasOwnProperty('name') && (
                                        <div className="text-danger">
                                            Die Saison muss einen Namen haben.
                                        </div>
                                    )}</div>
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className="text-muted fw-medium mb-2">
                                    Welche Zeiträume sind in der Saison?
                                    <span className='btn btn-success m-lg-2' onClick={handleDateRangeAdd}>+</span>
                                </div>
                                {saison.dateRanges.length === 0 ? (
                                    <div className='row ps-4'>
                                        Noch keine Zeiträume ausgewählt
                                    </div>
                                ) : (
                                    saison.dateRanges.map((dateRange, index) => (
                                        <div key={index} className='row ps-4'>
                                            <div className='row  mb-2'>
                                                <div className='col-xl-12 text-muted fw-medium'>
                                                    {dateRange.name || `Zeitraum ${index + 1}`}
                                                </div>
                                                <div className='col-xl-5'>
                                                    <input
                                                        className='form-control'
                                                        onChange={(e) => {
                                                            // change dateFrom
                                                            const newDateFrom = e.target.value;
                                                            setSaison((prevState) => {
                                                                const newDateRanges = [...prevState.dateRanges];
                                                                newDateRanges[index].dateFrom = newDateFrom;
                                                                return {...prevState, dateRanges: newDateRanges};
                                                            });
                                                        }}
                                                        type='date'
                                                        value={moment(dateRange.dateFrom).format('YYYY-MM-DD')}
                                                    />
                                                </div>
                                                <div className='col-xl-5'>
                                                    <input
                                                        className='form-control'
                                                        onChange={(e) => {
                                                            // change dateTo
                                                            const newDateTo = e.target.value;
                                                            setSaison((prevState) => {
                                                                const newDateRanges = [...prevState.dateRanges];
                                                                newDateRanges[index].dateTo = newDateTo;
                                                                return {...prevState, dateRanges: newDateRanges};
                                                            });
                                                        }}
                                                        type='date'
                                                        value={moment(dateRange.dateTo).format('YYYY-MM-DD')}
                                                    />
                                                </div>
                                                <div className='col-xl-2'>
                                                    <span
                                                        className='text-danger btn-icon btn-sm'
                                                        data-index={index}
                                                        type='button'
                                                        onClick={handleDateRangeRemove}
                                                    >
                                                        <RiCloseLine className='ri-more-fill align-bottom'/>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                )}
                            </div>
                            <div className='row ps-4'>
                                <div
                                    className="col-xl-12 text-danger">{errors.hasOwnProperty('dateRanges') && saison.dateRanges.length === 0 && (
                                    <div className="text-danger">Es muss mindestens ein Zeitraum ausgewählt
                                        werden.</div>
                                )}</div>
                                <div
                                    className="col-xl-12 text-danger">{errors.hasOwnProperty('dateRangeYear') && (
                                    <div className="text-danger">
                                        Zeiträume müssen im gleichen Jahr wie der Kalender sein.
                                    </div>
                                )}</div>
                                <div className="col-xl-12 text-danger">{errors.dateRange && (
                                    <div className="text-danger">
                                        Es gibt überlappende Zeiträume.
                                    </div>
                                )}</div>
                                <div className="col-xl-12 text-danger">{errors.dateRangeOtherSaison && (
                                    <div className="text-danger">
                                        Es gibt überlappende Zeiträume mit einer anderen Saison.
                                    </div>
                                )}</div>
                                <div className="col-xl-12 text-danger">{errors.dateRangeStart && (
                                    <div className="text-danger">
                                        Das Startdatum muss vor dem Enddatum liegen.
                                    </div>
                                )}</div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-light' onClick={closeSeasonModal}>
                                Abbrechen
                            </button>
                            <button type='submit' className='btn btn-success' onClick={submit}>
                            Speichern
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`toastify ${errors.SQL ? 'on' : ''} bg-danger toastify-center toastify-top`} aria-live="polite" style={{transform: "translate(0px, 0px)", top: "15px"}}>Die Saison: {saison.name} hat noch zugeordnete Objekte oder Kategorien. Diese müssen vorher entfernt werden.</div>
        </div>
    );
};

export default SaisonCard;
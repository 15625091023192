import React, {useEffect, useState} from "react";
import Loader from "../../components/Loader";
import SeasonService from "../../services/SeasonService";
import {useOrganization} from "../../hooks/useOrganization";
import {useAuth} from "../../hooks/useAuth";
import PlaceService from "../../services/PlaceService";
import {useLocation} from "react-router-dom";
import ObjectsAndSeasonsDnDPlaces from "./ObjectsAndSeasonsDnDPlaces";
import {DndProvider} from "react-dnd";
import{HTML5Backend} from "react-dnd-html5-backend";
import ObjectsAndSeasonsDraggableCalendar from "./ObjectsAndSeasonsDraggableCalendar";
import SimpleBar from "simplebar-react";

const ObjectsAndSeasonsDndTest = () => {
    const [loading, setLoading] = useState(true);
    const { organization } = useOrganization();
    const { user } = useAuth();
    const [calendars, setCalendars] = useState([]);
    const [places, setPlaces] = useState([]);
    let location = useLocation();



    const fetchCalendars = async () => {
        try {
            const response = await SeasonService.getCalendars(organization.id, user);
            setCalendars(response.data);
        } catch (err) {
            // TODO: Fehlerbehandlung
            throw err;
        }
    };

    const fetchPlaces = async () => {
        const response = await PlaceService.getPlaces(organization.id, user);
        const filteredPlaces = response.data.filter((item) => {
            if (!item.channels || item.channels.length === 0) {
                return true;
            }
            return !item.channels[0].channelDataMap.deleted;
        });
        setPlaces(filteredPlaces);
        setLoading(false);
    };

    useEffect(() => {
        fetchCalendars();
        fetchPlaces();
    }, [location]);


    return (
        <DndProvider backend={HTML5Backend}>
            <div className="row">
                <div className="col-md-3">
                    <div className="card position-sticky-90">
                        <div className="card-header">
                            <h4 className="card-title">Die Saisonkalendar</h4>
                        </div>
                        <div className="card-body file-manager-menu">
                            <SimpleBar style={{maxHeight: 'calc(100vh - 400px)'}}>
                                <div className={'list-unstyled file-manager-menu'} style={{maxHeight: 'calc(100vh - 400px)'}}>
                                    {calendars.length === 0 && (
                                        <div className="alert alert-warning" role="alert">
                                            Es gibt noch keine <strong>Saisonkalender</strong> in Ihrer Organisation.
                                        </div>
                                    )}
                                    {calendars.map((item, index) => (
                                        <ObjectsAndSeasonsDraggableCalendar key={index} item={item}/>
                                    ))}
                                </div>
                            </SimpleBar>
                        </div>
                    </div>

                </div>
                <div className="col-md-9">
                    <div className="card">
                        <div className="card-header">
                            <h4 className="card-title">Die Objekte</h4>
                            <div className="alert alert-warning d-none" role="alert">
                                Es gibt noch <strong>Objekte</strong> die
                                keinen <strong>Saisonkalender</strong> zugeordnet haben.
                            </div>
                        </div>
                        <div className="card-body">
                            {loading ? (
                                <Loader card={false}/>
                            ) : (
                                <div className="row">
                                    {places.map((item, index) => (
                                        <ObjectsAndSeasonsDnDPlaces key={index} item={item} />
                                    ))}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </DndProvider>
    );
}

export default ObjectsAndSeasonsDndTest;
import axios from 'axios';
import config from '../config.json';

class PriceAdjustmentService {
    static async postPriceAdjustment(organizationId, user, state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/organizations/${organizationId}/price/adjustments`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async getPriceAdjustment(organizationId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/organizations/${organizationId}/price/adjustments`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putPriceAdjustment(organizationId, priceAdjustmentId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/organizations/${organizationId}/price/adjustments/${priceAdjustmentId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deletePriceAdjustment(organizationId, priceAdjustmentId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/organizations/${organizationId}/price/adjustments/${priceAdjustmentId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

}

export default PriceAdjustmentService;

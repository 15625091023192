import React, {useEffect, useState} from 'react';
import PropertiesService from '../../../services/PropertiesService';  // Updated service name
import { useAuth } from '../../../hooks/useAuth';

const CategorySeasonTimeInput = ({ tag, seasonProperty, category, onSubmit }) => {
    const { user } = useAuth();

    const { season, ...restFullState } = seasonProperty;
    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState,
    });

    useEffect(() => {
        const {season, ...restFullState } = seasonProperty;

        setFullStateEdit({
            ...restFullState
        });

    }, [seasonProperty]);

    const change = (e) => {
        const newValue = e.target.value;
        setFullStateEdit(prevState => ({
            ...prevState,
            bookingProperties: {
                ...prevState.bookingProperties,
                [tag]: newValue,
            },
        }));
    };


    const saveChanges = async () => {
        try {
            await PropertiesService.updateSeasonCategoryProperty(seasonProperty.season.id, category.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    return (
        <div className='text-center olap-input d-flex'>
            {fullStateEdit.bookingProperties[tag] !== null ? '' : (
                <span className='me-2' style={{color: '#868993'}}>{category.bookingProperties[tag]}</span>
            )}
            <input
                type='time'
                className='form-control form-control-edit text-center'
                value={fullStateEdit.bookingProperties[tag] ? fullStateEdit.bookingProperties[tag] : ''}
                onChange={change}
                onBlur={saveChanges}
            />
        </div>
    );
};

export default CategorySeasonTimeInput;
import React from "react";

const SaisonCalendar = ({title, days, monthData, startDay}) => {
    const weekDays = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"];

    const renderCalendarRows = () => {
        const startDayIndex = weekDays.indexOf(startDay);
        const calendarRows = [];
        let currentWeek = [];
        let dayCounter = 1;

        for (let i = 0; i < startDayIndex; i++) {
            // Platzhalter für Tage vor dem Monatsbeginn
            currentWeek.push(<td key={`placeholder-${i}`} />);
        }

        for (const dayData of monthData) {
            const day = dayData.day;
            const density = dayData.density;
            const season = dayData.season;

            // Create a unique class name for the day based on season and density
            const dayClassName = `fc-day fc-daygrid-day ${density} ${season}`;

            currentWeek.push(
                <td key={`day-${day}`} className={dayClassName}>
                    <div className="fc-daygrid-day-frame fc-scrollgrid-sync-inner">
                        <div className="fc-daygrid-day-top">
                            <span className="fc-daygrid-day-number">{day}</span>
                        </div>
                    </div>
                </td>
            );

            if (currentWeek.length === 7) {
                // Füge die aktuelle Woche zur Tabelle hinzu und starte eine neue Woche
                calendarRows.push(
                    <tr role="row" key={`row-${calendarRows.length}`}>
                        {currentWeek}
                    </tr>
                );
                currentWeek = [];
            }
        }

        // Füge Platzhalter für Tage nach dem Monatsende hinzu
        while (currentWeek.length < 7) {
            currentWeek.push(<td key={`placeholder-after-${currentWeek.length}`} />);
        }

        if (currentWeek.length > 0) {
            // Füge die letzte Woche zur Tabelle hinzu
            calendarRows.push(
                <tr role="row" key={`row-${calendarRows.length}`}>
                    {currentWeek}
                </tr>
            );
        }

        return calendarRows;
    };

    return (
        <div className="col-xl-2">
            <div className="card">
                <div className='card-body saison-calendar'>
                    <div className="fc fc-media-screen fc-direction-ltr fc-theme-standard">
                        <div className="fc-header-toolbar fc-toolbar fc-toolbar-ltr">
                            <div className="fc-toolbar-chunk">
                                <h2 className="fc-toolbar-title">{title}</h2>
                            </div>
                        </div>
                        <div className="fc-view-harness fc-view-harness-active">
                            <div className="fc-dayGridMonth-view fc-view fc-daygrid">
                                <table role="presentation" className="fc-col-header ">
                                    <colgroup></colgroup>
                                    <thead>
                                    <tr>
                                        <th className="fc-col-header-cell fc-day fc-day-sun">
                                            <div className="fc-scrollgrid-sync-inner">
                                                <span className="fc-col-header-cell-cushion">Mo</span>
                                            </div>
                                        </th>
                                        <th className="fc-col-header-cell fc-day fc-day-mon">
                                            <div className="fc-scrollgrid-sync-inner">
                                                <span className="fc-col-header-cell-cushion">Di</span>
                                            </div>
                                        </th>
                                        <th className="fc-col-header-cell fc-day fc-day-tue">
                                            <div className="fc-scrollgrid-sync-inner">
                                                <span className="fc-col-header-cell-cushion">Mi</span>
                                            </div>
                                        </th>
                                        <th className="fc-col-header-cell fc-day fc-day-wed">
                                            <div className="fc-scrollgrid-sync-inner">
                                                <span className="fc-col-header-cell-cushion">Do</span>
                                            </div>
                                        </th>
                                        <th className="fc-col-header-cell fc-day fc-day-thu">
                                            <div className="fc-scrollgrid-sync-inner">
                                                <span className="fc-col-header-cell-cushion">Fr</span>
                                            </div>
                                        </th>
                                        <th className="fc-col-header-cell fc-day fc-day-fri">
                                            <div className="fc-scrollgrid-sync-inner">
                                                <span className="fc-col-header-cell-cushion">Sa</span>
                                            </div>
                                        </th>
                                        <th className="fc-col-header-cell fc-day fc-day-sat">
                                            <div className="fc-scrollgrid-sync-inner">
                                                <span className="fc-col-header-cell-cushion">So</span>
                                            </div>
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {renderCalendarRows()}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SaisonCalendar;

import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { isEmpty } from '../utils/validation';
import LoginFooter from "../parts/LoginFooter";

const LoginPage = () => {
    const { login } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState({
        email: '',
        password: '',
    });
    const [errors, setErrors] = useState({
        email: false,
        password: false,
    });
    const [invalid, setInvalid] = useState(false);
    const passwordChanged = location.state?.passwordChanged || false;
    const passwordReseted = location.state?.passwordReseted || false;
    const signUpRequest = location.state?.signUpRequest || false;

    const validate = () => {
        let hasErrors = false;
        let errors = {};
        for (const key in state) {
            if (isEmpty(state[key])) {
                errors[key] = `${key} is required`;
                hasErrors = true;
            }
        }

        if (hasErrors) {
            setErrors(errors);
        }
        return !hasErrors;
    };

    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                await login(state);
                navigate('/organisationen?autoselect=true', { replace: true });
            } catch (err) {
                setInvalid(true);
            }
        }
    };

    const change = (e) => {
        if (isEmpty(e.target.value)) {
            setErrors({ ...errors, [e.target.name]: `${e.target.name} is required` });
        } else {
            setErrors({ ...errors, [e.target.name]: false });
        }
        setState({ ...state, [e.target.name]: e.target.value });
    };

    return (
        <div className='auth-page-wrapper pt-5'>
            <div className='auth-one-bg-position auth-one-bg' id='auth-particles'>
                <div className='bg-overlay'></div>

                <div className='shape'>
                    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1440 120'>
                        <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z'></path>
                    </svg>
                </div>
            </div>
            <div className='auth-page-content'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8 col-lg-6 col-xl-6'>
                            <div className='card mt-4'>
                                <div className='card-body p-4'>
                                    <div className='text-center mt-2'>
                                        <h5 className='text-primary'>Willkommen zurück!</h5>
                                        <p className='text-primary'>Das Property Management System zur Verwaltung der Online Buchbarkeit.</p>
                                        <p className='text-muted'>Jetzt bei Camppartner PMS einloggen.</p>
                                    </div>
                                    {invalid && <span className='text-danger'>Invalid Credentials!</span>}
                                    {passwordChanged && (
                                        <div className='alert alert-success mt-3'>
                                            Passwort erfolgreich geändert! Bitte loggen Sie sich mit Ihrem neuen Passwort ein.
                                        </div>
                                    )}
                                    {passwordReseted && (
                                        <div className='alert alert-success mt-3'>
                                            Sie wollen ihr Passwort ändern? Bitte schauen Sie in Ihren E-Mails nach.
                                        </div>
                                    )}
                                    {signUpRequest && (
                                        <div className='alert alert-success mt-3'>
                                            Danke, dass Sie sich registrieren wollen. Bitte schauen Sie in Ihren E-Mails nach.
                                        </div>
                                    )}
                                    <div className='p-2 mt-4'>
                                        <form onSubmit={submit}>
                                            <div className='mb-3'>
                                                <label htmlFor='email' className='form-label'>
                                                    E-Mail
                                                </label>
                                                <input
                                                    type='email'
                                                    className={`form-control ${errors.email && 'is-invalid'}`}
                                                    name='email'
                                                    placeholder='E-Mail eingeben'
                                                    onChange={change}
                                                    value={state.email}
                                                />
                                                <div className='invalid-feedback'>{errors.email}</div>
                                            </div>

                                            <div className='mb-3'>
                                                <div className='float-end'>
                                                    <Link to='/forgot-password' className='text-muted'>
                                                        Passwort vergessen?
                                                    </Link>
                                                </div>
                                                <label className='form-label' htmlFor='password'>
                                                    Passwort
                                                </label>
                                                <div className='position-relative auth-pass-inputgroup mb-3'>
                                                    <input
                                                        type='password'
                                                        className={`form-control pe-5 password-input ${
                                                            errors.password && 'is-invalid'
                                                        }`}
                                                        placeholder='Passwort eingeben'
                                                        name='password'
                                                        onChange={change}
                                                        value={state.password}
                                                    />
                                                    <div className='invalid-feedback'>{errors.password}</div>
                                                </div>
                                            </div>

                                            <div className='form-check'>
                                                <input
                                                    className='form-check-input'
                                                    type='checkbox'
                                                    value=''
                                                    id='auth-remember-check'
                                                />
                                                <label className='form-check-label' htmlFor='auth-remember-check'>
                                                    Passwort speichern
                                                </label>
                                            </div>

                                            <div className='mt-4'>
                                                <button className='btn btn-success w-100' type='submit'>
                                                    Einloggen
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4 text-center'>
                                <p className='mb-0'>
                                    Du hast noch keinen Account?
                                    <Link
                                        to='/signup'
                                        className='ms-1 fw-semibold text-primary text-decoration-underline'
                                    >
                                        Registriere dich kostenfrei.
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoginFooter />
        </div>
    );
};

export default LoginPage;

import React, {useEffect, useRef, useState} from 'react';
import { RiMore2Fill } from 'react-icons/ri';
import {GrObjectGroup} from 'react-icons/gr';
import { Link, useParams } from 'react-router-dom';
import $ from "jquery";
import CategoryService from "../../../services/CategoryService";
import {useAuth} from "../../../hooks/useAuth";

const CategoryCard = ({ item, onSubmit }) => {
    const { placeId, categoryId } = useParams();
    const {user} = useAuth();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [errors, setErrors] = useState('');
    const dropdownRef = useRef();
    const dropdownContentRef = useRef();

    const [isEditing, setIsEditing] = useState(false);
    const [state, setState] = useState(
        item
    );

    useEffect(() => {
        setState(item)
    }, [item]);

    const change = (e) => {
        const newName = e.target.value;
        setState((prevState) => ({ ...prevState, name: newName }));
    };

    const activateEditing = () => {
        if(parseInt(categoryId) === item.id){
            setIsEditing(true);
        }
    };

    const saveChanges = async () => {
        try {
            setIsEditing(false);
            await CategoryService.putCategory(item.id, user, state);
        } catch (err) {
            //TODO
            throw err;
        }
    };


    const click = () => {
        if (dropdownRef.current.classList.contains('show')) {
            dropdownRef.current.classList.remove('show');
            $(dropdownContentRef.current).slideUp();
        } else {
            dropdownRef.current.classList.add('show');
            $(dropdownContentRef.current).slideDown();
        }
    };

    const clickEdit = () => {
        click();
        activateEditing();
    };

    const openDeleteModal = () => {
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        click();
    };

    const submitDelete = async (e) => {
        e.preventDefault();
        try {
            setIsDeleteModalOpen(false);
            click();
            await CategoryService.deleteCategory(item.id, user);
            onSubmit();
        } catch (err) {
            setErrors(err.response.data.errors);
        }
    };

    useEffect(() => {
        const errorTimer = setTimeout(() => {
            setErrors('');
        }, 5000);

        return () => {
            clearTimeout(errorTimer);
        };
    }, [errors]);

    return (
        <div className={`col-xxl-3 col-6 folder-card ${parseInt(categoryId) === item.id ? 'active' : ''}`}>
            <div className='card bg-light shadow-none'>
                <div className="dropdown-container-absolute">
                    <div className='d-flex mb-1'>
                        <div className='form-check form-check-danger mb-3 fs-15 flex-grow-1'></div>
                        <div className='dropdown'>
                            <span
                                className='btn btn-ghost-primary btn-icon btn-sm dropdown'
                                type='button'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                                onClick={click}
                                ref={dropdownRef}
                            >
                                <RiMore2Fill className='fs-16' />
                            </span>
                            <ul ref={dropdownContentRef} className='dropdown-menu dropdown-menu-end'>
                                <li>
                                    <span
                                        className='dropdown-item edit-folder-list'
                                        data-bs-toggle='modal'
                                        role='button'
                                        onClick={clickEdit}
                                    >
                                        Bearbeiten
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <span className='dropdown-item' data-bs-toggle='modal' role='button' onClick={openDeleteModal}>
                                            Löschen
                                        </span>
                                        <div
                                            className={`modal fade ${isDeleteModalOpen ? 'show' : ''}`}
                                            style={{ display: isDeleteModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                                            aria-hidden={!isDeleteModalOpen}
                                        >
                                            <div className='modal-dialog'>
                                                <div className='modal-content'>
                                                    <div className='modal-header'>
                                                        <h5 className='modal-title'>Objektkategorie "{item.name}" löschen</h5>
                                                        <button
                                                            type='button'
                                                            className='btn-close'
                                                            aria-label='Close'
                                                            onClick={closeDeleteModal}
                                                        />
                                                    </div>
                                                    <div className='modal-body'>
                                                        <div>
                                                            Wollen Sie wirklich Objektkategorie "{item.name}" löschen?<br/>
                                                            <br/>
                                                            Die Objektkategorie kann nur gelöscht werden, wenn sie keine Objekte enthält. Wenn erforderlich löschen Sie bitte vorher alle Objekte.
                                                        </div>
                                                    </div>
                                                    <div className='modal-footer'>
                                                        <button type='button' className='btn btn-light' onClick={closeDeleteModal}>
                                                            Abbrechen
                                                        </button>
                                                        <button type='submit' className='btn btn-danger' onClick={submitDelete}>
                                                            Löschen
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className={`toastify ${errors.SQL ? 'on' : ''} bg-danger toastify-center toastify-top`} aria-live="polite" style={{transform: "translate(0px, 0px)", top: "15px"}}>Die Objektkategorie: {state.name} hat noch Objekte. Bitte vorher löschen.</div>
                <Link to={`/management/place/${placeId}/category/${item.id}`}>
                    <div className='card-body'>
                        <div className='d-flex mb-1'>
                            <div className='form-check form-check-danger mb-3 fs-15 flex-grow-1'></div>
                        </div>
                        <div className='text-center'>
                            <div className='mb-2'>
                                <GrObjectGroup className='stroke-success text-success display-5' />
                            </div>
                            <h6 className='fs-15 folder-name'  onClick={activateEditing}>
                                {isEditing ? (
                                    <input
                                        type="text"
                                        className="form-control form-control-edit text-center"
                                        value={state.name}
                                        onChange={change}
                                        onBlur={saveChanges}
                                        autoFocus={true}
                                    />
                                ) : (
                                    state.name
                                )}
                            </h6>
                        </div>
                        <div className='hstack mt-4 text-muted'></div>
                    </div>
                </Link>
            </div>
        </div>
    );
};

export default CategoryCard;

import React, { useEffect, useState } from 'react';
import SimpleBar from 'simplebar-react';
import ManagementSidebarEntry from './ManagementSidebarEntry';
import { useOrganization } from '../../hooks/useOrganization';
import { useAuth } from '../../hooks/useAuth';
import { useLocation } from 'react-router-dom';
import PlaceService from "../../services/PlaceService";

const ManagementSidebar = () => {
    const { organization } = useOrganization();
    const { user } = useAuth();

    const [sitemap, setSitemap] = useState([]);

    let location = useLocation();

    const fetch = async () => {
        try {
            const response = await PlaceService.getPlaces(organization.id, user);
            const filteredPlaces = response.data.filter((item) => {
                if (!item.channels || item.channels.length === 0) {
                    return true;
                }
                return !item.channels[0].channelDataMap.deleted;
            });
            setSitemap(filteredPlaces);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    useEffect(() => {
        fetch();
    }, [location]);

    return (
        <div className='file-manager-sidebar'>
            <div className='p-3 d-flex flex-column h-100'>
                <div className='mb-3'>
                    <h5 className='mb-0 fw-semibold'>Objektstruktur</h5>
                </div>
                <SimpleBar className='mt-3 mx-n4 px-4 file-menu-sidebar-scroll'>
                    <ul className='list-unstyled file-manager-menu'>
                        {sitemap.map((item, idx) => (
                            <ManagementSidebarEntry item={item} key={idx} />
                        ))}
                    </ul>
                </SimpleBar>
            </div>
        </div>
    );
};

export default ManagementSidebar;

import React, {useEffect, useState} from 'react';
import PropertiesService from '../../../services/PropertiesService';  // Updated service name
import { useAuth } from '../../../hooks/useAuth';
import {RiEditLine} from "react-icons/ri";
import CategoryService from "../../../services/CategoryService";

const CategoryDaysCheckboxes = ({ tag, category, onSubmit }) => {
    const { user } = useAuth();

    const { id, bookingObjectSeasonProperties, ...restFullState } = category;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState
    });

    useEffect(() => {
        const { id, bookingObjectSeasonProperties, ...restFullState } = category;

        setFullStateEdit({
            ...restFullState
        });

    }, [category]);



    const [isDaysModalOpen, setIsDaysModalOpen] = useState(false);

    const openDaysModal = () => {
        setIsDaysModalOpen(true);
    };

    const closeDaysModal = () => {
        setIsDaysModalOpen(false);
    };

    const submitDaysModal = async () => {
        setIsDaysModalOpen(false);
        await saveChanges();
    };

    const toggleDay = (day) => {
        setFullStateEdit(prevState => {
            const newDays = prevState.bookingProperties[tag].includes(day)
                ? prevState.bookingProperties[tag].filter(d => d !== day)
                : [...prevState.bookingProperties[tag], day];

            return {
                ...prevState,
                bookingProperties: {
                    ...prevState.bookingProperties,
                    [tag]: newDays,
                },
            };
        });
    };

    const saveChanges = async () => {
        try {
            await CategoryService.putCategory(category.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    const daysMap = {
        'MONDAY': 'Montag',
        'TUESDAY': 'Dienstag',
        'WEDNESDAY': 'Mittwoch',
        'THURSDAY': 'Donnerstag',
        'FRIDAY': 'Freitag',
        'SATURDAY': 'Samstag',
        'SUNDAY': 'Sonntag'
    };

    return (
        <div className="row" style={{zIndex: "1050"}}>
            <div className="col-9">
                <div className="olap-description-text d-flex flex-wrap gap-2">
                    {fullStateEdit.bookingProperties[tag] && fullStateEdit.bookingProperties[tag].map(day => (
                        <span className={'badge bg-success-subtle text-success badge-border'} key={day}>{daysMap[day]} </span>
                    ))}
                </div>
            </div>
            <div className="col-3">
                <div onClick={openDaysModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <RiEditLine />
                </div>
                <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isDaysModalOpen ? 'show' : ''}`}
                     style={{ display: isDaysModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <h5 className='modal-title'>
                                    Tage bearbeiten
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeDaysModal} />
                            </div>
                            <div className='modal-body'>
                                <div>
                                    {Object.keys(daysMap).map(day => (
                                        <div key={day}>
                                            <label className={"cursor-pointer"}>
                                                <input
                                                    className={"form-check-input"}
                                                    type='checkbox'
                                                    value={day}
                                                    checked={fullStateEdit.bookingProperties[tag].includes(day)}
                                                    onChange={() => toggleDay(day)}
                                                />
                                                <span className={"ms-1"}>{daysMap[day]}</span>
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='modal-footer'>
                            <span className='btn btn-light' onClick={closeDaysModal}>
                                Abbrechen
                            </span>
                                <span className='btn btn-success' onClick={submitDaysModal}>
                                Speichern
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryDaysCheckboxes;
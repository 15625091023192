import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import SimpleBar from 'simplebar-react';
import { RiBellLine, RiClockwiseLine, RiNotificationBadgeLine } from 'react-icons/ri';

const NotificationButton = () => {
    /**
     * Functions
     */

    const click = () => {
        if (dropdownRef.current.classList.contains('show')) {
            dropdownRef.current.classList.remove('show');
            $(dropdownContentRef.current).slideUp();
        } else {
            dropdownRef.current.classList.add('show');
            $(dropdownContentRef.current).slideDown();
        }
    };

    const dropdownRef = useRef();
    const dropdownContentRef = useRef();

    return (
        <div ref={dropdownRef} className="dropdown topbar-head-dropdown ms-1 header-item d-none">
            <span onClick={click} className="notification-btn btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                <RiBellLine className="bx bx-bell fs-22" />
                <span className="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger">1<span className="visually-hidden">unread messages</span></span>
            </span>
            <div ref={dropdownContentRef} className="notification-dropdown dropdown-menu dropdown-menu-lg dropdown-menu-end p-0">
                <div className="dropdown-head bg-primary bg-pattern rounded-top">
                    <div className="p-3">
                        <div className="row align-items-center">
                            <div className="col">
                                <h6 className="m-0 fs-16 fw-semibold text-white"> Benachrichtigungen </h6>
                            </div>
                            <div className="col-auto dropdown-tabs">
                                <span className="badge bg-secondary-subtle text-body fs-13"> 1 Neu</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="tab-content position-relative" id="notificationItemsTabContent">
                    <div className="tab-pane fade show active py-2 ps-2" id="all-noti-tab" role="tabpanel">
                        <SimpleBar>
                            <div
                                className="text-reset notification-item d-block dropdown-item position-relative">
                                <div className="d-flex">
                                    <div className="avatar-xs me-3 flex-shrink-0">
                                                <span
                                                    className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                    <RiNotificationBadgeLine className="bx bx-badge-check" />
                                                </span>
                                    </div>
                                    <div className="flex-grow-1">
                                        <Link onClick={click} to="/saison-wizard" className="stretched-link">
                                            <h6 className="mt-0 mb-2 lh-base">Du hast eine <b>Organisation</b> mit Plätzen, Objektkategorien und Objekten angelegt. Jetzt kannst du mit
                                                dem <span
                                                    className="text-secondary">Saison Wizard</span> fortfahren.
                                            </h6>
                                        </Link>
                                        <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                            <span><RiClockwiseLine className="mdi mdi-clock-outline" /> Vor 30 Sekunden</span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </SimpleBar>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default NotificationButton;

import React, {useRef, useState} from 'react';
import {RiCalendarLine, RiHazeLine, RiMore2Fill} from "react-icons/ri";
import moment from "moment";
import {useAuth} from "../../hooks/useAuth";
import SeasonService from "../../services/SeasonService";
import $ from "jquery";
import {Link} from "react-router-dom";
import {useOrganization} from "../../hooks/useOrganization";

const CalendarCard = ({item, onSubmit}) => {
    const [calendar, setCalendar] = useState(item);
    const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false)
    const {user} = useAuth();
    const {organization} = useOrganization();
    const [errors, setErrors] = useState(false);

    const dropdownRef = useRef();
    const dropdownContentRef = useRef();

    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const click = () => {
        if (dropdownRef.current.classList.contains('show')) {
            dropdownRef.current.classList.remove('show');
            $(dropdownContentRef.current).slideUp();
        } else {
            dropdownRef.current.classList.add('show');
            $(dropdownContentRef.current).slideDown();
        }
    };

    const openCalendarModal = () => {
        setIsCalendarModalOpen(true);
    };

    const closeCalendarModal = () => {
        setIsCalendarModalOpen(false);
        click();
    };

    const openDeleteModal = () => {
        setIsDeleteModalOpen(true);
    };

    const closeDeleteModal = () => {
        setIsDeleteModalOpen(false);
        click();
    };

    const submitDelete = async (e) => {
        e.preventDefault();
        try {
            await SeasonService.deleteCalendar(calendar.id, user);
            setIsDeleteModalOpen(false);
            click();
            onSubmit();
        } catch (err) {
            //TODO
            setErrors(err.response.data.errors)
        }
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            await SeasonService.putCalendar(calendar.id, user, calendar)
            setIsCalendarModalOpen(false);
            click();
            onSubmit();
        } catch (err) {
            //TODO
            throw err;
        }
        return false;
    };

    const change = (e) => {
        setCalendar({ ...calendar, [e.target.name]: e.target.value });
    };


    return(
        <div className='col-xxl-3 col-6 folder-card'>
            <div className='card bg-light shadow-none'>
                <div className="dropdown-container-absolute">
                    <div className='d-flex mb-1'>
                        <div className='dropdown'>
                            <button
                                className='btn btn-ghost-primary btn-icon btn-sm dropdown'
                                type='button'
                                data-bs-toggle='dropdown'
                                aria-expanded='false'
                                onClick={click}
                                ref={dropdownRef}
                            >
                                <RiMore2Fill className='fs-16' />
                            </button>
                            <ul ref={dropdownContentRef} className='dropdown-menu dropdown-menu-end' style={{zIndex: "1054"}}>
                                <li>
                                    <span className='dropdown-item' data-bs-toggle='modal' role='button' onClick={openCalendarModal}>
                                        Bearbeiten
                                    </span>
                                </li>
                                <li>
                                    <div>
                                        <span className='dropdown-item' data-bs-toggle='modal' role='button' onClick={openDeleteModal}>
                                            Löschen
                                        </span>
                                        <div
                                            className={`modal fade ${isDeleteModalOpen ? 'show' : ''}`}
                                            style={{ display: isDeleteModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                                            aria-hidden={!isDeleteModalOpen}
                                        >
                                            <div className='modal-dialog'>
                                                <div className='modal-content'>
                                                    <div className='modal-header'>
                                                        <h5 className='modal-title'>Kalender "{item.name}" löschen</h5>
                                                        <button
                                                            type='button'
                                                            className='btn-close'
                                                            aria-label='Close'
                                                            onClick={closeDeleteModal}
                                                        />
                                                    </div>
                                                    <div className='modal-body'>
                                                        <div>
                                                            Wollen Sie wirklich den Kalender "{item.name}" löschen?
                                                        </div>
                                                        <div className='text-danger'>
                                                            <div className="col-xl-12 text-danger">{errors && (
                                                                <div className="text-danger">Leider kann dieser Kalender derzeit nicht gelöscht werden, da er noch Saisonzeiträume enthält.</div>
                                                            )}</div>
                                                        </div>

                                                    </div>
                                                    <div className='modal-footer'>
                                                        <button type='button' className='btn btn-light' onClick={closeDeleteModal}>
                                                            Abbrechen
                                                        </button>
                                                        <button type='submit' className='btn btn-danger' onClick={submitDelete}>
                                                            Löschen
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <Link to={`/saison-wizard/calendar/${item.id}`}>
                    <div className='card-body'>
                        <div className='text-center'>
                            <div className='mb-2 mt-2'>
                                <RiCalendarLine className='text-success display-5' />
                            </div>
                            <h6 className='fs-15 folder-name'>
                                {item.name}
                            </h6>
                        </div>
                        <div className='mt-2 text-muted text-center'>
                            {item.year}
                        </div>
                    </div>
                </Link>
            </div>
            <div
                className={`modal fade ${isCalendarModalOpen ? 'show' : ''}`}
                style={{ display: isCalendarModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                aria-hidden={!isCalendarModalOpen}
            >
                <div className='modal-dialog modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='myModalLabel'>
                                {calendar.name} bearbeiten
                            </h5>
                            <button
                                type='button'
                                className='btn-close'
                                aria-label='Close'
                                onClick={closeCalendarModal}
                            />
                        </div>
                        <div className='modal-body'>
                            <div className='row mb-2'>
                                <div className="text-muted fw-medium mb-2">Name des Kalenders</div>
                                <div className="col-xl-12">
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='name'
                                        value={calendar.name}
                                        onChange={change}
                                    />
                                </div>
                            </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-light' onClick={closeCalendarModal}>
                                Abbrechen
                            </button>
                            <button type='submit' className='btn btn-success' onClick={submit}>
                                Speichern
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    );
};

export default CalendarCard;
import React, {useEffect, useRef, useState} from "react";
import {useLocation, Link, useParams} from "react-router-dom";
import {useAuth} from "../../hooks/useAuth";
import Loader from "../../components/Loader";
import AlertCard from "../../components/AlertCard";
import ObjectService from "../../services/ObjectService";
import OlapObjects from "./OlapObjects";
import {RiArrowDownSLine, RiHotelBedLine} from "react-icons/ri";
import {GrObjectGroup} from 'react-icons/gr';
import $ from "jquery";

const OlapCategories = ({ item, parent, handleSidebarChange }) => {
    const [loading, setLoading] = useState(true);
    const [objects, setObjects] = useState([]);
    const { categoryId } = useParams();

    let location = useLocation();

    const linkRef = useRef();
    const collapseRef = useRef();

    const { user } = useAuth();

    const click = (e) => {
        if (linkRef.current.classList.contains('active')) {
            linkRef.current.classList.remove('active');
            linkRef.current.setAttribute('aria-expanded', 'false');
            $(collapseRef.current).slideUp();
        } else {
            linkRef.current.classList.add('active');
            linkRef.current.setAttribute('aria-expanded', 'true');
            $(collapseRef.current).slideDown();
        }
    };

    const fetchCategories = async () => {
        const response = await ObjectService.getObjects(item.id, user);
        const filteredObjects = response.data.filter((item) => {
            if (!item.channels || item.channels.length === 0) {
                return true;
            }
            return !item.channels[0].channelDataMap.deleted;
        });
        setObjects(filteredObjects);
        setLoading(false);
    };

    useEffect(() => {
        fetchCategories();
    }, [location]);

    return (
        <li className="li-unstyled">
            <Link to={`/olap/place/${parent.id}/category/${item.id}`} className={`${categoryId === item.id.toString() ? 'active' : ''}`}>
                <GrObjectGroup className="me-2"/>
                <span>{item.name}</span>
            </Link>
            <span className="ms-2 olap-sidebar-button" aria-expanded='false' ref={linkRef} onClick={click}>
                    <span className="icon"><RiArrowDownSLine /></span>
                </span>
            <div className='menu-dropdown' ref={collapseRef} style={{display: "none"}}>
                {loading ? (
                    <Loader card={false} />
                ) : (
                    <ul className='row list-unstyled' id='folderlist-data'>
                        {objects.length === 0 && <AlertCard>In dieser Objektkategorie gibt es noch keine Objekte.</AlertCard>}
                        {objects.map((child, idx) => (
                            <OlapObjects grandparent={parent} parent={item} item={child} key={idx} handleSidebarChange={handleSidebarChange}/>
                        ))}
                    </ul>
                )}
            </div>
        </li>
    );
}

export default OlapCategories;
import React, {useEffect, useState} from 'react';
import { useAuth } from '../../../hooks/useAuth';
import {RiEditLine} from "react-icons/ri";
import ObjectService from "../../../services/ObjectService";
import {Circle, MapContainer, Marker, Polygon, TileLayer, useMapEvents} from "react-leaflet";

const ObjectGeoLocation = ({ tag, category, object, onSubmit }) => {
    const { user } = useAuth();
    const [drawingMode, setDrawingMode] = useState('marker'); // 'marker', 'circle', 'polygon'
    const { id, bookingObjectSeasonProperties, ...restFullState } = object;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState,
        [tag]: object[tag].map(location => ({
            coordinates: location ? location.coordinates.map((coord) => ({ ...coord})).sort((a, b) => a.position - b.position) || [] : [],
            type: location ? location.type || '' : '',
            radius: location ? location.radius || '' : ''
        }))
    });

    useEffect(() => {
        const { id, bookingObjectSeasonProperties, ...restFullState } = object;

        setFullStateEdit({
            ...restFullState,
            [tag]: restFullState[tag].map(location => ({
                coordinates: location ? location.coordinates.map((coord) => ({ ...coord})).sort((a, b) => a.position - b.position) || [] : [],
                type: location ? location.type || '' : '',
                radius: location ? location.radius || '' : ''
            }))
        });
    }, [object]); // eslint-disable-line react-hooks/exhaustive-deps

    const [isModalOpen, setIsModalOpen] = useState(false);

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const submitModal = async () => {
        setIsModalOpen(false);
        await saveChanges();
    };

    const saveChanges = async () => {
        try {
            await ObjectService.putObject(object.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };


    const MapClickHandler = () => {
        useMapEvents({
            click(e) {
                const { lat, lng } = e.latlng;
                if (drawingMode === 'marker') {
                    setFullStateEdit(prevState => {
                        const newLocation = { coordinates: [{ lat, lng, position: 0 }], type: 'marker' };
                        const updatedLocations = [newLocation];
                        return {
                            ...prevState,
                            [tag]: updatedLocations,
                        };
                    });
                }
                if (drawingMode === 'circle') {
                    setFullStateEdit(prevState => {
                        const newLocation = { coordinates: [{ lat, lng, position: 0 }], type: 'circle', radius: 500 };
                        const updatedLocations = [newLocation];
                        return {
                            ...prevState,
                            [tag]: updatedLocations,
                        };
                    });
                }

                if (drawingMode === 'polygon') {
                    setFullStateEdit(prevState => {
                        const lastPolygon = prevState[tag].find(location => location.type === 'polygon');
                        if (lastPolygon) {
                            const updatedPolygon = {
                                ...lastPolygon,
                                coordinates: [...lastPolygon.coordinates, { lat, lng, position: lastPolygon.coordinates.length }]
                            };
                            const updatedLocations = prevState[tag].map(location =>
                                location === lastPolygon ? updatedPolygon : location
                            );
                            return {
                                ...prevState,
                                [tag]: updatedLocations,
                            };
                        } else {
                            const newLocation = { coordinates: [{ lat, lng, position: 0 }], type: 'polygon' };
                            const updatedLocations = [newLocation];
                            return {
                                ...prevState,
                                [tag]: updatedLocations,
                            };
                        }
                    });
                }
            }
        });
        return null;
    };
    return (
        <div className="row" style={{zIndex: "1050"}}>
            <div className="col-9">
                <div className="olap-description-text d-flex flex-wrap gap-2">
                    {fullStateEdit[tag] && fullStateEdit[tag].map((location, index) => (
                            <span className={'badge bg-success-subtle text-success badge-border'} key={index}>
                           Typ:{location.type}, Lat: {location.coordinates[0].lat}, Lon: {location.coordinates[0].lng}, {location.radius ? location.radius : ''}
                        </span>
                        )
                    )}
                </div>
            </div>
            <div className="col-3">
                <div onClick={openModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <RiEditLine />
                </div>
                <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isModalOpen ? 'show' : ''}`}
                     style={{ display: isModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <h5 className='modal-title'>
                                    GPS Daten bearbeiten
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeModal} />
                            </div>
                            <div className='modal-body'>
                                <div className="row">
                                    <div className="col-9">
                                        {
                                            isModalOpen && (
                                                <MapContainer center={[54.31239261756474, 13.088577130518907]} zoom={14} style={{ height: "500px", width: "100%" }}>
                                                    <TileLayer
                                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                                    />
                                                    <MapClickHandler />
                                                    {fullStateEdit[tag].map((marker, idx) => (
                                                        marker.type === 'marker' ? (
                                                            <Marker key={idx} position={marker.coordinates[0]} />
                                                        ) : (
                                                            //if market.type === 'circle' then show circle
                                                            marker.type === 'circle' ? (
                                                                <Circle key={idx} center={marker.coordinates[0]} radius={marker.radius} />
                                                            ) : (
                                                                <Polygon key={idx} positions={marker.coordinates} />
                                                            )
                                                        )
                                                    ))}
                                                </MapContainer>
                                            )
                                        }
                                    </div>
                                    <div className="col-3">
                                        <div className="btn-group">
                                            <button className={`btn ${drawingMode === 'marker' ? 'btn-primary' : 'btn-soft-primary'}`} onClick={() => setDrawingMode('marker')}>Marker</button>
                                            <button className={`btn ${drawingMode === 'circle' ? 'btn-primary' : 'btn-soft-primary'}`} onClick={() => setDrawingMode('circle')}>Kreis</button>
                                            <button className={`btn ${drawingMode === 'polygon' ? 'btn-primary' : 'btn-soft-primary'}`} onClick={() => setDrawingMode('polygon')}>Polygon</button>
                                        </div>
                                        {fullStateEdit[tag].map((marker, idx) => (
                                            <div className="mt-3">
                                                <div>
                                                    <div>Typ: {marker.type}</div>
                                                    {marker.type === 'circle' && (
                                                        <div>Radius: {marker.radius}</div>
                                                    )}
                                                </div>
                                                {marker.coordinates.map((cord, index) => (
                                                    <div className="mt-3">
                                                        <div>Latitude {cord.position}: {cord.lat}</div>
                                                        <div>Longitude {cord.position}: {cord.lng}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className='modal-footer'>
                            <span className='btn btn-light' onClick={closeModal}>
                                Abbrechen
                            </span>
                                <span className='btn btn-success' onClick={submitModal}>
                                Speichern
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ObjectGeoLocation;
import axios from 'axios';
import config from '../config.json';
import PlaceService from './PlaceService';

class CategoryService {
    static async getCategories(placeId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/places/${placeId}/categories`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async getCategory(categoryId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/categories/${categoryId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putCategory(categoryId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/categories/${categoryId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteCategory(categoryId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/categories/${categoryId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postCategory(placeId, user, organization, state = { name: 'Kategorie 1', bookingProperties: {blocked: false}, bookable: false}) {
        let place = 0;
        if (!placeId) {
            const response = await PlaceService.postPlace(organization.id, user);
            place = response.data.id;
        } else {
            place = placeId;
        }

        const response = await axios.post(`${config.API_BASE_URI}/api/v1/places/${place}/categories`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });

        return {
            placeId: place,
            categoryId: response.data.id,
        };
    }
}

export default CategoryService;

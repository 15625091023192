import React, {useEffect, useState} from 'react';
import { RiEditLine } from 'react-icons/ri';

const OlapCategoryModal = () => {
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [editState, setEditState] = useState({
        categories: [],
    });

    const targetGroupsList = [
        'Alleinlage', 'Autovermietung', 'Bauernhof', 'Berg-Urlaub', 'Besondere Übernachtungen', 'Familie/Kinder', 'Feiertage',
        'Geschäftsreisen', 'Golf', 'Gourmet/Genießer', 'Kultur', 'Landurlaub', 'Langzeitvermieter', 'Luxusurlaub', 'Medical Wellness/Kur',
        'Meerblick', 'Merchandising-Artikel', 'Motorrad', 'Nationalpark', 'Natur(park)', 'Pauschalangebote', 'Reisegruppen',
        'Reiten', 'Rollstuhlfahrer', 'Romantikurlaub', 'Seeblick', 'Senioren', 'Skaten', 'Sonstiges', 'Sport & Spiel', 'Städtereise',
        'Strand & Baden', 'Tagung/Konferenz', 'Tauchen', 'Tickets', 'Urlaub am See', 'Urlaub in historischen Gebäuden', 'Urlaub mit Hund',
        'Urlaub mit Pool', 'Wandern/Walking', 'Wassersport', 'Wein - Urlaub', 'Wellness/Fitness/Gesundheit', 'Wintersport (Ski, Langlauf)',
    ];


    const openCategoryModal = () => {
        setIsCategoryModalOpen(true);
    };

    const closeCategoryModal = () => {
        setIsCategoryModalOpen(false);
    };

    const submitCategoryModal = () => {
        setIsCategoryModalOpen(false);
    };

    const handleCheckboxChange = (category) => {
        const updatedCategories = [...editState.categories];
        const index = updatedCategories.indexOf(category);

        if (index === -1) {
            updatedCategories.push(category);
        } else {
            updatedCategories.splice(index, 1);
        }

        setEditState({ ...editState, categories: updatedCategories });
    };

    return (
        <div className="row" style={{zIndex: "1050"}}>
            <div className="col-9">
                <div className="d-flex flex-wrap gap-2">
                    {editState.categories.map((category) => (
                        <span key={category} className="badge bg-success-subtle text-success badge-border">
                            {category}
                        </span>
                    ))}
                </div>
            </div>
            <div className="col-3">
                <div onClick={openCategoryModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <RiEditLine />
                </div>
                <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isCategoryModalOpen ? 'show' : ''}`}
                     style={{ display: isCategoryModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <h5 className='modal-title'>
                                    Objektkategorie bearbeiten
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeCategoryModal} />
                            </div>
                            <div className='modal-body'>
                                <div className="hstack gap-2 flex-wrap">
                                    {targetGroupsList.map((category) => (
                                        <div key={category}>
                                            <input
                                                type="checkbox"
                                                className="btn-check"
                                                id={`btn-check-${category}`}
                                                onChange={() => handleCheckboxChange(category)}
                                                checked={editState.categories.includes(category)}
                                            />
                                            <label className="btn btn-outline-success" htmlFor={`btn-check-${category}`}>
                                                {category}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='modal-footer'>
                            <span type='button' className='btn btn-light' onClick={closeCategoryModal}>
                                Abbrechen
                            </span>
                                <span className='btn btn-success' onClick={submitCategoryModal}>
                                Speichern
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OlapCategoryModal;

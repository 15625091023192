import React, { useEffect, useState } from 'react';
import { RiEditLine } from 'react-icons/ri';
import { useAuth } from '../../../hooks/useAuth';
import CategoryService from "../../../services/CategoryService";

const CategoryCategoriesNumberModal = ({ tag, category, onSubmit }) => {
    const categoryEnums = {
        beds: {
            DOUBLE_BED: 'Doppelbett (180-195 cm breit, keine zusammengestellten Einzelbetten)',
            LARGE_FRENCH_BED: 'Großes französisches Bett (150-160 cm breit)',
            SMALL_FRENCH_BED: 'Kleines französisches Bett (135-140 cm breit)',
            BUNK_BED: 'Etagenbett',
            SOFA_BED: 'Schlafsofa',
            CHILD_BED: 'Kinderbett (ca. 70cm breit)',
            BABY_BED: 'Babybett (ca. 50cm breit)',
        },
    };

    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    const { user } = useAuth();

    const { id, bookingObjectSeasonProperties, ...restFullState } = category;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState
    });

    useEffect(() => {
        const { id, bookingObjectSeasonProperties, ...restFullState } = category;

        setFullStateEdit({
            ...restFullState
        });

    }, [category]);

    const openDescriptionModal = () => {
        setIsDescriptionModalOpen(true);
    };

    const closeDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
    };

    const submitDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
        saveChanges();
    };

    const saveChanges = async () => {
        try {
            await CategoryService.putCategory(category.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    const toggleCategoryItem = (categoryItem) => {
        setFullStateEdit((prevState) => {
            const newCategoryItems = { ...prevState[tag] };

            if (newCategoryItems[categoryItem]) {
                // If categoryItem exists, remove it
                delete newCategoryItems[categoryItem];
            } else {
                // If categoryItem doesn't exist, add it with the value 1
                newCategoryItems[categoryItem] = 1;
            }

            return {
                ...prevState,
                [tag]: newCategoryItems,
            };
        });
    };

    const handleInputChange = (categoryItem, value) => {
        setFullStateEdit((prevState) => ({
            ...prevState,
            [tag]: {
                ...prevState[tag],
                [categoryItem]: value,
            },
        }));
    };

    return (
        <div className="row" style={{ zIndex: '1051' }}>
            <div className="col-9 d-flex align-items-center">
                <div className="olap-description-text d-flex flex-wrap gap-2">
                    {Object.keys(fullStateEdit[tag]).map((categoryItem) => (
                        <span className={'badge bg-success-subtle text-success badge-border'}>
                          {categoryEnums[tag][categoryItem]} - {fullStateEdit[tag][categoryItem]}
                        </span>
                    ))}
                </div>
            </div>
            <div className="col-3">
                <div
                    onClick={openDescriptionModal}
                    className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#theme-settings-offcanvas"
                    aria-controls="theme-settings-offcanvas"
                >
                    <RiEditLine />
                </div>
                <div
                    id="bottom-rightModal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className={`modal fade ${isDescriptionModalOpen ? 'show' : ''}`}
                    style={{ display: isDescriptionModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Kategorie bearbeiten</h5>
                                <button type="button" className="btn-close" aria-label="Close" onClick={closeDescriptionModal} />
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className={'row'}>
                                        {Object.keys(categoryEnums[tag]).map((categoryItem) => (
                                            <div key={categoryItem} className={'col-lg-6'}>
                                                <label className={'cursor-pointer'}>
                                                    <input
                                                        className={'form-check-input'}
                                                        type="checkbox"
                                                        value={categoryItem}
                                                        checked={Object.keys(fullStateEdit[tag]).includes(categoryItem)}
                                                        onChange={() => toggleCategoryItem(categoryItem)}
                                                    />
                                                    <span className={'ms-1'}>{categoryEnums[tag][categoryItem]}</span>
                                                    <input
                                                        type="number"
                                                        className={"form-control"}
                                                        value={fullStateEdit[tag][categoryItem] || 0}
                                                        onChange={(e) => handleInputChange(categoryItem, parseInt(e.target.value, 10))}
                                                    />
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <span type="button" className="btn btn-light" onClick={closeDescriptionModal}>
                                  Abbrechen
                                </span>
                                                <span className="btn btn-success" onClick={submitDescriptionModal}>
                                  Speichern
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CategoryCategoriesNumberModal;

import React from "react";
import {useDrag} from "react-dnd";
import moment from "moment/moment";

const ObjectsAndSeasonsDraggableSeason  = ({item}) => {

    const [{ isDragging }, drag] = useDrag({
        type: 'SEASON',
        item: { id: item.id, name: item.name, type: 'SEASON', dateRanges: item.dateRanges},
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
            handlerId: monitor.getHandlerId(),
        }),
    });
    const opacity = isDragging ? 0.4 : 1

    return (
        <div ref={drag} style={{ opacity }} className="card bg-light m-1 draggable">
            <div className="card-body">
                {item.name}
                {item.dateRanges.map((range, index) => (
                    <div className="row ms-1" key={index}>
                        <div className="col-12">
                            {moment(range.dateFrom).format('DD.MM.YYYY')} - {moment(range.dateTo).format('DD.MM.YYYY')}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default ObjectsAndSeasonsDraggableSeason ;
import React, { Fragment } from 'react';
import ManagementSidebar from '../ManagementSidebar';
import ObjectManagementHeader from '../ObjectManagementHeader';
import PlaceManagementContent from './PlaceManagementContent';
import Tutorial from "../Tutorial";

const PlaceManagement = () => {
    return (
        <Fragment>
            <ObjectManagementHeader />
            <div className='chat-wrapper d-lg-flex gap-1 mx-n4 mt-n4 p-1'>
                <ManagementSidebar />
                <PlaceManagementContent />
            </div>
            <Tutorial />
        </Fragment>
    );
};

export default PlaceManagement;

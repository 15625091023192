import React, {useEffect, useState} from 'react';
import {useSearchParams} from "react-router-dom";
import {RiCloseLine} from 'react-icons/ri';
import {Link, useLocation} from 'react-router-dom';

const NewObjectNotification = () => {
    const [isNotificationOpen, setIsNotificationOpen] = useState(false);

    let location = useLocation();

    const openNotification = () => {
        setIsNotificationOpen(true);
    };

    const closeNotification = (e) => {
        setIsNotificationOpen(false);
    };

    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        if (searchParams.get('newObject') === 'true') {
            openNotification();
        }
    }, [location]);

    return (
        <div>
            <div className={`toastify new-object-toast ${isNotificationOpen && 'on'}`}>
                <Link to={'/saison-wizard'}>
                    Du hast neue Objekte erstellt! Wenn du möchtest, kannst nun direkt mit deinen Saisons fortfahren. (Hier klicken)
                </Link>
                <span className="btn btn-ghost-light toast-close" onClick={closeNotification}>
                    <RiCloseLine  />
                </span>
            </div>

        </div>
    );
};

export default NewObjectNotification;

import React, {Fragment, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useAuth} from "../../../hooks/useAuth";
import AlertCard from "../../../components/AlertCard";
import { useLocation } from 'react-router-dom';
import SaisonCard from "../SaisonCard";
import AddSaisonModal from "../AddSaisonModal";
import SeasonService from "../../../services/SeasonService";
import Loader from "../../../components/Loader";

const FourthNew = () => {
    const [saisons, setSaisons] = useState([]);
    const { user } = useAuth();
    const {calendarId} = useParams();
    const [calendar, setCalendar] = useState({});

    const [loading, setLoading] = useState(true);
    let location = useLocation();
    const fetchSaisons = async () => {
        setLoading(true);
        const response = await SeasonService.getSeasons(calendarId, user);
        setSaisons(response.data);
        setLoading(false);
    };

    const fetchCalendar = async () => {
        setLoading(true);
        const response = await SeasonService.getCalendar(calendarId, user);
        setCalendar(response.data);
        setLoading(false);
    }

    const onModalSubmit = () => {
        setLoading(true);
        fetchSaisons();

    };

    useEffect(() => {
        fetchCalendar();
        fetchSaisons();
    }, [location]);

    return (
        <Fragment>
            <div>
                <h5>Saisons</h5>
                <p className="text-muted">
                    Wählen Sie Ihre Saisons aus oder erstellen Sie neue.
                </p>
                <div className='row' id='folderlist-data'>
                    {loading && <Loader />}
                    {loading !== true && saisons.length === 0 && <AlertCard>Es wurde für diesen Saisonkalender noch keine Saison erstellt</AlertCard>}
                    {loading !== true && saisons.map((saisonItem, idx) => (
                        <SaisonCard saisonItem={saisonItem} key={idx} calendarId={calendarId} saisons={saisons} calendar={calendar} onSubmit={onModalSubmit}/>
                    ))}
                </div>
                <AddSaisonModal calendarId={calendarId} saisons={saisons} calendar={calendar} onSubmit={onModalSubmit}/>
            </div>
        </Fragment>
    );

};

export default FourthNew;
import React from 'react';
import ProfileBackground from "./ProfileBackground";
import ProfileHeader from "./ProfileHeader";
import ProfileContent from "./ProfileContent";

const Profile = () => {

    return (
            <div>
                <ProfileBackground />
                <ProfileHeader />
                <ProfileContent />
            </div>
    );
};

export default Profile;

import axios from "axios";
import React, {useEffect, useState} from 'react';
import {RiCloseLine} from "react-icons/ri";
import config from "../../config.json";
import {useAuth} from "../../hooks/useAuth";
import SeasonService from "../../services/SeasonService";
import {isEmpty} from "../../utils/validation";

const AddSaisonModal = ({calendarId, onSubmit, calendar, saisons}) => {
    const [isSeasonModalOpen, setIsSeasonModalOpen] = useState(false);

    const [saison, setSaison] = useState({
        name: "Season 1",
        dateRanges: [],
    });

    const { user } = useAuth();
    const [errors, setErrors] = useState({});

    const handleDateRangeAdd = () => {
        setSaison((prevState) => ({
            ...prevState,
            dateRanges: [
                ...prevState.dateRanges,
                {
                    dateFrom: '',
                    dateTo: ''
                }
            ],
        }));
    };

    const handleDateRangeRemove = (e) => {
        let index = parseInt((e.target.dataset && e.target.dataset.index && e.target.dataset.index) || (e.target.parentElement && e.target.parentElement.dataset && e.target.parentElement.dataset.index));
        let idx = -1;
        for (let d=0; d < saison.dateRanges.length; d++) {
            if (!saison.dateRanges[d].hasOwnProperty('id')) {
                if (index === 0) {
                    idx = d;
                    break;
                }
                index--;
            }
        }
        if (idx > -1) {
            setSaison((prevState) => {
                const newDateRanges = [
                    ...prevState.dateRanges
                ];
                newDateRanges.splice(idx, 1);
                return {
                    ...prevState,
                    dateRanges: newDateRanges
                };
            });
        }
    };

    const openSeasonModal = () => {
        setIsSeasonModalOpen(true);
    };

    const closeSeasonModal = () => {
        setIsSeasonModalOpen(false);
    };

    const validate = () => {
        let hasErrors = false;
        let errors = {};
        let year = calendar.year.toString();
        let dateRanges = saison.dateRanges;
        let dateRangesLength = dateRanges.length;

        if (isEmpty(saison.name)) {
            errors.name = 'Es muss ein Saisonname angegeben werden.';
            hasErrors = true;
        }

        if (saison.dateRanges.length === 0) {
            errors.dateRanges = 'Es muss mindestens ein Zeitraum ausgewählt werden.';
            hasErrors = true;
        }

        //check if date range year is calendar.year
        for (let i = 0; i < dateRangesLength; i++) {
            let dateFrom = dateRanges[i].dateFrom;
            let dateTo = dateRanges[i].dateTo;
            if (dateFrom && dateTo) {
                let dateFromYear = dateFrom.split('-')[0];
                let dateToYear = dateTo.split('-')[0];
                if (dateFromYear !== year || dateToYear !== year) {
                    errors.dateRangeYear = 'Es muss das gleiche Jahr wie der Kalender sein.';
                    hasErrors = true;
                }
            }
        }

        //check if date ranges overlap
        for (let i = 0; i < dateRangesLength; i++) {
            let dateFrom = dateRanges[i].dateFrom;
            let dateTo = dateRanges[i].dateTo;
            if (dateFrom && dateTo) {
                for (let j = i + 1; j < dateRangesLength; j++) {
                    let dateFrom2 = dateRanges[j].dateFrom;
                    let dateTo2 = dateRanges[j].dateTo;
                    if (dateFrom2 && dateTo2) {
                        if ((dateFrom <= dateFrom2 && dateFrom2 <= dateTo) || (dateFrom <= dateTo2 && dateTo2 <= dateTo) || (dateFrom2 <= dateFrom && dateFrom <= dateTo2) || (dateFrom2 <= dateTo && dateTo <= dateTo2)) {
                            errors.dateRange = 'Es gibt überlappende Zeiträume.';
                            hasErrors = true;
                        }
                    }
                }
            }
        }

        //check if date ranges overlap with existing seasons
        for (let i = 0; i < saisons.length; i++) {
            let existingDateRanges = saisons[i].dateRanges;
            for (let j = 0; j < dateRangesLength; j++) {
                let dateFrom = dateRanges[j].dateFrom;
                let dateTo = dateRanges[j].dateTo;
                if (dateFrom && dateTo) {
                    for (let k = 0; k < existingDateRanges.length; k++) {
                        let dateFrom2 = existingDateRanges[k].dateFrom;
                        let dateTo2 = existingDateRanges[k].dateTo;
                        if (dateFrom2 && dateTo2) {
                            if ((dateFrom <= dateFrom2 && dateFrom2 <= dateTo) || (dateFrom <= dateTo2 && dateTo2 <= dateTo) || (dateFrom2 <= dateFrom && dateFrom <= dateTo2) || (dateFrom2 <= dateTo && dateTo <= dateTo2)) {
                                errors.dateRangeOtherSaison = 'Es gibt überlappende Zeiträume mit einer anderen Saison.';
                                hasErrors = true;
                            }
                        }
                    }
                }
            }
        }

        //check that in date range dateFrom is before dateTo
        for (let i = 0; i < dateRangesLength; i++) {
            let dateFrom = dateRanges[i].dateFrom;
            let dateTo = dateRanges[i].dateTo;
            if (dateFrom && dateTo) {
                if (dateFrom >= dateTo) {
                    errors.dateRangeStart = 'Das Startdatum muss vor dem Enddatum liegen.';
                    hasErrors = true;
                }
            }
        }

        setErrors(errors);
        return !hasErrors;
    };


    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                await SeasonService.postSeason(calendarId, user, saison);
                setIsSeasonModalOpen(false);
                setSaison(
                    {
                        "name": "Season 1",
                        "dateRanges": [],
                    }
                );
                onSubmit();
            } catch (err) {
                setErrors(err.response.data.errors);
            }
        }
        return false;
    };

    return(
        <>
            <span onClick={openSeasonModal} className='btn btn-success'>Neue Saison erstellen</span>
            <div
                aria-hidden={!isSeasonModalOpen}
                className={`modal fade ${isSeasonModalOpen ? 'show' : ''}`}
                style={{ display: isSeasonModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
            >
                <div className='modal-dialog modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title' id='myModalLabel'>
                                Neue Saison erstellen
                            </h5>
                            <button
                                aria-label='Close'
                                className='btn-close'
                                onClick={closeSeasonModal}
                                type='button'
                            />
                        </div>
                        <div className='modal-body'>
                            <div className='row mb-2'>
                                <div className="text-muted fw-medium mb-2 required">Saisonname</div>
                                <div>
                                    <input
                                        className='form-control'
                                        name='seasonname'
                                        onChange={(e) => {
                                            setSaison((prevState) => ({...prevState, name: e.target.value}));
                                        }}
                                        required={true}
                                        type='text'
                                        value={saison.name}
                                    />
                                </div>
                            </div>
                            <div className='row mb-2'>
                                <div className="text-muted fw-medium mb-2">Welche Zeiträume hat die Saison?
                                    <span className='btn btn-success m-lg-2' onClick={handleDateRangeAdd}>+</span>
                                </div>
                                {saison.dateRanges.length === 0 ? (
                                    <div className='row ps-5'>
                                        Noch keine Zeiträume ausgewählt
                                    </div>
                                ) : (saison.dateRanges.filter((range) => !range.hasOwnProperty('id')).map((dateRange, index) => (
                                    <div className='row ps-5' key={index}>
                                        <div className='col-xl-12 text-muted fw-medium'>
                                            {dateRange.name || `Zeitraum ${index + 1}`}
                                        </div>
                                        <div className='col-xl-5'>
                                            <input
                                                className='form-control'
                                                onChange={(e) => {
                                                    // change dateFrom
                                                    const newDateFrom = e.target.value;
                                                    setSaison((prevState) => {
                                                        const newDateRanges = [...prevState.dateRanges];
                                                        newDateRanges[index].dateFrom = newDateFrom;
                                                        return {...prevState, dateRanges: newDateRanges};
                                                    });
                                                }}
                                                type='date'
                                                value={dateRange.dateFrom}
                                            />
                                        </div>
                                        <div className='col-xl-5'>
                                            <input
                                                className='form-control'
                                                onChange={(e) => {
                                                    // change dateTo
                                                    const newDateTo = e.target.value;
                                                    setSaison((prevState) => {
                                                        const newDateRanges = [...prevState.dateRanges];
                                                        newDateRanges[index].dateTo = newDateTo;
                                                        return {...prevState, dateRanges: newDateRanges};
                                                    });
                                                }}
                                                type='date'
                                                value={dateRange.dateTo}
                                            />
                                        </div>
                                        <div className='col-xl-2'>
                                            <span
                                                className='text-danger btn-icon btn-sm'
                                                data-index={index}
                                                type='button'
                                                onClick={handleDateRangeRemove}
                                            >
                                                <RiCloseLine className='ri-more-fill align-bottom'/>
                                            </span>
                                        </div>
                                    </div>
                                )))}
                            </div>
                            <div className='row mb-2'>
                                <div className='row ps-4'>
                                    <div
                                        className="col-xl-12 text-danger">{errors.hasOwnProperty('dateRanges') && saison.dateRanges.length === 0 && (
                                        <div className="text-danger">Es muss mindestens ein Zeitraum ausgewählt
                                            werden.</div>
                                    )}</div>
                                </div>
                                <div className='row ps-4'>
                                    <div
                                        className="col-xl-12 text-danger">{errors.hasOwnProperty('dateRangeYear') && (
                                        <div className="text-danger">
                                            Zeiträume müssen im gleichen Jahr wie der Kalender sein.
                                        </div>
                                    )}</div>
                                </div>
                                <div className="col-xl-12 text-danger">{errors.dateRange && (
                                    <div className="text-danger">Es gibt überlappende Zeiträume.</div>
                                )}</div>
                                <div className="col-xl-12 text-danger">{errors.dateRangeOtherSaison && (
                                    <div className="text-danger">
                                        Es gibt überlappende Zeiträume mit einer anderen Saison.
                                    </div>
                                )}</div>
                                <div className="col-xl-12 text-danger">{errors.dateRangeStart && (
                                    <div className="text-danger">
                                        Das Startdatum muss vor dem Enddatum liegen.
                                    </div>
                                )}</div>
                            </div>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-light' onClick={closeSeasonModal}>
                                Abbrechen
                            </button>
                            <button type='submit' className='btn btn-success' onClick={submit}>
                                Hinzufügen
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddSaisonModal;
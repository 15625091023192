import React, { useRef } from 'react';
import {RiAddLine} from 'react-icons/ri';
import $ from 'jquery';
import AddPlaceModal from "./AddPlaceModal";
import AddCategoryModal from "./AddCategoryModal";
import AddObjectModal from "./AddObjectModal";

const ObjectManagementMenu = () => {

    const click = () => {
        if (dropdownRef.current.classList.contains('show')) {
            dropdownRef.current.classList.remove('show');
            $(dropdownContentRef.current).slideUp();
        } else {
            dropdownRef.current.classList.add('show');
            $(dropdownContentRef.current).slideDown();
        }
    };

    const dropdownRef = useRef();
    const dropdownContentRef = useRef();

    return (
        <div className="dropdown">
            <span
                className='btn btn-success w-sm create-folder-modal flex-shrink-0'
                data-bs-toggle='modal'
                data-bs-target='#createFolderModal'
                ref={dropdownRef}
                onClick={click}
            >
                <RiAddLine className='ri-add-line me-1' /> Hinzufügen
            </span>
            <ul ref={dropdownContentRef} className="dropdown-menu dropdown-menu-end" style={{right: "0", zIndex: "1060"}}>
                <li>
                    <AddPlaceModal onClickClose={click}/>
                </li>
                <li>
                    <AddCategoryModal onClickClose={click}/>
                </li>
                <li>
                    <AddObjectModal onClickClose={click}/>
                </li>
            </ul>
        </div>
    );
};

export default ObjectManagementMenu;

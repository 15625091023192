import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import defaultAvatar from '../assets/img/default-avatar.webp';
import { RiAccountCircleLine, RiLogoutBoxLine, RiOrganizationChart } from 'react-icons/ri';
import { useOrganization } from '../hooks/useOrganization';
import { useAuth } from '../hooks/useAuth';

const ProfileDropdown = () => {
    /**
     * Functions
     */

    const click = () => {
        if (dropdownRef.current.classList.contains('show')) {
            dropdownRef.current.classList.remove('show');
            $(dropdownContentRef.current).slideUp();
        } else {
            dropdownRef.current.classList.add('show');
            $(dropdownContentRef.current).slideDown();
        }
    };

    const dropdownRef = useRef();
    const dropdownContentRef = useRef();

    const { user, signout } = useAuth();
    const { organization } = useOrganization();

    return (
        <div className='dropdown ms-sm-3 header-item topbar-user'>
            <div
                className='btn'
                id='page-header-user-dropdown'
                data-bs-toggle='dropdown'
                ref={dropdownRef}
                onClick={click}
                aria-haspopup='true'
                aria-expanded='true'
            >
                <span className='d-flex align-items-center'>
                    <img className='rounded-circle header-profile-user' src={defaultAvatar} alt='Header Avatar' />

                    <span className='text-start ms-xl-2'>
                        <span className='d-none d-xl-inline-block ms-1 fw-medium user-name-text'>{user.email}</span>
                        <span className='d-none d-xl-block ms-1 fs-12 user-name-sub-text'>
                            {organization === null ? 'Keine Organisation' : organization.name}
                        </span>
                    </span>
                </span>
            </div>
            <div
                className='dropdown-menu dropdown-menu-end'
                ref={dropdownContentRef}
                style={{
                    position: 'absolute',
                    inset: ' 0px 0px auto auto',
                    margin: '0px',
                    transform: 'translate(0px, 65px)',
                }}
                data-popper-placement='bottom-end'
            >
                <Link className='dropdown-item' to='/profil' onClick={click}>
                    <RiAccountCircleLine className='text-muted fs-16 align-middle me-1' />
                    <span className='align-middle'>Profil</span>
                </Link>
                <Link className='dropdown-item' to='/organisationen' onClick={click}>
                    <RiOrganizationChart className='text-muted fs-16 align-middle me-1' />
                    <span className='align-middle'>Organisationen</span>
                </Link>
                <Link className='dropdown-item' to='/login' onClick={signout}>
                    <RiLogoutBoxLine className=' text-muted fs-16 align-middle me-1' />
                    <span className='align-middle'>Logout</span>
                </Link>
            </div>
        </div>
    );
};

export default ProfileDropdown;

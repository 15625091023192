import axios from 'axios';
import config from '../config.json';

class AuthService {
    static async login(state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/auth/login`, state);
    }

    static async signup(state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/auth/signup`, state);
    }

    static async sendPasswordReset(state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/auth/passwordreset`, state);
    }

    static async resetPassword(token, state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/auth/set-password?token=${token}`, state);
    }
}

export default AuthService;

import React, { useEffect, useState } from 'react';
import Loader from '../../../components/Loader';
import ObjectManagementContent from '../ObjectManagementContent';
import { useLocation, useParams } from 'react-router-dom';
import CategoryService from '../../../services/CategoryService';
import { useAuth } from '../../../hooks/useAuth';
import CategoryCard from './CategoryCard';
import AlertCard from '../../../components/AlertCard';
import CategoryManagementObjects from './CategoryManagementObjects';

const CategoryManagementContent = () => {
    const [loading, setLoading] = useState(true);
    const { placeId, categoryId } = useParams();
    const [categories, setCategories] = useState([]);

    let location = useLocation();

    const { user } = useAuth();

    const fetchCategories = async () => {
        try {
            const response = await CategoryService.getCategories(placeId, user);
            const filteredCategories = response.data.filter((item) => {
                if (!item.channels || item.channels.length === 0) {
                    return true;
                }
                return !item.channels[0].channelDataMap.deleted;
            });
            setCategories(filteredCategories);
            setLoading(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    useEffect(() => {
        fetchCategories();
    }, [location]);

    useEffect(() => {
        fetchCategories();
    }, [placeId]);

    const onSubmit = async (e) => {
        fetchCategories();
    }

    return (
        <ObjectManagementContent title='Objektkategorien'>
            {loading ? (
                <Loader card={false} />
            ) : (
                <div className='row' id='folderlist-data'>
                    {categories.length === 0 && <AlertCard>In diesem Platz gibt es noch keine Objektkategorien.</AlertCard>}
                    {categories.map((item, idx) => (
                        <CategoryCard onSubmit={onSubmit} item={item} key={idx} />
                    ))}
                </div>
            )}
            {categoryId && <CategoryManagementObjects />}
        </ObjectManagementContent>
    );
};

export default CategoryManagementContent;

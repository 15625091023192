import React from "react";
import {RiBookLine} from 'react-icons/ri';
import {Link} from "react-router-dom";

const CategoryPropertiesNavbar = ({handleSidebarChange, place, item}) => {

    return (
        <div className='card olap-card'>
            <div className='card-header d-none'>
                <div className='dataTables_wrapper'>
                    <div id="model-datatables_filter" className="dataTables_filter">
                        <label>
                            Search:
                            <input type="search" className="form-control form-control-sm" placeholder="" aria-controls="model-datatables" />
                        </label>
                    </div>
                </div>
            </div>
            <div className='card-body'>
                <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                    <div className="row">
                        <div className="col-7 pe-2 ps-2 dataTables_length">
                            <div className="row olap-row">
                                <div className="col-auto pe-2 ps-2 dataTables_length">
                                    <div className="d-none d-md-block">
                                        <span onClick={handleSidebarChange} className="btn-olap btn btn-success create-folder-modal flex-shrink-0">
                                            <RiBookLine />
                                        </span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center pe-2 ps-2 col-auto dataTables_length">
                                    <ol className="breadcrumb m-0">
                                        <li className="breadcrumb-item d-flex">
                                            <h4 className="m-0">
                                                <Link to={`/olap/place/${place.id}`}>{place.name}</Link>
                                            </h4>
                                        </li>
                                        <li className="breadcrumb-item d-flex">
                                            <h4 className="m-0">
                                                <Link to="#">{item.name} (Objektkategorie-Ansicht)</Link>
                                            </h4>
                                        </li>
                                    </ol>
                                </div>
                            </div>
                        </div>
                        <div className="col-5 justify-content-end pe-3 ps-2 dataTables_length d-flex">
                            <Link to={`/olap/place/${place.id}/category/${item.id}/season`}>
                                <button className="ms-2 btn-olap btn btn-success">Zur Saison-Ansicht</button>
                            </Link>
                            <Link to={`#`}>
                                <button className="ms-2 btn-olap btn btn-success btn-toggled">Zur Objektkategorie-Ansicht</button>
                            </Link>
                            <Link to={`#`} className={'d-none'}>
                                <button className="ms-2 btn-olap btn btn-success">Zur Tages-Ansicht</button>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default CategoryPropertiesNavbar;
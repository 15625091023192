import React, {useEffect, useRef, useState} from "react";
import {Link, useLocation} from "react-router-dom";
import {RiArrowDownSLine, RiUserLocationLine} from "react-icons/ri";
import {useAuth} from "../../hooks/useAuth";
import CategoryService from "../../services/CategoryService";
import ObjectsAndSeasonsDnDCategories from "./ObjectsAndSeasonsDnDCategories";
import Loader from "../../components/Loader";
import $ from "jquery";

const ObjectsAndSeasonsDnDPlaces = ({index, item}) => {
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);
    let location = useLocation();
    const { user } = useAuth();

    const linkRef = useRef();
    const collapseRef = useRef();

    const fetchCategories = async () => {
        const response = await CategoryService.getCategories(item.id, user);
        const filteredCategories = response.data.filter((item) => {
            if (!item.channels || item.channels.length === 0) {
                return true;
            }
            return !item.channels[0].channelDataMap.deleted;
        });
        setCategories(filteredCategories);
        setLoading(false);
    };

    useEffect(() => {
        fetchCategories();
    }, [location]);

    const newFetchCategories = () => {
        fetchCategories();
    }

    const click = (e) => {
        if (linkRef.current.classList.contains('active')) {
            linkRef.current.classList.remove('active');
            linkRef.current.setAttribute('aria-expanded', 'false');
            $(collapseRef.current).slideUp();
        } else {
            linkRef.current.classList.add('active');
            linkRef.current.setAttribute('aria-expanded', 'true');
            $(collapseRef.current).slideDown();
        }
    };

    return (
        <ul className="col-lg-12 list-unstyled file-manager-menu height-unset">
            <li>
                <Link to="">
                    <RiUserLocationLine className="me-2"/>
                    <span>
                        {item.name} (Platz)
                    </span>
                </Link>
                <span
                    className="ms-2 active olap-sidebar-button"
                    aria-expanded='false'
                    ref={linkRef}
                    onClick={click}
                >
                    <span className="icon"><RiArrowDownSLine /></span>
                </span>
                <div className='menu-dropdown' ref={collapseRef}>
                    {loading ? (
                        <Loader card={false} />
                    ) : (
                        <ul className='sub-menu list-unstyled' id='folderlist-data'>
                            {categories.map((child, idx) => (
                                <ObjectsAndSeasonsDnDCategories newFetchCategories={newFetchCategories} parent={item} item={child} key={idx} />
                            ))}
                        </ul>
                    )}

                </div>
            </li>
        </ul>
    )
}

export default ObjectsAndSeasonsDnDPlaces;
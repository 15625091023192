import React from 'react';

const Footer = () => {
    return (
        <footer className='footer'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-sm-6'>2024 © Camppartner.</div>
                    <div className='col-sm-6'>
                        <div className='text-sm-end d-none d-sm-block'>Design &amp; Entwicklung von orcas v1.0.0</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;

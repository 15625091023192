import React, {useEffect, useState} from 'react';
import { useAuth } from '../../../hooks/useAuth';
import PlaceService from "../../../services/PlaceService";

const PlaceEditInput = ({ tag, place, onSubmit }) => {
    const { user } = useAuth();

    const { id, ...restFullState } = place;
    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState,
    });

    useEffect(() => {
        const { id, ...restFullState } = place;
        setFullStateEdit({
            ...restFullState,
        });
    }, [place]);

    const change = (e) => {
        setFullStateEdit((prevState) => ({
            ...prevState,
            [tag]: e.target.value,
        }));
    };

    const saveChanges = async () => {
        try {
            await PlaceService.putPlace(place.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    return (
        <div className='text olap-input'>
            <input
                type='text'
                className='form-control form-control-edit text-center'
                value={fullStateEdit[tag]}
                onChange={change}
                onBlur={saveChanges}
            />
        </div>
    );
};

export default PlaceEditInput;
import React from 'react';
import ProfileDropdown from "../parts/ProfileDropdown";
import NotificationButton from "../parts/NotificationButton";

const TopBarModules = () => {
    return (
        <div className='d-flex align-items-center'>
            <NotificationButton />
            <ProfileDropdown />
        </div>
    );
};

export default TopBarModules;

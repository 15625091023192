import React, {useState} from 'react';
import OlapList from "../OlapList";
import PlaceDescriptionModal from "./PlaceDescriptionModal";
import PlaceCategoriesModal from "./PlaceCategoriesModal";
import PlaceCategoriesNumbersModal from "./PlaceCategoriesNumbersModal";
import OlapWiki from "../Management/OlapWiki";
import PlaceMediaUploadModal from "./PlaceMediaUploadModal";
import PlaceEditInput from "./PlaceEditInput";
import SimpleBar from "simplebar-react";

const PlacePropertiesTable = ({sidebarOpen, handleSidebarChange, item, onSubmit}) => {
    const [selectedOption, setSelectedOption] = useState("Beschreibungstexte");
    const [hoveredColumn, setHoveredColumn] = useState(null);


    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    return (
        <div className={`row`}>
            <div className={`col-sm-2 ${sidebarOpen ? '' : 'd-none'}`}>
                <div className='card'>
                    <div className='card-body'>
                        <SimpleBar>
                            <OlapList handleSidebarChange={handleSidebarChange}/>
                        </SimpleBar>
                    </div>
                </div>
            </div>
            <div className={`${sidebarOpen ? 'col-sm-10' : 'col-sm-12'}`}>
                <div className="card">
                    <div className="card-body">
                        <div className="olap-table">
                            <table id="alternative-pagination" className={`table nowrap dt-responsive align-middle olap-table-hover table-bordered dataTable no-footer dtr-inline`}>
                                <thead>
                                    <tr>
                                        <th className="dtr-control">
                                            <div className="dataTables_length">
                                                <select name="model-datatables_length" aria-controls="model-datatables" className="form-select form-select-sm" onChange={handleOptionChange} value={selectedOption}>
                                                    <option>Beschreibungstexte</option>
                                                    <option>Eigenschaften</option>
                                                    <option>Kategorien</option>
                                                    <option>Medien</option>
                                                </select>
                                            </div>
                                        </th>
                                        <th
                                            className={hoveredColumn === 1 ? 'hovered' : ''}
                                        >
                                            <div className="table-month">
                                                <span>{item.name}</span>
                                            </div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                <tr className={`textRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Straße
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceEditInput tag={"street"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Postleitzahl
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceEditInput tag={"postalCode"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Stadt
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceEditInput tag={"city"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Bundesland
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceEditInput tag={"state"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Land
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceEditInput tag={"country"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Postfach
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceEditInput tag={"postBox"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Platzbeschreibung
                                            <OlapWiki tag={'objektbeschreibung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceDescriptionModal tag={"infoDescription"} place={item}
                                                               onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Beschreibung der Anfahrt
                                            <OlapWiki tag={'beschreibung-der-anfahrt'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceDescriptionModal tag={"approachRoute"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Beschreibung der Umgebung
                                            <OlapWiki tag={'beschreibung-der-umgebung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceDescriptionModal tag={"infoEnvironment"} place={item}
                                                               onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Information zur Kaution
                                            <OlapWiki tag={'information-zur-kaution'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceDescriptionModal tag={"infoDeposit"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Information zur Anreise
                                            <OlapWiki tag={'information-zur-anreise'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceDescriptionModal tag={"infoArrival"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Information zur Abreise
                                            <OlapWiki tag={'information-zur-abreise'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceDescriptionModal tag={"infoDeparture"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Information zu Haustieren
                                            <OlapWiki tag={'information-zu-haustieren'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceDescriptionModal tag={"infoPets "} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Objekttyp
                                            <OlapWiki tag={'objekttyp'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesModal tag={"objectTypes"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Zielgruppe
                                            <OlapWiki tag={'zielgruppe'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesModal tag={"targetGroups"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Attribute
                                            <OlapWiki tag={'attribute'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesModal tag={"attributes"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Gastronomie
                                            <OlapWiki tag={'gastronomie'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn()}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesModal tag={"foodSupplies"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Outdoor Equipment
                                            <OlapWiki tag={'outdoor-equipment'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesModal tag={"outdoorEquipments"} place={item}
                                                              onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Ausstattung
                                            <OlapWiki tag={'ausstattung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesModal tag={"facilities"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Geräte
                                            <OlapWiki tag={'ger%C3%A4te'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesModal tag={"technics"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Dienstleistungen
                                            <OlapWiki tag={'dienstleistungen'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesModal tag={"serviceOffers"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Freizeitangebote
                                            <OlapWiki tag={'freizeitangebote'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesModal tag={"leisureOffers"} place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Umweltmaßnahmen
                                            <OlapWiki tag={'umweltmaßnahmen'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesModal tag={"environmentalMeasures"} place={item}
                                                              onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Entfernungen
                                            <OlapWiki tag={'entfernungen'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceCategoriesNumbersModal tag={"distances"} place={item}
                                                                     onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`mediaRow ${selectedOption === "Medien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Bilder und Videos
                                            <OlapWiki tag={'bilder'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <PlaceMediaUploadModal place={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PlacePropertiesTable;
import React, { useState, useContext, createContext } from 'react';

const organizationContext = createContext();

export function ProvideOrganization({ children }) {
    const organization = useProvideOrganization();
    return <organizationContext.Provider value={organization}>{children}</organizationContext.Provider>;
}

export const useOrganization = () => {
    return useContext(organizationContext);
};

function useProvideOrganization() {
    const [organization, setOrganization] = useState(null);

    return {
        organization,
        setOrganization,
    };
}

import React from 'react';
import { RiQuestionLine } from 'react-icons/ri';

const OlapTooltip = ({Text}) => {
    return (
        <span className={'tooltipCp'}>
            <RiQuestionLine />
            <span className={'tooltiptextCp'}>{Text}</span>
        </span>
    );
};

export default OlapTooltip;

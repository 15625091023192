import axios from 'axios';
import config from '../config.json';
import CategoryService from './CategoryService';
import PlaceService from './PlaceService';

class ObjectService {
    static async getObjects(catId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/categories/${catId}/objects`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async getObject(objectId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/objects/${objectId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putObject(objectId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/objects/${objectId}`, state,{
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteObject(objectId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/objects/${objectId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postObject(placeId, categoryId, organization, user, state) {
        let place = 0;
        if (!placeId) {
            const response = await PlaceService.postPlace(organization.id, user);
            place = response.data.id;
        } else {
            place = placeId;
        }

        let redirects = {};
        if (!categoryId) {
            const response = await CategoryService.postCategory(place, user, organization);
            redirects = response;
        } else {
            redirects = {
                placeId: place,
                categoryId: categoryId,
            };
        }

        let postObject = [];

        for (let i = parseInt(state.begin); i < parseInt(state.begin) + parseInt(state.objectCount); i++) {
            postObject.push({
                name: `${state.prefix ? state.prefix + ' ' : ''}${i}${state.suffix ? ' ' + state.suffix : ''}`,
                bookingProperties: {blocked: false},
                bookable: false
            });
        }


        await axios.post(`${config.API_BASE_URI}/api/v1/categories/${redirects.categoryId}/objects`, postObject, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });

        return redirects;
    }
}

export default ObjectService;

import React from 'react';
import { RiAirplayFill, RiEditBoxLine } from 'react-icons/ri';

const ProfileContentTabs = () => {
    return (
        <div className="d-flex profile-wrapper">
            <ul className="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                <li className="nav-item" role="presentation">
                    <a className="nav-link fs-14 active" data-bs-toggle="tab"
                       href="#overview-tab" role="tab" aria-selected="true">
                        <RiAirplayFill className="ri-airplay-fill d-inline-block d-md-none" />
                        <span className="d-none d-md-inline-block">Überblick</span>
                    </a>
                </li>
            </ul>
            <div className="flex-shrink-0">
                <a href="pages-profile-settings.html" className="btn btn-success d-none">
                    <RiEditBoxLine className="ri-edit-box-line" /> Profil bearbeiten</a>
            </div>
        </div>
    );
};

export default ProfileContentTabs;
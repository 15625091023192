import axios from 'axios';
import config from '../config.json';

class SyncService {
    static async sync(apikey, state) {
        return axios.post(`${config.API_SYNC_URI}/api/v1/sync`, state, {
            headers: {
                'X-Api-Key': apikey,
            }
        });
    }
}

export default SyncService;

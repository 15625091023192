import React from 'react';
import {RiCloseLine, RiCheckLine} from "react-icons/ri";
import {Link} from 'react-router-dom';

const InvitationContent = () => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <div>
                    <div className="tab-content pt-4 text-muted">
                        <div className="tab-pane active" id="overview-tab" role="tabpanel">
                            <div className="row">
                                <div className="col-xxl-6">
                                    <div className="card">
                                        <div className="card-body">
                                            <h5 className="card-title mb-3">Einladung von Hilton!</h5>
                                            <p>
                                                Wir freuen uns, Ihnen mitteilen zu können, dass Sie eine Einladung von der Organisation
                                                Hilton erhalten haben. Diese Einladung wurde von Administrator "hilton@orcas.de" ausgestellt.
                                            </p>
                                            <p>
                                                Bitte überprüfen Sie die Einladung sorgfältig. Sollten Sie erkennen, dass diese Einladung
                                                nicht für Sie bestimmt ist oder Unstimmigkeiten auftreten, zögern Sie bitte nicht, sie
                                                abzulehnen. Hierfür klicken Sie einfach auf den "Ablehnen"-Button unten.
                                            </p>
                                            <div className="row">
                                                <div className="col-lg-6 flex-shrink-0">
                                                    <Link to="/profil" className="btn btn-danger">
                                                        <RiCloseLine className="ri-edit-box-line" /> Ablehnen
                                                    </Link>
                                                </div>
                                                <div className="col-lg-6 flex-shrink-0 mb-2 justify-content-end d-flex">
                                                    <Link to="/" className="btn btn-success">
                                                        <RiCheckLine className="ri-edit-box-line" /> Annehmen
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InvitationContent;
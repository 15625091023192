import React from 'react';
import { RiAlertLine } from 'react-icons/ri';

const AlertCard = ({ children }) => {
    return (
        <div
            className='alert alert-warning alert-dismissible bg-warning text-white alert-label-icon fade show'
            role='alert'
        >
            <RiAlertLine className='ri-alert-line label-icon p-1' />
            {children}
        </div>
    );
};

export default AlertCard;

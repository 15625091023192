import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { isEmpty } from '../utils/validation';
import LoginFooter from "../parts/LoginFooter";

const PasswordResetPage = () => {
    const { confirmPasswordReset } = useAuth();
    const {token } = useParams();

    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        password: '',
        passwordConfirm: ''
    });

    const [errors, setErrors] = useState({
        password: false,
        passwordConfirm: false
    });

    const [invalid, setInvalid] = useState(false);

    const validate = () => {
        let hasErrors = false;
        let newErrors = {};

        for (const key in state) {
            if (isEmpty(state[key])) {
                newErrors[key] = `${key} ist notwendig`;
                hasErrors = true;
            }
        }

        if (state.password !== state.passwordConfirm) {
            newErrors.passwordConfirm = 'Passwort muss gleich sein';
            hasErrors = true;
        }

        if (hasErrors) {
            setErrors(newErrors);
        }

        return !hasErrors;
    };

    useEffect(() => {
        if (!token) {
            setErrors('Invalid Token.');
        }
    }, [location.search]);

    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                await confirmPasswordReset(token, state);
                navigate('/login', { replace: true, state: { passwordChanged: true }  });
            } catch (err) {
                setInvalid(true);
            }
        }
    };

    const change = (e) => {
        if (isEmpty(e.target.value)) {
            setErrors({ ...errors, [e.target.name]: `${e.target.name} ist notwendig` });
        } else {
            setErrors({ ...errors, [e.target.name]: false });
        }
        setState({ ...state, [e.target.name]: e.target.value });
    };

    return (
        <div className='auth-page-wrapper pt-5'>
            <div className='auth-one-bg-position auth-one-bg' id='auth-particles'>
                <div className='bg-overlay'></div>

                <div className='shape'>
                    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1440 120'>
                        <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z'></path>
                    </svg>
                </div>
            </div>
            <div className='auth-page-content'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8 col-lg-6 col-xl-6'>
                            <div className='card mt-4'>
                                <div className='card-body p-4'>
                                    <div className='text-center mt-2'>
                                        <h5 className='text-primary'>Willkommen zurück!</h5>
                                        <p className='text-primary'>Das Property Management System zur Verwaltung der
                                            Online Buchbarkeit.</p>
                                        <p className='text-muted'>Jetzt Passwort neu setzen!</p>
                                    </div>
                                    {invalid && <span className='text-danger'>Ein Fehler ist aufgetreten!</span>}
                                    <div className='p-2 mt-4'>
                                        <form onSubmit={submit}>
                                            <div className='mb-3'>
                                                <label htmlFor='email' className='form-label mt-3 d-block'>
                                                    Passwort
                                                </label>
                                                <input
                                                    type='password'
                                                    className={`form-control ${errors.password && 'is-invalid'}`}
                                                    name='password'
                                                    placeholder='Passwort eingeben'
                                                    onChange={change}
                                                    value={state.password}
                                                />
                                                <div className='invalid-feedback'>{errors.password}</div>
                                                <label htmlFor='email' className='form-label mt-3'>
                                                    Passwort bestätigen
                                                </label>
                                                <input
                                                    type='password'
                                                    className={`form-control ${errors.passwordConfirm && 'is-invalid'}`}
                                                    name='passwordConfirm'
                                                    placeholder='Passwort bestätigen'
                                                    onChange={change}
                                                    value={state.passwordConfirm}
                                                />
                                                <div className='invalid-feedback'>{errors.passwordConfirm}</div>
                                            </div>
                                            <div className='mt-4'>
                                                <button className='btn btn-success w-100' type='submit'>
                                                    Passwort speichern
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4 text-center'>
                                <p className='mb-0'>
                                    Sie wollen sich einloggen?
                                    <a
                                        href='/login'
                                        className='ms-1 fw-semibold text-primary text-decoration-underline'
                                    >
                                        Login
                                    </a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoginFooter/>
        </div>
);
};

export default PasswordResetPage;

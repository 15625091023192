import React, {Fragment, useEffect, useRef, useState} from 'react';
import { RiArrowDownSLine  } from 'react-icons/ri';
import $ from "jquery";
import { useParams, useLocation } from 'react-router-dom';
import ServiceModal from "./ServiceModal";
import AddServiceModal from "./AddServiceModal";
import objectService from "../../services/ObjectService";
import categoryService from "../../services/CategoryService";
import {useAuth} from "../../hooks/useAuth";
const OlapOrganizationServices = ({services, onSubmit}) => {
    const linkRef = useRef();
    const collapseRef = useRef();

    const click = (e) => {
        if (linkRef.current.classList.contains('active')) {
            linkRef.current.classList.remove('active');
            linkRef.current.setAttribute('aria-expanded', 'false');
            $(collapseRef.current).slideUp();
        } else {
            linkRef.current.classList.add('active');
            linkRef.current.setAttribute('aria-expanded', 'true');
            $(collapseRef.current).slideDown();
        }
    };

    const { user } = useAuth();

    const { placeId, categoryId, objectId } = useParams();
    const [seasonIds, setSeasonIds] = useState([]);
    const [sortServices, setSortServices] = useState([]);
    const location = useLocation();
    const path = location.pathname;

    //sort services by name
    useEffect(() => {
        if (services.length > 0) {
            const sortedServices = services.sort((a, b) => {
                if (a.name[0].text < b.name[0].text) {
                    return -1;
                }
                if (a.name[0].text > b.name[0].text) {
                    return 1;
                }
                return 0;
            });
            setSortServices(sortedServices);
        }
    }, [services,location]); // eslint-disable-line react-hooks/exhaustive-deps


    const getSeasonIds = async () => {
        try {
            let ids = [];
            if (path.endsWith('season')) {
                if (objectId) {
                    const response = await objectService.getObject(objectId, user);
                    ids = response.data.bookingObjectSeasonProperties.map(season => season.season.id);
                } else if (categoryId) {
                    const response = await categoryService.getCategory(categoryId, user);
                    ids = response.data.bookingCategorySeasonProperties.map(season => season.season.id);
                }
            }
            setSeasonIds(ids);
        } catch (err) {
            console.log(err);
        }
    }

    useEffect(() => {
        getSeasonIds();
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Fragment>
            <div className='card olap-card'>
                <div className='card-header'>
                    Leistungsvorlagen der Organisation
                    <span className="ms-2 olap-sidebar-button cursor-pointer" aria-expanded='false' ref={linkRef} onClick={click}>
                        <span className="icon"><RiArrowDownSLine /></span>
                    </span>
                </div>
                <div className='card-body' ref={collapseRef}  style={{display: "none"}}>
                    <div className="dataTables_wrapper dt-bootstrap5 no-footer">
                        <div className="row">
                            <div className="col-12 pe-2 ps-2 dataTables_length olap-table">
                                <table id="alternative-pagination" className={`table nowrap dt-responsive align-middle olap-table-hover table-bordered dataTable no-footer dtr-inline`}>
                                    <tbody>
                                    {
                                        sortServices.map((service, serviceIndex) => (
                                            <tr key={service+serviceIndex}>
                                                <td className="dtr-control">
                                                    <div className="rowTitle">
                                                        {service.name[0] && service.name[0].text ? service.name[0].text : ''} (Vorlage)
                                                        <small className='text-muted'>
                                                            {service.channels.length > 0 && service.channels[0].channelType === "CPO" ? " (" + service.channels[0].channelDataMap.cpoId + " - " + service.channels[0].channelDataMap.name + ")" : ''}
                                                        </small>
                                                    </div>
                                                </td>
                                                <td>
                                                    <ServiceModal service={service} onSubmit={onSubmit}
                                                                  seasonIds={seasonIds}/>
                                                </td>
                                            </tr>
                                        ))
                                    }
                                    <tr>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                <AddServiceModal onSubmit={onSubmit}/>
                                            </div>
                                        </td>
                                        <td>

                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default OlapOrganizationServices;
import React, { useState, useContext, createContext } from 'react';
import axios from 'axios';
import AuthService from '../services/AuthService';

const authContext = createContext();

export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

export const useAuth = () => {
    return useContext(authContext);
};

function useProvideAuth() {
    const [user, setUser] = useState(null);

    const login = async (state) => {
        try {
            const response = await AuthService.login(state);
            setUser(response.data);
        } catch (err) {
            throw err;
        }
    };
    const signup = async (state) => {
        try {
            const response = await AuthService.signup(state);
        } catch (err) {
            throw err;
        }
    };

    const sendPasswordReset = async (state) => {
        try {
            const response = await AuthService.sendPasswordReset(state);
        } catch (err) {
            throw err;
        }
    };

    const confirmPasswordReset = async (token, state) => {
        try {
            const response = await AuthService.resetPassword(token, state);
        } catch (err) {
            throw err;
        }
    };

    const signout = () => {
        setUser(null);
    };

    return {
        user,
        login,
        signup,
        signout,
        sendPasswordReset,
        confirmPasswordReset,
    };
}

import React, {useEffect, useState} from 'react';
import OlapList from "../OlapList";
import CategoryEditInput from "./CategoryEditInput";
import CategoryTimeInput from "./CategoryTimeInput";
import CategoryDaysCheckboxes from "./CategoryDaysCheckboxes";
import CategoryDescriptionModal from "./CategoryDescriptionModal";
import CategoryCategoriesModal from "./CategoryCategoriesModal";
import CategoryCategoriesNumbersModal from "./CategoryCategoriesNumbersModal";
import {useOrganization} from "../../../hooks/useOrganization";
import {useAuth} from "../../../hooks/useAuth";
import {useLocation} from "react-router-dom";
import serviceService from "../../../services/ServiceService";
import CategoryServiceModal from "./CategoryServiceModal";
import OlapWiki from "../Management/OlapWiki";
import OlapServiceKatalog from "../OlapServiceKatalog";
import OlapOrganizationServices from "../OlapOrganizationServices";
import CategoryAddServiceModal from "./CategoryAddServiceModal";
import CategoryBookable from "./CategoryBookable";
import CategoryGeoLocation from "./CategoryGeoLocation";
import CategoryMediaUploadModal from "./CategoryMediaUploadModal";
import SimpleBar from "simplebar-react";

const CategoryPropertiesTable = ({sidebarOpen, handleSidebarChange, item, onSubmit}) => {
    const [services, setServices] = useState([]);
    const [selectedOption, setSelectedOption] = useState("Eigenschaften");
    const [hoveredColumn, setHoveredColumn] = useState(null);

    const [organizationServices, setOrganizationServices] = useState([]);
    const [categoryServices, setCategoryServices] = useState([]);

    const {organization} = useOrganization();
    const {user} = useAuth();
    let location = useLocation();

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const fetchOrganizationServices = async () => {
        const response = await serviceService.getOrganizationServices(organization.id, user);
        setOrganizationServices(response.data);
    }

    const fetchCategoryServices = async () => {
        const response = await serviceService.getCategoryServices(item.id, user);
        setCategoryServices(response.data);
    }

    const submitService = () => {
        fetchOrganizationServices();
        fetchCategoryServices();
    }

    useEffect(() => {
        fetchOrganizationServices();
        fetchCategoryServices();
    }, [location]);  // eslint-disable-line react-hooks/exhaustive-deps
    return (
        <div className={`row`}>
            <div className={`col-sm-2 ${sidebarOpen ? '' : 'd-none'}`}>
                <div className='card'>
                    <div className='card-body'>
                        <SimpleBar>
                            <OlapList handleSidebarChange={handleSidebarChange}/>
                        </SimpleBar>
                    </div>
                </div>
            </div>
            <div className={`${sidebarOpen ? 'col-sm-10' : 'col-sm-12'}`}>
                <div className="card">
                    <div className="card-body">
                        <div className="olap-table">
                            <table id="alternative-pagination" className={`table nowrap dt-responsive align-middle olap-table-hover table-bordered dataTable no-footer dtr-inline`}>
                                <thead>
                                    <tr>
                                        <th className="dtr-control">
                                            <div className="dataTables_length">
                                                <select name="model-datatables_length" aria-controls="model-datatables" className="form-select form-select-sm" onChange={handleOptionChange} value={selectedOption}>
                                                    <option>Eigenschaften</option>
                                                    <option>Beschreibungstexte</option>
                                                    <option>Kategorien</option>
                                                    <option>Medien</option>
                                                    <option>Leistungen</option>
                                                </select>
                                            </div>
                                        </th>
                                        <th
                                            className={hoveredColumn === 1 ? 'hovered' : ''}
                                        >
                                            <div className="table-month">
                                                <span>{item.name}</span>
                                            </div>
                                        </th>

                                    </tr>
                                </thead>
                                <tbody>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Buchbarkeit
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryBookable tag={"bookable"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Basispreis für Standardbelegung
                                            <OlapWiki tag={'basispreis-f%C3%BCr-standardbelegung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryEditInput tag={"price"} category={item} onSubmit={onSubmit}/>
                                    </td>

                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Mindestaufenthalt
                                            <OlapWiki tag={'mindestaufenthalt'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryEditInput tag={"minimumStay"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Buchungslücken füllen ab
                                            <OlapWiki tag={'buchungsl%C3%BCcken-f%C3%BCllen-ab'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryEditInput tag={"gapFill"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Karenztage (nach Buchung)
                                            <OlapWiki tag={'karenztage-nach-buchung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryEditInput tag={"daysBlockedAfterBooking"} category={item}
                                                           onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Standardbelegung
                                            <OlapWiki tag={'standardbelegung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryEditInput tag={"occupancyDefault"} category={item}
                                                           onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Maximale Belegung
                                            <OlapWiki tag={'maximalbelegung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryEditInput tag={"occupancyMax"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Davon Babys
                                            <OlapWiki tag={'davon-babys'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryEditInput tag={"babies"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Anreise von
                                            <OlapWiki tag={'anreise-von'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryTimeInput tag={"arrivalTimeFrom"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Anreise bis
                                            <OlapWiki tag={'anreise-bis'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryTimeInput tag={"arrivalTimeTo"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Abreise von
                                            <OlapWiki tag={'abreise-von'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryTimeInput tag={"departureTimeFrom"} category={item}
                                                           onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Abreise bis
                                            <OlapWiki tag={'abreise-bis'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryTimeInput tag={"departureTimeTo"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Ankunftstage
                                            <OlapWiki tag={'m%C3%B6gliche-anreisewochentage'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryDaysCheckboxes tag={"arrivalDays"} category={item}
                                                                onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`d-none eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Abreisetage
                                            <OlapWiki tag={'m%C3%B6gliche-abreisewochentage'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryDaysCheckboxes tag={"departureDays"} category={item}
                                                                onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`eigenschaftenRow ${selectedOption === "Eigenschaften" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            GPS Daten
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryGeoLocation tag={"geoLocations"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Objektkategoriebeschreibung
                                            <OlapWiki tag={'objektbeschreibung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryDescriptionModal tag={"infoDescription"} category={item}
                                                                  onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Beschreibung der Umgebung
                                            <OlapWiki tag={'beschreibung-der-umgebung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryDescriptionModal tag={"infoEnvironment"} category={item}
                                                                  onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Information zur Kaution
                                            <OlapWiki tag={'information-zur-kaution'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryDescriptionModal tag={"infoDeposit"} category={item}
                                                                  onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`textRow ${selectedOption === "Beschreibungstexte" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Information zu Haustieren
                                            <OlapWiki tag={'information-zu-haustieren'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryDescriptionModal tag={"infoPets"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Objekttyp
                                            <OlapWiki tag={'objekttyp'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"objectTypes"} category={item}
                                                                 onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Raumtyp
                                            <OlapWiki tag={'raumtyp'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"roomTypes"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Zielgruppe
                                            <OlapWiki tag={'zielgruppe'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"targetGroups"} category={item}
                                                                 onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Attribute
                                            <OlapWiki tag={'attribute'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"attributes"} category={item}
                                                                 onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Gastronomie
                                            <OlapWiki tag={'gastronomie'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"foodSupplies"} category={item}
                                                                 onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Bad Equipment
                                            <OlapWiki tag={'bad-equipment'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"bathEquipments"} category={item}
                                                                 onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Küchen Equipment
                                            <OlapWiki tag={'k%C3%BCcheneigenschaften'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"kitchenEquipments"} category={item}
                                                                 onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Ausstattung
                                            <OlapWiki tag={'ausstattung'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"facilities"} category={item}
                                                                 onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Geräte
                                            <OlapWiki tag={'ger%C3%A4te'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"technics"} category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Dienstleistungen
                                            <OlapWiki tag={'dienstleistungen'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"serviceOffers"} category={item}
                                                                 onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Freizeitangebote
                                            <OlapWiki tag={'freizeitangebote'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"leisureOffers"} category={item}
                                                                 onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Outdoor Equipment
                                            <OlapWiki tag={'outdoor-equipment'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesModal tag={"outdoorEquipments"} category={item}
                                                                 onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                <tr className={`categoryRow ${selectedOption === "Kategorien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Betten
                                            <OlapWiki tag={'betten'}/>
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryCategoriesNumbersModal tag={"beds"} category={item}
                                                                        onSubmit={onSubmit}/>
                                    </td>
                                </tr>
                                {
                                    categoryServices.map((service, serviceIndex) => (
                                        <tr key={serviceIndex}
                                            className={`serviceRow ${selectedOption === "Leistungen" ? "" : "d-none"}`}>
                                            <td className="dtr-control">
                                                <div className="rowTitle">
                                                    {service.service.name[0] && service.service.name[0].text ? service.service.name[0].text : ''}
                                                </div>
                                            </td>
                                            <td
                                                onMouseEnter={() => setHoveredColumn(1)}
                                                onMouseLeave={() => setHoveredColumn(null)}
                                            >
                                                <CategoryServiceModal serviceItem={service} categoryId={item.id}
                                                                      onSubmit={submitService}/>
                                            </td>
                                        </tr>
                                    ))
                                }
                                {
                                    categoryServices.length === 0 && (
                                        <tr
                                            className={`serviceRow ${selectedOption === "Leistungen" ? "" : "d-none"}`}>
                                            <td className="dtr-control">
                                                <div className="rowTitle">
                                                    <div>
                                                        Leistungen
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                onMouseEnter={() => setHoveredColumn(1)}
                                                onMouseLeave={() => setHoveredColumn(null)}
                                            >
                                                Es wurden noch keine Leistungen für dieses Objekt hinterlegt. Unter "Leistungsvorlage der Organisation" kann gestartet werden.
                                            </td>
                                        </tr>
                                    )
                                }
                                {/*
                                    <tr className={`serviceRow ${selectedOption === "Leistungen" ? "" : "d-none"}`}>
                                        <td className="dtr-control">
                                            <div className="rowTitle">
                                                <CategoryAddServiceModal categoryId={item.id} onSubmit={submitService}/>
                                            </div>
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                */}
                                <tr className={`mediaRow ${selectedOption === "Medien" ? "" : "d-none"}`}>
                                    <td className="dtr-control">
                                        <div className="rowTitle">
                                            Bilder und Videos
                                            <OlapWiki tag={'bilder'} />
                                        </div>
                                    </td>
                                    <td
                                        onMouseEnter={() => setHoveredColumn(1)}
                                        onMouseLeave={() => setHoveredColumn(null)}
                                    >
                                        <CategoryMediaUploadModal category={item} onSubmit={onSubmit}/>
                                    </td>
                                </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {
                    selectedOption === "Leistungen" && (
                        <OlapOrganizationServices services={organizationServices} onSubmit={submitService}/>
                    )
                }

                {/*
                     {
                    selectedOption === "Leistungen" && (
                        <OlapServiceKatalog onSubmit={submitService}/>
                    )
                }
                */}


            </div>
        </div>
    );
}
export default CategoryPropertiesTable;
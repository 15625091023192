import React from "react";
import { useOrganization } from "../../../hooks/useOrganization";

const OlapManagementHeader = () => {
    const {organization} = useOrganization();

    return (
        <div className='page-title-box d-sm-flex align-items-center justify-content-between'>
            <h4 className='mb-sm-0'>Daten für Organisation: {organization.name}</h4>
        </div>
    );
}

export default OlapManagementHeader;
import React, {Fragment, useEffect, useState} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import OlapManagementHeader from "../Management/OlapManagementHeader";
import OlapTutorial from "../Management/OlapTutorial";
import {useAuth} from "../../../hooks/useAuth";
import CategoryService from "../../../services/CategoryService";
import CategoryPropertiesNavbar from "./CategoryPropertiesNavbar";
import Loader from "../../../components/Loader";
import CategoryPropertiesTable from "./CategoryPropertiesTable";
import PlaceService from "../../../services/PlaceService";
import OlapSyncButton from "../OlapSyncButton";

const CategoryProperties = () => {
    const [loadingCategory, setLoadingCategory] = useState(true);
    const [loadingPlace, setLoadingPlace] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(true);
    const { placeId, categoryId } = useParams();
    const [category, setCategory] = useState({});
    const [place, setPlace] = useState({});

    const location = useLocation();

    const { user } = useAuth();

    const fetchCategory = async () => {
        try {
            setLoadingCategory(true);
            const response = await CategoryService.getCategory(categoryId, user);
            setCategory(response.data);
            setLoadingCategory(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    const fetchPlace = async () => {
        try {
            setLoadingPlace(true);
            const response = await PlaceService.getPlace(placeId, user);
            setPlace(response.data);
            setLoadingPlace(false);
        } catch (err) {
            //TODO
            throw err;
        }
    }

    useEffect(() => {
        fetchCategory();
        fetchPlace();
    }, [location, categoryId, placeId]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSidebarChange = () => {
        setSidebarOpen(!sidebarOpen);
    }

    const fetchCategoryWithoutLoad = async () => {
        try {
            const response = await CategoryService.getCategory(categoryId, user);
            setCategory(response.data);
            setLoadingCategory(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    const onSubmit = async () => {
        await fetchCategoryWithoutLoad();
    }

    return (
        <Fragment>
            <OlapManagementHeader />
            {loadingCategory || loadingPlace ? (
                <Loader card={false} />
            ) : (
                <Fragment>
                    <CategoryPropertiesNavbar handleSidebarChange={handleSidebarChange} place={place} item={category} />
                    <CategoryPropertiesTable sidebarOpen={sidebarOpen} handleSidebarChange={handleSidebarChange} item={category} onSubmit={onSubmit}/>
                </Fragment>
            )}
            <OlapTutorial />
            <OlapSyncButton categoryId={category.id} objectId={null} place={place}/>
        </Fragment>
    );
};

export default CategoryProperties;
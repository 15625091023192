import React from 'react';
import ProfileBackground from "../Profile/ProfileBackground";
import ProfileHeader from "../Profile/ProfileHeader";
import InvitationContent from "./InvitationContent";

const Invitation = () => {

    return (
            <div>
                <ProfileBackground />
                <ProfileHeader />
                <InvitationContent />
            </div>
    );
};

export default Invitation;

import React, { useEffect, useState } from 'react';
import { RiEditLine, RiCloseLine  } from 'react-icons/ri';
import { useAuth } from '../../../hooks/useAuth';
import {useDropzone} from 'react-dropzone';
import MediaService from "../../../services/MediaService";
import config from "../../../config.json";

const PlaceMediaUploadModal = ({ place, onSubmit }) => {
    const [isMediaModalOpen, setIsMediaModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { user } = useAuth();
    const [medias, setMedias] = useState(place.medias);

    const openMediaModal = () => {
        setIsMediaModalOpen(true);
    };

    const closeMediaModal = () => {
        setIsMediaModalOpen(false);
        setFiles([]);
    };

    const submitMediaModal = () => {
        saveChangesPost();
    };

    const saveChangesPost = async () => {
        setIsMediaModalOpen(false);
        setIsLoading(true);

        try {
            for (const file of files) {
                const formData = new FormData();
                formData.append('file', file);
                if (file.season === 'summer') {
                    formData.append('dateFrom', '2024-05-01');
                    formData.append('dateTo', '2024-09-30');
                } else if (file.season === 'winter') {
                    formData.append('dateFrom', '2023-10-01');
                    formData.append('dateTo', '2024-04-30');
                } else {
                    formData.append('dateFrom', '2024-01-01');
                    formData.append('dateTo', '2024-12-31');
                }
                formData.append('description', file.description);
                await MediaService.postPlaceMedia(place.id, user, formData);
            }
            setIsLoading(false);
            setIsMediaModalOpen(false);
            setFiles([]);
            onSubmit();
        } catch (err) {
            setIsLoading(false);
            throw err;
        }
    };

    const [files, setFiles] = useState([]);

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'image/jpeg': [],
            'image/png': []
        },
        onDrop: acceptedFiles => {
            setFiles((prevFiles) => [
                ...prevFiles,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        description: '',
                        season: 'both',
                    })
                ),
            ]);
        }
    });

    const handleUploadRemove = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    };

    const thumbs = files.map((file, index) => (
        <div className={'col-lg-12'}>
            <div className={'d-flex'}>
                <div className={'olap-media-preview'} key={`${file.name}-${index}-${Math.random()}`}>
                    <div className={'olap-media-inner'}>
                        <img
                            src={file.preview}
                            // Revoke data uri after image is loaded
                            //onLoad={() => { URL.revokeObjectURL(file.preview) }}
                        />
                    </div>
                    <button type="button" className="btn-remove" onClick={() => handleUploadRemove(index)}>
                        <RiCloseLine />
                    </button>
                </div>
                <div className={'row w-100 ms-1'}>
                    <div className={'col-lg-12 mb-2'}>
                        <label className={'form-check form-check-inline mt-2 d-inline-flex align-items-center'}>
                            <input
                                className={'form-radio-input form-radio-success me-1'}
                                type="radio"
                                value="summer"
                                checked={file.season === 'summer'}
                                onChange={() => handleChange(index, 'season', 'summer')}
                            />
                            Sommer
                        </label>
                        <label className={'form-check form-check-inline  mt-2 d-inline-flex align-items-center'}>
                            <input
                                className={'form-radio-input form-radio-success me-1'}
                                type="radio"
                                value="winter"
                                checked={file.season === 'winter'}
                                onChange={() => handleChange(index, 'season', 'winter')}
                            />
                            Winter
                        </label>
                        <label className={'form-check form-check-inline  mt-2 d-inline-flex align-items-center'}>
                            <input
                                className={'form-radio-input form-radio-success me-1'}
                                type="radio"
                                value="both"
                                checked={file.season === 'both'}
                                onChange={() => handleChange(index, 'season', 'both')}
                            />
                            Beides
                        </label>
                    </div>
                    <div className={'col-lg-12'}>
                        <textarea
                            className="form-control"
                            placeholder="Beschreibung"
                            value={file.description}
                            onChange={(e) => handleChange(index, 'description', e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    ));

    const handleChange = (index, key, value) => {
        const newFiles = [...files];
        newFiles[index][key] = value;
        setFiles(newFiles);
    };

    const handleDelete = async (mediaId) => {
        try{
            await MediaService.deletePlaceMedia(place.id, user, mediaId);
            onSubmit();
        }
        catch (err) {
            throw err;
        }
    };

    const handleMediasChange = (index, key, value) => {
        const newMedias = [...medias];
        newMedias[index][key] = value;
        setMedias(newMedias);
    };

    const handleMediasDateChange = (index, season) => {
        const newMedias = [...medias];
        const media = newMedias[index];

        if (season === 'summer') {
            media.dateFrom = '2024-05-01';
            media.dateTo = '2024-09-30';
        } else if (season === 'winter') {
            media.dateFrom = '2023-10-01';
            media.dateTo = '2024-04-30';
        } else {
            media.dateFrom = '2024-01-01';
            media.dateTo = '2024-12-31';
        }

        setMedias(newMedias);
    };

    useEffect(() => {
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    useEffect(() => {
        setMedias(place.medias);
    }, [place]);

    return (
        <div className="row" style={{ zIndex: '1051' }}>
            <div className="col-10 d-flex align-items-center">
                <div className="olap-description-text d-flex flex-wrap gap-2">
                    {place && place.medias && place.medias.length > 0 && place.medias.map((image, index) => (
                        <div className="olap-media-preview" key={index}>
                            <div className="olap-media-inner">
                                <img src={`${config.API_BASE_URI}/api/v1/media/places/${place.id}/${image.filename}`} alt={`Alt-Text-${index}`} />
                            </div>
                            <button type="button" className="btn-remove" onClick={() => handleDelete(image.id)}>
                                <RiCloseLine />
                            </button>
                        </div>
                    ))}
                </div>
            </div>
            <div className="col-2">
                <div
                    onClick={openMediaModal}
                    className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#theme-settings-offcanvas"
                    aria-controls="theme-settings-offcanvas"
                >
                    <RiEditLine />
                </div>
                <div
                    id="bottom-rightModal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className={`modal fade ${isMediaModalOpen ? 'show' : ''}`}
                    style={{ display: isMediaModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Medien bearbeiten</h5>
                                <button type="button" className="btn-close" aria-label="Close" onClick={closeMediaModal} />
                            </div>
                            <div className="modal-body">
                                <div>
                                    <section className="container">
                                        <div {...getRootProps({className: 'dropzone'})}>
                                            <input {...getInputProps()} />
                                            <p>Ziehe Dateien hierher oder klicke, um Dateien auszuwählen</p>
                                            <em>(Es werden nur Bilder im Format *.jpeg und *.png akzeptiert)</em>
                                        </div>
                                        {files.length > 0 && (
                                            <aside>
                                                <div className={'text mt-3'}>Neue Bilder</div>
                                                <div className={'row'}>
                                                    {thumbs}
                                                </div>
                                            </aside>
                                        )}

                                        <aside>
                                            {medias && medias.length > 0 && (
                                                <div className={'text mt-3'}>Aktuelle Bilder</div>
                                            )}
                                            <div className={'row'}>
                                                {medias && medias.map((image, index) => (
                                                    <div className={'col-lg-12'}>
                                                        <div className={'d-flex'}>
                                                            <div className="olap-media-preview" key={index}>
                                                                <div className="olap-media-inner">
                                                                    <img src={`${config.API_BASE_URI}/api/v1/media/places/${place.id}/${image.filename}`} alt={`Alt-Text-${index}`} />
                                                                </div>
                                                                <button type="button" className="btn-remove d-none">
                                                                    <RiCloseLine />
                                                                </button>
                                                            </div>
                                                            <div className={'row w-100 ms-1'}>
                                                                <div className={'col-lg-12 mb-2'}>
                                                                    <label className={'form-check form-check-inline mt-2 d-inline-flex align-items-center'}>
                                                                        <input
                                                                            className={'form-radio-input form-radio-success me-1'}
                                                                            type="radio"
                                                                            value="summer"
                                                                            checked={image.dateFrom.includes('05-01')}
                                                                            onChange={() => handleMediasDateChange(index, 'summer')}
                                                                        />
                                                                        Sommer
                                                                    </label>
                                                                    <label className={'form-check form-check-inline  mt-2 d-inline-flex align-items-center'}>
                                                                        <input
                                                                            className={'form-radio-input form-radio-success me-1'}
                                                                            type="radio"
                                                                            value="winter"
                                                                            checked={image.dateFrom.includes('10-01')}
                                                                            onChange={() => handleMediasDateChange(index, 'winter')}
                                                                        />
                                                                        Winter
                                                                    </label>
                                                                    <label className={'form-check form-check-inline  mt-2 d-inline-flex align-items-center'}>
                                                                        <input
                                                                            className={'form-radio-input form-radio-success me-1'}
                                                                            type="radio"
                                                                            value="both"
                                                                            checked={image.dateFrom.includes('01-01')}
                                                                            onChange={() => handleMediasDateChange(index, 'both')}
                                                                        />
                                                                        Beides
                                                                    </label>
                                                                </div>
                                                                <div className={'col-lg-12'}>
                                                                    <textarea
                                                                        className="form-control"
                                                                        placeholder="Beschreibung"
                                                                        value={image.description}
                                                                        onChange={(e) => handleMediasChange(index, 'description', e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </aside>
                                    </section>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <span type="button" className="btn btn-light" onClick={closeMediaModal}>
                                  Abbrechen
                                </span>
                                                <span className="btn btn-success" onClick={submitMediaModal}>
                                  Speichern
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`toastify ${isLoading ? 'on' : ''} bg-success toastify-center toastify-top`} aria-live="polite" style={{transform: "translate(0px, 0px)", top: "15px"}}>Es werden gerade deine Dateien hochgeladen!</div>
        </div>
    );
};

export default PlaceMediaUploadModal;

import React, {useEffect, useState} from 'react';
import PropertiesService from '../../../services/PropertiesService';  // Updated service name
import { useAuth } from '../../../hooks/useAuth';
import CategoryService from "../../../services/CategoryService";

const CategoryTimeInput = ({ tag, category, onSubmit }) => {
    const { user } = useAuth();

    const { id, bookingObjectSeasonProperties, ...restFullState } = category;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState
    });

    useEffect(() => {
        const { id, bookingObjectSeasonProperties, ...restFullState } = category;

        setFullStateEdit({
            ...restFullState
        });

    }, [category]);

    const change = (e) => {
        const newValue = e.target.value;
        setFullStateEdit((prevState) => ({
            ...prevState,
            bookingProperties: {
                ...prevState.bookingProperties,
                [tag]: newValue,
            },
        }));
    };


    const saveChanges = async () => {
        try {
            await CategoryService.putCategory(category.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    return (
        <div className='text-center olap-input'>
            <input
                type='time'
                className='form-control form-control-edit text-center'
                value={fullStateEdit.bookingProperties[tag]}
                onChange={change}
                onBlur={saveChanges}
            />
        </div>
    );
};

export default CategoryTimeInput;
import axios from 'axios';
import config from '../config.json';

class MediaService {
    static async getObjectMedia(fileId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/media/${fileId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postObjectMedia(objectId, user, file) {
        return axios.post(`${config.API_BASE_URI}/api/v1/objects/${objectId}/medias`, file,{
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postCategoryMedia(categoryId, user, file) {
        return axios.post(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/medias`, file,{
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postPlaceMedia(placeId, user, file) {
        return axios.post(`${config.API_BASE_URI}/api/v1/places/${placeId}/medias`, file,{
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteCategoryMedia(categoryId, user, mediaId) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/medias/${mediaId}`,{
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteObjectMedia(objectId, user, mediaId) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/objects/${objectId}/medias/${mediaId}`,{
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deletePlaceMedia(placeId, user, mediaId) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/places/${placeId}/medias/${mediaId}`,{
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putMedia(user, mediaId, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/medias/${mediaId}`, state ,{
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

}

export default MediaService;

import React, { useState } from 'react';
import ObjectService from '../../services/ObjectService';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import { useOrganization } from '../../hooks/useOrganization';
import {isEmpty} from "../../utils/validation";

const AddObjectModal = ({ onClickClose = () => {} }) => {
    const [isObjectModalOpen, setIsObjectModalOpen] = useState(false);

    const [errors, setErrors] = useState({
        suffix: false,
        prefix: false,
        objectCount: false,
        begin: false,
    });

    const openObjectModal = () => {
        setIsObjectModalOpen(true);
    };

    const closeObjectModal = () => {
        setIsObjectModalOpen(false);
        onClickClose();
    };

    const { placeId, categoryId } = useParams();
    const { user } = useAuth();
    const { organization } = useOrganization();
    const navigate = useNavigate();

    const [addObjectState, setAddObjectState] = useState({
        suffix: '',
        prefix: '',
        objectCount: 1,
        begin: 1,
    });

    const change = (e) => {
        setAddObjectState({ ...addObjectState, [e.target.name]: e.target.value });
    };

    const validate = () => {
        let hasErrors = false;
        let errors = {};

        if (isEmpty(addObjectState.objectCount) || !/^\d+$/.test(addObjectState.objectCount) || parseInt(addObjectState.objectCount) < 1) {
            errors.objectCount = 'Die Objektanzahl muss mindestens 1 sein.';
            hasErrors = true;
        } else if (parseInt(addObjectState.objectCount) === 1) {
            addObjectState.prefix = addObjectState.prefix.trim();
        }

        if (isEmpty(addObjectState.prefix) && isEmpty(addObjectState.suffix)) {
            errors.suffix = 'Es wird Suffix oder Prefix benötigt!';
            errors.prefix = 'Es wird Suffix oder Prefix benötigt!';
            hasErrors = true;
        }

        if (isEmpty(addObjectState.begin) || !/^\d+$/.test(addObjectState.begin) || parseInt(addObjectState.begin) < 1) {
            errors.begin = 'Beginn muss mindestens 1 sein.';
            hasErrors = true;
        }

        setErrors(errors);

        return !hasErrors;
    };

    const submit = async (e) => {
        e.preventDefault();
        if (validate()) {
            try {
                onClickClose();
                setIsObjectModalOpen(false);
                const response = await ObjectService.postObject(placeId, categoryId, organization, user, addObjectState);
                setAddObjectState({
                    suffix: '',
                    prefix: '',
                    objectCount: 1,
                    begin: 1,
                });
                navigate(`/management/place/${response.placeId}/category/${response.categoryId}?newObject=true`, { replace: true });
            } catch (err) {
                //TODO
                throw err;
            }
        }
        return false;
    };
    return (
        <div>
            <span className='dropdown-item viewfile-list' role='button' onClick={openObjectModal}>
                Objekte
            </span>
            <div
                className={`modal fade ${isObjectModalOpen ? 'show' : ''}`}
                style={{ display: isObjectModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                aria-hidden={!isObjectModalOpen}
            >
                <div className='modal-dialog'>
                    <form onSubmit={submit}>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title'>Neue Objekte hinzufügen</h5>
                                <button
                                    aria-label='Close'
                                    className='btn-close'
                                    onClick={closeObjectModal}
                                    type='button'
                                />
                            </div>
                            <div className='modal-body'>
                                <div className='mb-4'>
                                    <label className='form-label'>{addObjectState.objectCount > 1 ? "Prefix (vor der Nummer)" : "Objektname"}</label>
                                    <input
                                        className={`form-control ${errors.prefix && 'is-invalid'}`}
                                        name='prefix'
                                        onChange={change}
                                        required={true}
                                        type='text'
                                        value={addObjectState.prefix}
                                    />
                                    {addObjectState.objectCount > 1 && (
                                        <div className="text-muted">Fügen Sie am Ende wenn gewünscht ein Leerzeichen ein.</div>
                                    )}
                                    <div className='invalid-feedback'>{errors.prefix}</div>
                                </div>
                                <div className='mb-4'>
                                    <label className='form-label'>Wie viele Objekte sollen hinzugefügt werden?</label>
                                    <input
                                        className={`form-control ${errors.objectCount && 'is-invalid'}`}
                                        max={1000}
                                        min={1}
                                        name='objectCount'
                                        onChange={change}
                                        type='number'
                                        value={addObjectState.objectCount}
                                    />
                                    <div className='invalid-feedback'>{errors.objectCount}</div>
                                </div>
                                {addObjectState.objectCount > 1 && (
                                    <>
                                        <div className='mb-4'>
                                            <label className='form-label'>Suffix (nach der Nummer)</label>
                                            <input
                                                className={`form-control ${errors.suffix && 'is-invalid'}`}
                                                name='suffix'
                                                onChange={change}
                                                type='text'
                                                value={addObjectState.suffix}
                                            />
                                            <div className="text-muted">Fügen Sie davor wenn gewünscht ein Leerzeichen ein.</div>
                                            <div className='invalid-feedback'>{errors.suffix}</div>
                                        </div>
                                        <div className='mb-4'>
                                            <label className='form-label'>Bei welcher Nummer soll begonnen werden?</label>
                                            <input
                                                className={`form-control ${errors.begin && 'is-invalid'}`}
                                                name='begin'
                                                onChange={change}
                                                type='number'
                                                value={addObjectState.begin}
                                            />
                                            <div className='invalid-feedback'>{errors.begin}</div>
                                        </div>
                                        <div className='mb-4'>
                                            <p className="text-muted fw-bold">
                                                Vorschau:
                                            </p>
                                            <p className="text-muted">
                                                {addObjectState.prefix} {addObjectState.begin} {addObjectState.suffix}<br/>
                                                <br/>
                                                bis <br/>
                                                <br/>
                                                {addObjectState.prefix} {parseInt(addObjectState.begin) + parseInt(addObjectState.objectCount) - 1} {addObjectState.suffix}
                                            </p>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-light' onClick={closeObjectModal}>
                                    Abbrechen
                                </button>
                                <button type='submit' className='btn btn-success'>
                                    Speichern
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddObjectModal;

import React, {useEffect, useState} from 'react';
import { RiCloseLine } from 'react-icons/ri';

const OlapDiscountModal = () => {
    const [isDiscountModalOpen, setIsDiscountModalOpen] = useState(false);
    const [editState, setEditState] = useState({
        name: '',
        beschreibung: '',
        from: '',
        to: '',
        wert: '',
        type: '',
        maxDay: '',
        minDay: '',
        active: '',
    });

    const openDiscountModal = () => {
        setIsDiscountModalOpen(true);
    };

    const closeDiscountModal = () => {
        setIsDiscountModalOpen(false);
    };

    const submitDiscountModal = () => {
        setIsDiscountModalOpen(false);
    };

    const change = (e) => {
        setEditState({ ...editState, [e.target.name]: e.target.value });
    };

    return (
        <div className="row" style={{zIndex: "1050"}}>
            <div className="col-10">
            </div>
            <div className="col-2">
                <div onClick={openDiscountModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    auswählen und hinzufügen
                </div>
                <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isDiscountModalOpen ? 'show' : ''}`}
                     style={{ display: isDiscountModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <h5 className='modal-title'>
                                    Rabatte
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeDiscountModal} />
                            </div>
                            <div className='modal-body'>
                                <div>
                                    <label className='form-label mt-2'>
                                        Rabattname
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='name'
                                        value={editState.name}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Rabattbeschreibung
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='beschreibung'
                                        value={editState.beschreibung}
                                        onChange={change}
                                    />
                                </div>
                                <div className="row">
                                    <div className="col-lg-6">
                                        <label className='form-label mt-2'>
                                            Verfügbar von
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='from'
                                            value={editState.from}
                                            onChange={change}
                                        />
                                    </div>
                                    <div className="col-lg-6">
                                        <label className='form-label mt-2'>
                                            Verfügbar bis
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='to'
                                            value={editState.to}
                                            onChange={change}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-8">
                                        <label className='form-label mt-2'>
                                            Wert
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='wert'
                                            value={editState.wert}
                                            onChange={change}
                                        />
                                    </div>
                                    <div className="col-lg-4">
                                        <label className='form-label mt-2'>
                                            Typ
                                        </label>
                                        <input
                                            type='text'
                                            className='form-control'
                                            name='type'
                                            value={editState.type}
                                            onChange={change}
                                        />
                                    </div>
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Maximale Tage vor Anreise (für Last Minute, maximal oder weniger Tage vor Anreise)
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='maxDay'
                                        value={editState.maxDay}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Minimale Tage vor Anreise (für Early Bird, mindestens oder mehr Tage vor Anreise)
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='minDay'
                                        value={editState.minDay}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label className='form-label mt-2'>
                                        Aktiviert
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='active'
                                        value={editState.active}
                                        onChange={change}
                                    />
                                </div>
                            </div>
                            <div className='modal-footer'>
                            <span type='button' className='btn btn-light' onClick={closeDiscountModal}>
                                Abbrechen
                            </span>
                                <span className='btn btn-success' onClick={submitDiscountModal}>
                                Speichern
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OlapDiscountModal;

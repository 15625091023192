import axios from 'axios';
import config from '../config.json';

class PropertiesService {
    static async postObjectProperty(objectId, user, state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/objects/${objectId}/properties`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async updateObjectProperty(propertyId, objectId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/objects/${objectId}/properties/${propertyId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postCategoryProperty(categoryId, user, state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/properties`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async updateCategoryProperty(propertyId, categoryId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/properties/${propertyId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async updateSeasonObjectProperty(seasonId, objectId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/objects/${objectId}/seasons/${seasonId}/properties`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async updateSeasonCategoryProperty(seasonId, categoryId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/seasons/${seasonId}/properties`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postSeasonObjectProperty(seasonId, objectId, user, state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/objects/${objectId}/seasons/${seasonId}/properties`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteSeasonObjectProperty(seasonId, objectId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/objects/${objectId}/seasons/${seasonId}/properties`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postSeasonCategoryProperty(seasonId, categoryId, user, state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/seasons/${seasonId}/properties`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteSeasonCategoryProperty(seasonId, categoryId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/seasons/${seasonId}/properties`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }


}

export default PropertiesService;

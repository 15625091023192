import React, {Fragment, useEffect, useState} from "react";
import Loader from "../../components/Loader";
import AlertCard from "../../components/AlertCard";
import OlapPlaces from "./OlapPlaces";
import {useLocation} from "react-router-dom";
import {useOrganization} from "../../hooks/useOrganization";
import {useAuth} from "../../hooks/useAuth";
import PlaceService from "../../services/PlaceService";

const OlapList = ({handleSidebarChange}) => {
    const [loading, setLoading] = useState(true);
    const [places, setPlaces] = useState([]);

    let location = useLocation();

    const { organization } = useOrganization();

    const { user } = useAuth();

    const fetchPlaces = async () => {
        const response = await PlaceService.getPlaces(organization.id, user);
        const filteredPlaces = response.data.filter((item) => {
            if (!item.channels || item.channels.length === 0) {
                return true;
            }
            return !item.channels[0].channelDataMap.deleted;
        });
        setPlaces(filteredPlaces);
        setLoading(false);
    };

    useEffect(() => {
        fetchPlaces();
    }, [location]);
    return (

        <Fragment>
            {loading ? (
                <Loader card={false} />
            ) : (
                <div className='row' id='folderlist-data'>
                    {places.length === 0 && <AlertCard>In dieser Organisation gibt es noch keine Plätze.</AlertCard>}
                    {places.map((item, idx) => (
                        <OlapPlaces item={item} key={idx} handleSidebarChange={handleSidebarChange}/>
                    ))}
                </div>)}
        </Fragment>
    );
}

export default OlapList;
import React, {useEffect, useState} from 'react';
import { useAuth } from '../../../hooks/useAuth';
import ObjectService from "../../../services/ObjectService";
import PropertiesService from "../../../services/PropertiesService";

const ObjectSeasonBlocked = ({ tag, objectId, category, seasonProperties, seasonPropertyIndex, onSubmit  }) => {
    const { user } = useAuth();

    const { season, ...restFullState } = seasonProperties;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState
    });

    useEffect(() => {
        const { season, ...restFullState } = seasonProperties;

        setFullStateEdit({
            ...restFullState
        });

    }, [seasonProperties]);

    const change = (e) => {
        let newValue = null;

        newValue = e.target.checked;

        setFullStateEdit((prevState) => ({
            ...prevState,
            bookingProperties: {
                ...prevState.bookingProperties,
                [tag]: newValue,
            }
        }));
    };

    const saveChanges = async () => {
        try {
            await PropertiesService.updateSeasonObjectProperty(seasonProperties.season.id, objectId, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    return (
        <div className='text'>
            Soll dieses Object für diese Saison geblockt werden und nicht mehr buchbar sein?
            <label className='form-check-label ms-2' htmlFor='blocked'>
                Ja
            </label>
            <input
                id='blocked'
                type='checkbox'
                className='form-check-input ms-2 cursor-pointer'
                value='blocked'
                onChange={change}
                onBlur={saveChanges}
                checked={fullStateEdit.bookingProperties[tag]}
            />
        </div>
    );
};

export default ObjectSeasonBlocked;
import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import SeasonService from "../../services/SeasonService";
import {useOrganization} from "../../hooks/useOrganization";

const AddCalendarModal = ({ onSubmit = () => {} }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { user } = useAuth();
    const { organization } = useOrganization();

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const [state, setState] = useState({
        name: '',
        year: ''
    });

    const [error, setError] = useState('');

    const change = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };

    const submit = async (e) => {
        e.preventDefault();
        try {
            // Hier wird überprüft, ob das Jahr eine Zahl ist und im zulässigen Bereich liegt
            if (isNaN(state.year) || state.year < new Date().getFullYear() || state.year > new Date().getFullYear() + 5) {
                setError('Bitte trage ein Jahr von diesem Jahr bis fünf Jahre in die Zukunft ein.');
                return;
            }

            // Wenn die Eingabe korrekt ist, wird die Fehlermeldung zurückgesetzt
            setError('');

            await SeasonService.postCalendar(organization.id, user, state);
            onSubmit();
            closeModal();
            setState({
                name: '',
                year: '',
            });
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div>
            <button type='button' className='btn btn-success ' onClick={openModal}>
                Saisonkalender erstellen
            </button>
            <div
                id='organisationModal'
                className={`modal fade ${isModalOpen ? 'show' : ''}`}
                tabIndex='-1'
                style={{ display: isModalOpen ? 'block' : 'none' }}
                aria-hidden={!isModalOpen}
            >
                <div className='modal-dialog'>
                    <form>
                        <div className='modal-content'>
                            <div className='modal-header'>
                                <h5 className='modal-title' id='myModalLabel'>
                                    Neuen Saisonkalender erstellen
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeModal} />
                            </div>
                            <div className='modal-body'>
                                <h5 className='fs-15'>
                                    Tragen Sie im unteren Feld den Namen für den Kalender ein und drücken Sie auf
                                    "Erstellen".
                                </h5>
                                <div>
                                    <label htmlFor='calendarname' className='form-label'>
                                        Kalendername
                                    </label>
                                    <input
                                        type='text'
                                        className='form-control'
                                        name='name'
                                        value={state.name}
                                        onChange={change}
                                    />
                                </div>
                                <div>
                                    <label htmlFor="calendaryear" className="mt-2 form-label">
                                        Kalenderjahr
                                    </label>
                                    <input
                                        type="number"
                                        className="form-control"
                                        name="year"
                                        value={state.year}
                                        onChange={change}
                                        onKeyDown={(e) => {
                                            if (e.key === 'e' || e.key === 'E' || e.key === '+' || e.key === '-' || e.key === '.' || e.key === ',') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    {error && <div className="text-danger">{error}</div>}
                                </div>
                            </div>
                            <div className='modal-footer'>
                                <button type='button' className='btn btn-light' onClick={closeModal}>
                                    Abbrechen
                                </button>
                                <span type='submit' className='btn btn-success' onClick={submit}>
                                    Erstellen
                                </span>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default AddCalendarModal;

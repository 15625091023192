import React, { useEffect, useState } from 'react';
import { RiEditLine, RiCloseLine } from 'react-icons/ri';
import { useAuth } from '../../../hooks/useAuth';
import OlapTooltip from "../OlapTooltip";
import serviceService from "../../../services/ServiceService";

const ObjectPlacerholderServiceModal = ({ service, objectId, onSubmit }) => {

    const { user } = useAuth();

    const { id, ...restFullState } = service;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState,
        overrides: service.id
    });

    useEffect(() => {
        const { id, ...restFullState } = service;

        setFullStateEdit({
            ...restFullState,
            overrides: service.id
        });

    }, [service]);

    const addService = async () => {
        try {
            await serviceService.postObjectServices(objectId, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    return (
        <div className="row" style={{ zIndex: '1051' }}>
            <div className="col-10">
                <div className="olap-description-text d-flex flex-wrap gap-2">
                    {fullStateEdit.name[0] && fullStateEdit.name[0].text ? fullStateEdit.name[0].text : ''}<br/>
                    {fullStateEdit.inclusive && 'inkl., '}
                    {fullStateEdit.paymentInterval === 'PER_VISIT' ? '' : fullStateEdit.paymentInterval === 'PER_DAY' ? 'pro Tag, ' : 'pro Stunde, '}
                    {fullStateEdit.serviceCalculationType === 'PER_BOOKING' ? '' : fullStateEdit.serviceCalculationType === 'PER_PERSON' ? 'p.P., ' : fullStateEdit.serviceCalculationType === 'PER_ROOM_OR_OBJECT' ? 'p.Z., ' : ''}
                    {fullStateEdit.ageMin ? `>=${fullStateEdit.ageMin}, ` : ''}
                    {fullStateEdit.ageMax ? `<=${fullStateEdit.ageMax}, ` : ''}
                    {fullStateEdit.price ? `${fullStateEdit.price}€, `: ''}
                    {fullStateEdit.addedAutomatically ? `autom., ` : ''}
                    {fullStateEdit.hidden ? `ausbl., ` : ''}
                    {fullStateEdit.onSite ? `nur v. Ort, ` : ''}
                </div>
            </div>
            <div className="col-2">
                <div className={'row'}>
                    <div className="col-12">
                        <div
                            onClick={addService}
                            className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2"
                        >
                            <RiEditLine />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ObjectPlacerholderServiceModal;

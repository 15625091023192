import React, {useEffect, useState} from 'react';
import { useAuth } from '../../../hooks/useAuth';
import ObjectService from "../../../services/ObjectService";

const ObjectTimeInput = ({ tag,  object, category, onSubmit }) => {
    const { user } = useAuth();

    const { id, bookingObjectSeasonProperties, ...restFullState } = object;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState
    });

    useEffect(() => {
        const { id, bookingObjectSeasonProperties, ...restFullState } = object;

        setFullStateEdit({
            ...restFullState
        });

    }, [object]);

    const change = (e) => {
        const newValue = e.target.value;
        setFullStateEdit((prevState) => ({
            ...prevState,
            bookingProperties: {
                ...prevState.bookingProperties,
                [tag]: newValue,
            },
        }));
    };

    const saveChanges = async () => {
        try {
            await ObjectService.putObject(object.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    return (
        <div className='text-center olap-input d-flex'>
            {fullStateEdit.bookingProperties[tag] !== null ? '' : (
                <span className='me-2' style={{color: '#868993'}}>{category.bookingProperties[tag]}</span>
            )}
            <input
                type='time'
                className='form-control form-control-edit text-center'
                value={fullStateEdit.bookingProperties[tag]}
                onChange={change}
                onBlur={saveChanges}
            />
        </div>
    );
};

export default ObjectTimeInput;
import React, {Fragment, useEffect, useState} from "react";
import {useOrganization} from "../../../hooks/useOrganization";
import AddCalendarModal from "../AddCaldenarModal";
import SeasonService from "../../../services/SeasonService";
import Loader from "../../../components/Loader";
import AlertCard from "../../../components/AlertCard";
import {useAuth} from "../../../hooks/useAuth";
import CalendarCard from "../CalendarCard";
const First = () => {
    const { organization } = useOrganization();
    const { user } = useAuth();

    const [loading, setLoading] = useState(true);
    const [calendars, setCalendars] = useState([]);

    const fetchCalendars = async () => {
        try {
            const response = await SeasonService.getCalendars(organization.id, user);
            setCalendars(response.data);
            setLoading(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    useEffect(() => {
        fetchCalendars();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const onModalSubmit = () => {
        setLoading(true);
        fetchCalendars();
    };

    return (
        <Fragment>
            <div>
                <h5>Saisonkalender</h5>
                <p className="text-muted">
                    Wähle einen Saisonkalender aus oder erstelle einen Neuen.
                </p>
                <div className='row'>
                    {loading && <Loader />}
                    {loading !== true && calendars.length === 0 && (
                        <AlertCard>
                            <strong>Warnung</strong> - Es wurde für diese Organisation noch kein Saisonkalender erstellt.
                        </AlertCard>
                    )}
                    {loading !== true && calendars.length > 0 && (
                        calendars.map((item, idx) => (
                                <CalendarCard key={idx} item={item} onSubmit={onModalSubmit}/>
                        ))
                    )}

                </div>
                <div>
                    <AddCalendarModal onSubmit={onModalSubmit}/>
                </div>
            </div>
        </Fragment>
    );
};

export default First;
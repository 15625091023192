import React, {useEffect, useState} from 'react';
import { RiEditLine } from 'react-icons/ri';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const OlapDescriptionModal = () => {
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    const [editState, setEditState] = useState({
         value: ''
    });

    const openDescriptionModal = () => {
        setIsDescriptionModalOpen(true);
    };

    const closeDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
    };

    const submitDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
    };

    const change = (e) => {
        setEditState({ value: e });
    };

    return (
        <div className="row" style={{zIndex: "1050"}}>
            <div className="col-9">
                <div className="olap-description-text" dangerouslySetInnerHTML={{ __html: editState.value }} />
            </div>
            <div className="col-3">
                <div onClick={openDescriptionModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <RiEditLine />
                </div>
                <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isDescriptionModalOpen ? 'show' : ''}`}
                     style={{ display: isDescriptionModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <h5 className='modal-title'>
                                    Beschreibung bearbeiten
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeDescriptionModal} />
                            </div>
                            <div className='modal-body'>
                                <div>
                                    <ReactQuill theme="snow" name='value' value={editState.value} onChange={change} />
                                </div>
                            </div>
                            <div className='modal-footer'>
                            <span type='button' className='btn btn-light' onClick={closeDescriptionModal}>
                                Abbrechen
                            </span>
                                <span className='btn btn-success' onClick={submitDescriptionModal}>
                                Speichern
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OlapDescriptionModal;

import React, { useEffect, useState } from 'react';
import { RiEditLine } from 'react-icons/ri';
import { useAuth } from '../../../hooks/useAuth';
import PlaceService from "../../../services/PlaceService";

const PlaceCategoriesNumberModal = ({ tag, place, onSubmit }) => {
    const categoryEnums = {
        distances: {
            TO_BEACH: 'Zum Strand',
            TO_CABLE_CAR: 'Zur Seilbahn',
            TO_CENTER: 'Zum Zentrum',
            TO_TOURIST_INFORMATION: 'Zum Touristeninformation',
            TO_AIRPORT: 'Zum Flughafen',
            ABOVE_SEA_LEVEL: 'Über dem Meeresspiegel',
            TO_SKI_RESORT: 'Zum Skigebiet',
            TO_HIKING_TRAIL: 'Zum Wanderweg',
            TO_HOSPITAL_CLINIC: 'Zum Krankenhaus/Klinik',
            TO_BAKERY: 'Zur Bäckerei',
            TO_THERMAL_BATH: 'Zum Thermalbad',
            TO_SUBURBAN_TRAIN: 'Zum S-Bahnhof',
            TO_SWIMMING_POOL_WATER_PARK: 'Zum Schwimmbad/Wasserpark',
            TO_DOCTOR: 'Zum Arzt',
            TO_THEME_PARK: 'Zum Freizeitpark',
            TO_BATHING_AREA_WATER: 'Zum Badegewässer',
            TO_PARK_FOREST: 'Zum Park/Wald',
            TO_FAIR: 'Zur Messe',
            TO_TRAIN_STATION: 'Zum Bahnhof',
            TO_HIGHWAY: 'Zur Autobahn',
            TO_CROSS_COUNTRY_SKI_TRACK: 'Zum Langlauf',
            TO_GOLF_COURSE: 'Zum Golfplatz',
            TO_BUS_STOP: 'Zur Bushaltestelle',
            TO_ATM_BANK: 'Zum Geldautomaten/Bank',
            TO_SUPERMARKET: 'Zum Supermarkt',
            TO_BIKE_PATH: 'Zum Radweg',
            TO_TRAM: 'Zur Straßenbahn',
            TO_RESTAURANT: 'Zum Restaurant',
            TO_NEIGHBOR: 'Zum Nachbarn',
        },
    };

    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    const { user } = useAuth();

    const { id, ...restFullState } = place;
    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState,
    });

    useEffect(() => {
        const { id, ...restFullState } = place;
        setFullStateEdit({
            ...restFullState,
        });
    }, [place]);

    const openDescriptionModal = () => {
        setIsDescriptionModalOpen(true);
    };

    const closeDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
    };

    const submitDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
        saveChanges();
    };

    const saveChanges = async () => {
        try {
            await PlaceService.putPlace(place.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    const toggleCategoryItem = (categoryItem) => {
        setFullStateEdit((prevState) => {
            const newCategoryItems = [...prevState[tag]];

            const index = newCategoryItems.findIndex(item => item.type === categoryItem);

            if (index !== -1) {
                newCategoryItems.splice(index, 1);
            } else {
                newCategoryItems.push({ value: 1, type: categoryItem });
            }

            return {
                ...prevState,
                [tag]: newCategoryItems,
            };
        });
    };

    const handleInputChange = (categoryItem, value) => {
        setFullStateEdit((prevState) => ({
            ...prevState,
            [tag]: prevState[tag].map(item => item.type === categoryItem ? { ...item, value } : item),
        }));
    };

    return (
        <div className="row" style={{ zIndex: '1051' }}>
            <div className="col-9 d-flex align-items-center">
                <div className="olap-description-text d-flex flex-wrap gap-2">
                    {fullStateEdit[tag].map((categoryItem) => (
                        <span key={categoryItem.type} className={'badge bg-success-subtle text-success badge-border'}>
                          {categoryEnums[tag][categoryItem.type]} - {categoryItem.value}
                        </span>
                    ))}
                </div>
            </div>
            <div className="col-3">
                <div
                    onClick={openDescriptionModal}
                    className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2"
                    data-bs-toggle="offcanvas"
                    data-bs-target="#theme-settings-offcanvas"
                    aria-controls="theme-settings-offcanvas"
                >
                    <RiEditLine />
                </div>
                <div
                    id="bottom-rightModal"
                    tabIndex="-1"
                    aria-hidden="true"
                    className={`modal fade ${isDescriptionModalOpen ? 'show' : ''}`}
                    style={{ display: isDescriptionModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                >
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Kategorie bearbeiten</h5>
                                {
                                    tag === 'distances' && (
                                        <div className="modal-title text-muted d-block ms-1">
                                            Alle Angaben in Metern
                                        </div>
                                    )
                                }
                                <button type="button" className="btn-close" aria-label="Close" onClick={closeDescriptionModal} />
                            </div>
                            <div className="modal-body">
                                <div>
                                    <div className={'row'}>
                                        {Object.keys(categoryEnums[tag]).map((categoryItem) => (
                                            <div key={categoryItem} className={'col-lg-6'}>
                                                <label className={'cursor-pointer'}>
                                                    <input
                                                        className={'form-check-input'}
                                                        type="checkbox"
                                                        value={categoryItem}
                                                        checked={fullStateEdit[tag].some(item => item.type === categoryItem)}
                                                        onChange={() => toggleCategoryItem(categoryItem)}
                                                    />
                                                    <span className={'ms-1'}>{categoryEnums[tag][categoryItem]}</span>
                                                    <input
                                                        type="number"
                                                        className={"form-control"}
                                                        value={fullStateEdit[tag].find(item => item.type === categoryItem)?.value || 0}
                                                        onChange={(e) => handleInputChange(categoryItem, parseInt(e.target.value, 10))}
                                                    />
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <span type="button" className="btn btn-light" onClick={closeDescriptionModal}>
                                  Abbrechen
                                </span>
                                                <span className="btn btn-success" onClick={submitDescriptionModal}>
                                  Speichern
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlaceCategoriesNumberModal;

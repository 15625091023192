import React, {Fragment, useEffect, useState} from 'react';
import {useAuth} from "../../hooks/useAuth";
import {useOrganization} from "../../hooks/useOrganization";
import SyncService from "../../services/SyncService";
import Loader from "../../components/Loader";

const OlapSyncButton = ({categoryId, objectId, place}) => {
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState(false);
    const [success, setSuccess] = useState(false);

    const state = {};
    if (categoryId) {
        state.categoryIds = [categoryId];
    }
    if (objectId) {
        state.objectIds = [objectId];
    }

    const sync = async () => {
        const apikey = place.apiKey.apiKey;
        setLoading(true);
        try {
            const response = await SyncService.sync(apikey, state);
            setLoading(false);
            setSuccess(true);
        } catch (error) {
            console.error("Error while syncing:", error);
            setErrors(true);
            setLoading(false);
        }
    }

    useEffect(() => {
        const errorTimer = setTimeout(() => {
            setErrors(false);
        }, 3000);

        return () => {
            clearTimeout(errorTimer);
        };
    }, [errors]);

    useEffect(() => {
        const successTimer = setTimeout(() => {
            setSuccess(false);
        }, 3000);

        return () => {
            clearTimeout(successTimer);
        };
    }, [success]);

    return (
        <Fragment>
            <div className={`customizer-setting ${place.apiKey && place.apiKey.apiKey ? "" : "d-none"}`} style={{zIndex: "1050"}}
                 style={{right: "80px"}}>
                <div onClick={sync} className="ms-2 btn-olap btn btn-primary rounded-pill shadow-lg p-2"
                     data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas"
                     aria-controls="theme-settings-offcanvas">
                    Synchronisieren
                    {loading && (
                        <div className='spinner-border text-light ms-2' style={{width: "13px", height: "13px"}} role='status'>
                            <span className='sr-only'>Loading...</span>
                        </div>
                    )}
                </div>
            </div>
            <div className={`toastify ${errors ? 'on' : ''} bg-danger toastify-center toastify-top`} aria-live="polite"
                 style={{transform: "translate(0px, 0px)", top: "15px"}}>Die Synchronisation ist fehlgeschlagen. Bitte wenden Sie sich an einen Entwickler.
            </div>

            <div className={`toastify ${success ? 'on' : ''} bg-success toastify-center toastify-top`} aria-live="polite"
                 style={{transform: "translate(0px, 0px)", top: "15px"}}>Die Synchronisation war erfolgreich.
            </div>
        </Fragment>
    )
        ;
};

export default OlapSyncButton

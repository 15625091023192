import React from 'react';
import defaultBackground from '../../assets/img/default-background.webp';

const ProfileBackground = () => {
    return (
        <div className="profile-foreground position-relative mx-n4 mt-n4">
            <div className="profile-wid-bg">
                <img src={defaultBackground} alt="" className="profile-wid-img" />
            </div>
        </div>
    );
};

export default ProfileBackground;
import React, {useState} from 'react';
import { RiQuestionLine, RiCloseLine } from 'react-icons/ri';

const Tutorial = () => {
    const [isTutorialModalOpen, setIsTutorialModalOpen] = useState(false);

    const openTutorialModal = () => {
        setIsTutorialModalOpen(true);
    };

    const closeTutorialModal = () => {
        setIsTutorialModalOpen(false);
    };

    return (
        <div className="customizer-setting d-none d-md-block" style={{zIndex: "1050"}}>
            <div onClick={openTutorialModal} className="btn-info rounded-pill shadow-lg btn btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                <RiQuestionLine className="mdi mdi-spin mdi-cog-outline fs-22" />
            </div>
            <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isTutorialModalOpen ? 'show' : ''}`}
                 style={{ display: isTutorialModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-body p-5">
                            <div className="mt-4">
                                <h4 className="mb-3">Willkommen in der Saisonkalender-Ansicht!</h4>
                                <p className="text-muted mb-4">Hier können Sie Ihre Datumsbereiche erstellen und verwalten, sie in Saisons gruppieren und sie einem Saisonkalender zuordnen.</p>
                                <p className="text-muted mb-4">Verschiedene Objektkategorien machen unterschiedliche Saisonkalender erforderlich. Es könnte z. B. ein Dauercamperkalender geben, mit dem zwischen Sommer- und Wintersaison unterschieden wird. Parallel dazu könnte ein Touristkalender zwischen Hauptsaison für Sommerferien und Weihnachten/Silvester und Nebensaison für den Rest des Jahres unterscheiden.</p>
                                <p className="text-muted mb-4">In einem Saisonkalender gibt es ein oder mehrere Saisons, die einem spezifischen Preislevel entsprechen. Innerhalb der Saison gibt es ein oder mehrere Datumsbereiche. Jeder Tag des Jahres kann innerhalb eines Saisonkalenders nur 1 Datumsbereich und dieser nur 1 Saison zugeordnet werden.</p>

                                <div className="hstack gap-2 justify-content-end">
                                    <span onClick={closeTutorialModal} className="btn btn-link link-success fw-medium keychainify-checked"
                                       data-bs-dismiss="modal"><RiCloseLine className="ri-close-line me-1 align-middle" />Schließen</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tutorial;

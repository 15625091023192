import React, {useEffect, useState} from 'react';
import { RiEditLine } from 'react-icons/ri';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import {useAuth} from "../../../hooks/useAuth";
import ObjectService from "../../../services/ObjectService";

const ObjectDescriptionModal = ({tag, object, onSubmit}) => {
    const [isDescriptionModalOpen, setIsDescriptionModalOpen] = useState(false);
    const { user } = useAuth();

    const { id, bookingObjectSeasonProperties, ...restFullState } = object;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState
    });

    const [currentLanguage, setCurrentLanguage] = useState('de');

    const [textByLanguage, setTextByLanguage] = useState([
        { locale: 'de', text: '' },
        { locale: 'en', text: '' },
        { locale: 'fr', text: '' },
        { locale: 'pl', text: '' },
        { locale: 'cs', text: '' },
        { locale: 'nl', text: '' }
    ]);

    useEffect(() => {
        const { id, bookingObjectSeasonProperties, ...restFullState } = object;
        setFullStateEdit({
            ...restFullState
        });

        const texts = {};
        restFullState[tag]?.forEach(desc => {
            texts[desc.locale] = desc.text;
        });
        setTextByLanguage(prevTexts => {
            return prevTexts.map(item => ({
                ...item,
                text: texts[item.locale] || ''
            }));
        });
    }, [object, tag]);

    useEffect(() => {
        const updatedDescriptions = textByLanguage
            .filter(({ text }) => text.trim() !== '')
            .map(({ locale, text }) => ({
                locale,
                text
            }));

        setFullStateEdit(prevState => ({
            ...prevState,
            [tag]: updatedDescriptions
        }));
    }, [textByLanguage]);

    const change = (content, language) => {
        setTextByLanguage(prevTexts => {
            return prevTexts.map(item => {
                if (item.locale === language) {
                    return {
                        ...item,
                        text: content
                    };
                }
                return item;
            });
        });
    };

    const openDescriptionModal = () => {
        setIsDescriptionModalOpen(true);
    };

    const closeDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
    };

    const submitDescriptionModal = () => {
        setIsDescriptionModalOpen(false);
        saveChanges();
    };

    const saveChanges = async () => {
        try {
            await ObjectService.putObject(object.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    const handleLanguageChange = (e) => {
        setCurrentLanguage(e.target.value);
    };

    return (
        <div className="row" style={{zIndex: "1051"}}>
            <div className="col-9">
                <div className="olap-description-text" dangerouslySetInnerHTML={{ __html: textByLanguage.find(desc => desc.locale === currentLanguage)?.text || '' }} />
            </div>
            <div className="col-3">
                <div onClick={openDescriptionModal} className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <RiEditLine />
                </div>
                <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isDescriptionModalOpen ? 'show' : ''}`}
                     style={{ display: isDescriptionModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                    <div className="modal-dialog modal-lg">
                        <div className="modal-content">
                            <div className='modal-header'>
                                <h5 className='modal-title'>
                                    Beschreibung bearbeiten
                                </h5>
                                <button type='button' className='btn-close' aria-label='Close' onClick={closeDescriptionModal} />
                            </div>
                            <div className='modal-body'>
                                <select className="form-select mb-3" onChange={handleLanguageChange}
                                        value={currentLanguage}>
                                    <option value="de">Deutsch</option>
                                    <option value="en">Englisch</option>
                                    <option value="fr">Französisch</option>
                                    <option value="pl">Polnisch</option>
                                    <option value="cs">Tschechisch</option>
                                    <option value="nl">Niederländisch</option>
                                </select>
                                <div>
                                    {textByLanguage.map(({ locale, text }) => (
                                        <div key={locale} style={{ display: locale === currentLanguage ? 'block' : 'none' }}>
                                            <ReactQuill
                                                theme="snow"
                                                value={text}
                                                onChange={(content) => change(content, locale)}
                                            />
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div className='modal-footer'>
                            <span className='btn btn-light' onClick={closeDescriptionModal}>
                                Abbrechen
                            </span>
                                <span className='btn btn-success' onClick={submitDescriptionModal}>
                                Speichern
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ObjectDescriptionModal;

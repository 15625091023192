import axios from 'axios';
import config from '../config.json';

class SeasonService {
    static async getCalendars(orgId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/organizations/${orgId}/calendars`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postCalendar(orgId, user, state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/organizations/${orgId}/calendars`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putCalendar(calendarId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/calendars/${calendarId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async getCalendar(calendarId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/calendars/${calendarId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteCalendar(calendarId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/calendars/${calendarId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postSeason(calendarId, user, state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/calendars/${calendarId}/seasons`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async getSeasons(calendarId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/calendars/${calendarId}/seasons`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putSeasons(seasonId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/seasons/${seasonId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteSeason(seasonId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/seasons/${seasonId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }
}

export default SeasonService;

import React from 'react';
import { RiQuestionLine } from 'react-icons/ri';

const OlapWiki = ({tag}) => {
    return (
        <div className="rowTutorial d-none d-md-block" style={{zIndex: "1050"}}>
            <a href={`https://wiki.camppartner.com/de/Produkte/CPOw/Booking#${tag}`} target="_blank" rel="noopener noreferrer">
                <div className="btn-info rounded-pill shadow-lg btn btn-olap-success btn-success btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                    <RiQuestionLine className="mdi mdi-spin mdi-cog-outline fs-22" />
                </div>
            </a>
        </div>
    );
};

export default OlapWiki;

import React from 'react';
import ProfileBackground from "../Profile/ProfileBackground";
import ProfileHeader from "../Profile/ProfileHeader";
import OrganisationsContent from "./OrganisationsContent";

const Organisations = () => {

    return (
        <div>
            <ProfileBackground />
            <ProfileHeader />
            <OrganisationsContent />
        </div>
    );
};

export default Organisations;
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useOrganization } from '../hooks/useOrganization';

const HasOganization = ({ children }) => {
    const { organization } = useOrganization();

    if (!organization) {
        return <Navigate to='/organisationen' replace />;
    }
    return children;
};

export default HasOganization;

import axios from 'axios';
import config from '../config.json';

class ServiceService {
    static async getObjectSeasonServices(objectId, seasonId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/objects/${objectId}/season/${seasonId}/services`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postObjectSeasonService(objectId, seasonId, serviceId, user, state = {} ) {
        return axios.post(`${config.API_BASE_URI}/api/v1/objects/${objectId}/season/${seasonId}/services/${serviceId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putObjectSeasonService(objectId, seasonId, serviceId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/objects/${objectId}/season/${seasonId}/services/${serviceId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteObjectSeasonService(objectId, seasonId, serviceId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/objects/${objectId}/season/${seasonId}/services/${serviceId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async getCategorySeasonServices(categoryId, seasonId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/season/${seasonId}/services`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postCategorySeasonService(categoryId, seasonId, serviceId, user, state = {} ) {
        return axios.post(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/season/${seasonId}/services/${serviceId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putCategorySeasonService(categoryId, seasonId, serviceId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/season/${seasonId}/services/${serviceId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteCategorySeasonService(categoryId, seasonId, serviceId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/season/${seasonId}/services/${serviceId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async getObjectServices(objectId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/objects/${objectId}/services`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postObjectServices(objectId, serviceId, user, state = {} ) {
        return axios.post(`${config.API_BASE_URI}/api/v1/objects/${objectId}/services/${serviceId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putObjectService(objectId, serviceId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/objects/${objectId}/services/${serviceId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteObjectService(objectId, serviceId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/objects/${objectId}/services/${serviceId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async getCategoryServices(categoryId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/services`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postCategoryServices(categoryId, serviceId, user, state = {}) {
        return axios.post(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/services/${serviceId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putCategoryService(categoryId, serviceId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/services/${serviceId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteCategoryService(categoryId, serviceId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/categories/${categoryId}/services/${serviceId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async getOrganizationServices(organizationId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/organizations/${organizationId}/services`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postOrganizationServices(organizationId, user, state) {
        return axios.post(`${config.API_BASE_URI}/api/v1/organizations/${organizationId}/services`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putOrganizationService(organizationId, serviceId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/organizations/${organizationId}/services/${serviceId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deleteOrganizationService(organizationId, serviceId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/organizations/${organizationId}/services/${serviceId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

}

export default ServiceService;

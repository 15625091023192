import React, {Fragment, useEffect, useState} from 'react';
import { RiArrowRightLine, RiArrowLeftLine, RiCheckDoubleFill } from 'react-icons/ri';
import WizardSidebar from "./WizardSidebar";
import First from './Steps/First';
import FourthStep from './Steps/FourthNew';
import SaisonCalendar from "./SaisonCalendar";
import {useParams, useLocation, Link} from "react-router-dom";
import CalendarMockService from "../../services/CalendarMockService";
import Tutorial from "./Tutorial";

const SaisonWizard = () => {
    const { calendarId } = useParams();
    let location = useLocation();

    const [monthData, setMonthData] = useState([]);

    const [step, setStep] = useState(0);

    const [showDensity, setShowDensity] = useState(true);
    const [showSaison, setShowSaison] = useState(true);

    const next = (e) => {
        e.preventDefault();
        setStep(step + 1);
    }

    const back = (e) => {
        e.preventDefault();
        setStep(step - 1);
    }

    const fetchCalendar = async () => {
        try {
            const response = await CalendarMockService.getCalendarData();
            setMonthData(response);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    useEffect(() => {
        const initialStep = calendarId === undefined  ? 0 : 1;
        setStep(initialStep);
    }, [location, calendarId]);

    useEffect(() => {
        fetchCalendar();
    }, []);

    const handleShowDensityChange = () => {
        setShowDensity(!showDensity);
    };

    const handleShowSaisonChange = () => {
        setShowSaison(!showSaison);
    };

    const conditionalStep = () => {
        switch (step) {
            case 1:
                //return <SecondStep />;
                return <FourthStep />;
            default:
                return <First />;
        }
    };

    return (
            <Fragment>
                <div className="row">
                    <div className="col-12">
                        <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                            <h4 className="mb-sm-0">Saison Wizard</h4>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col-xl-12'>
                        <div className="card">
                            <div className="card-header p-1 pe-3">
                                <div className='row'>
                                    <div className="col-xl-6 d-flex align-content-center align-items-center">
                                        <h4 className="card-title mb-0 p-3">Verwalten Sie Ihre Saisons!</h4>
                                    </div>
                                    <div className="col-xl-6 d-flex align-items-center align-content-center justify-content-end gap-3">
                                        {(step === 1) && (
                                            <button onClick={back} type="submit" className="btn btn-light btn-label previestab">
                                                <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2">
                                                    <RiArrowLeftLine/>
                                                </i>
                                                Zurück
                                            </button>
                                        )}
                                        {(step === 2) && (
                                            <button onClick={next} className="btn btn-success btn-label right nexttab nexttab">
                                                <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2">
                                                    <RiArrowRightLine/>
                                                </i>
                                                Weiter
                                            </button>
                                        )}
                                        {step === 1 && (
                                            <Link to={"/objects-and-seasons"}>
                                                <button type="submit" className="btn btn-success btn-label right nexttab nexttab">
                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2">
                                                        <RiCheckDoubleFill/>
                                                    </i>
                                                    Saisonkalendar und Objekte verknüpfen
                                                </button>
                                            </Link>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="form-steps vertical-navs-step">
                                    <div className="row gy-5">
                                        <div className="col-lg-9">
                                            <div className="px-lg-4">
                                                {conditionalStep()}
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <WizardSidebar step={step}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`row ${showDensity ? '' : 'noShowDensity'} ${showSaison ? '' : 'noShowSaison'}`} id="saison-calendar" style={{"display": "none"}}>
                    <div className='col-xl-12'>
                        <div className='row'>
                            <div className='col-xl-12'>
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='row'>
                                            <div className="form-check col-xl-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="showSaison"
                                                    checked={showSaison}
                                                    onChange={handleShowSaisonChange}
                                                />
                                                <label className="form-check-label" htmlFor="showSaison">
                                                    Saisons anzeigen
                                                </label>
                                            </div>
                                            <div className="form-check col-xl-2">
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    id="showDensity"
                                                    checked={showDensity}
                                                    onChange={handleShowDensityChange}
                                                />
                                                <label className="form-check-label" htmlFor="showDensity">
                                                    Feriendichte anzeigen
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-12'>
                        <div className='row'>
                            {monthData.map((month, index) => (
                                <SaisonCalendar
                                    key={index}
                                    title={month.title}
                                    days={month.days}
                                    startDay={month.startDay}
                                    monthData={month.data}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <Tutorial />
            </Fragment>
    );
};

export default SaisonWizard;

import React, {Fragment, useEffect, useState} from 'react';
import { useParams, useLocation } from 'react-router-dom';
import OlapManagementHeader from "../Management/OlapManagementHeader";
import OlapTutorial from "../Management/OlapTutorial";
import {useAuth} from "../../../hooks/useAuth";
import ObjectSeasonPropertiesNavbar from "./ObjectSeasonPropertiesNavbar";
import Loader from "../../../components/Loader";
import ObjectService from "../../../services/ObjectService";
import ObjectSeasonPropertiesTable from "./ObjectSeasonPropertiesTable";
import CategoryService from "../../../services/CategoryService";
import PlaceService from "../../../services/PlaceService";
import OlapSyncButton from "../OlapSyncButton";

const ObjectSeasonProperties = () => {
    const [loadingObject, setLoadingObject] = useState(true);
    const [loadingCategory, setLoadingCategory] = useState(true);
    const [loadingPlace, setLoadingPlace] = useState(true);
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { placeId, categoryId, objectId } = useParams();
    const [object, setObject] = useState({});
    const [category, setCategory] = useState({});
    const [place, setPlace] = useState({});

    const location = useLocation();

    const { user } = useAuth();

    const fetchObject = async () => {
        try {
            setLoadingObject(true);
            const response = await ObjectService.getObject(objectId, user);
            setObject(response.data);
            setLoadingObject(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    const fetchCategory = async () => {
        try {
            setLoadingCategory(true);
            const response = await CategoryService.getCategory(categoryId, user);
            setCategory(response.data);
            setLoadingCategory(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    const fetchPlace = async () => {
        try {
            setLoadingPlace(true);
            const response = await PlaceService.getPlace(placeId, user);
            setPlace(response.data);
            setLoadingPlace(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };


    useEffect(() => {
        fetchObject();
        fetchCategory();
        fetchPlace();
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSidebarChange = () => {
        setSidebarOpen(!sidebarOpen);
    }

    const fetchObjectWithoutLoad = async () => {
        try {
            const response = await ObjectService.getObject(objectId, user);
            setObject(response.data);
            setLoadingObject(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    const onSubmit = async () => {
        await fetchObjectWithoutLoad();
    }

    return (
        <Fragment>
            <OlapManagementHeader />
            {loadingObject || loadingCategory || loadingPlace ? (
                    <Loader card={false} />
                ) : (
                <Fragment>
                    <ObjectSeasonPropertiesNavbar handleSidebarChange={handleSidebarChange} place={place} category={category} item={object} />
                    <ObjectSeasonPropertiesTable sidebarOpen={sidebarOpen} handleSidebarChange={handleSidebarChange} category={category} item={object} onSubmit={onSubmit}/>
                </Fragment>
            )}
            <OlapTutorial />
            <OlapSyncButton categoryId={null} objectId={object.id} place={place}/>
        </Fragment>
    );
};

export default ObjectSeasonProperties;
import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth';
import { isEmpty } from '../utils/validation';
import LoginFooter from "../parts/LoginFooter";

const SignUpPage = () => {
    const { signup } = useAuth();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState({
        email: ''
    });
    const [errors, setErrors] = useState({
        email: false,
        exist: false
    });
    const [invalid, setInvalid] = useState(false);

    const validate = () => {
        let hasErrors = false;
        let newErrors = {};

        for (const key in state) {
            if (isEmpty(state[key])) {
                newErrors[key] = `${key} ist notwendig`;
                hasErrors = true;
            }
        }

        if (hasErrors) {
            setErrors(newErrors);
        }

        return !hasErrors;
    };

    const submit = async (e) => {
        e.preventDefault();
        setLoading(true);
        if (validate()) {
            try {
                await signup(state);
                navigate('/login', { replace: true, state: { signUpRequest: true } });
            } catch (err) {
                setInvalid(true);
                setLoading(false);
            }
        }
    };

    const change = (e) => {
        if (isEmpty(e.target.value)) {
            setErrors({ ...errors, [e.target.name]: `${e.target.name} ist notwendig` });
        } else {
            setErrors({ ...errors, [e.target.name]: false });
        }
        setState({ ...state, [e.target.name]: e.target.value });
    };

    return (
        <div className='auth-page-wrapper pt-5'>
            <div className='auth-one-bg-position auth-one-bg' id='auth-particles'>
                <div className='bg-overlay'></div>

                <div className='shape'>
                    <svg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 1440 120'>
                        <path d='M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z'></path>
                    </svg>
                </div>
            </div>
            <div className='auth-page-content'>
                <div className='container'>
                    <div className='row justify-content-center'>
                        <div className='col-md-8 col-lg-6 col-xl-6'>
                            <div className='card mt-4'>
                                <div className='card-body p-4'>
                                    <div className='text-center mt-2'>
                                        <h5 className='text-primary'>Willkommen bei Camppartner!</h5>
                                        <p className='text-primary'>Das Property Management System zur Verwaltung der
                                            Online Buchbarkeit.</p>
                                        <p className='text-muted'>
                                            Registriere dich mit der Mail Adresse deiner Organisation, um Objekte, Saisonzeiten, Preise und Eigenschaften deiner online buchbaren Objekte zu verwalten.
                                        </p>
                                    </div>
                                    <div className='p-2 mt-4'>
                                        <form onSubmit={submit}>
                                            <div className='mb-3'>
                                                <label htmlFor='email' className='form-label d-block'>
                                                    E-Mail
                                                </label>
                                                <input
                                                    type='text'
                                                    className={`form-control ${errors.email && 'is-invalid'}`}
                                                    name='email'
                                                    placeholder='E-Mail eingeben'
                                                    onChange={change}
                                                    value={state.email}
                                                />
                                                {invalid &&
                                                    <span className='text-danger'>Diese E-Mail gibt es bereits. Wenn Sie ihr Passwort vergessen haben oder eine neue Verifikations-Mail benötigen klicken sie <Link
                                                        to='/forgot-password'>[hier]</Link>.</span>
                                                }
                                                <div className='invalid-feedback'>{errors.email}</div>
                                            </div>

                                            <div className={'text-muted'}>
                                                <small>
                                                    Bitte stelle sicher, dass die eingegebene E-Mail korrekt ist. Nach
                                                    Eingabe wird dir innerhalb von Sekunden eine Mail zugeschickt, in
                                                    der sich ein Link zur Verifizierung deiner Mail-Adresse befindet.
                                                    Durch Schutzmaßnahmen deiner Organisation kann es zur Verzögerungen
                                                    der Auslieferung der Mail (i.d.R. von nicht mehr als 10 Minuten)
                                                    kommen. Bitte kontrolliere auch deinen Spam-Ordner. Der Link zur
                                                    Verifizierung bleibt 12 Stunden gültig.
                                                </small>
                                            </div>

                                            <div className='mt-4'>
                                                <button className='btn btn-success w-100' type='submit'>
                                                    Registrieren
                                                </button>
                                            </div>

                                            <div className='mt-4 text-center'>
                                                {loading && (
                                                    <div className='spinner-border text-secondary' role='status'>
                                                        <span className='sr-only'>Loading...</span>
                                                    </div>
                                                )}
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>

                            <div className='mt-4 text-center'>
                                <p className='mb-0'>
                                    Sie haben bereits einen Account?
                                    <Link
                                        to='/login'
                                        className='ms-1 fw-semibold text-primary text-decoration-underline'
                                    >
                                        Login
                                    </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <LoginFooter />
        </div>
    );
};

export default SignUpPage;

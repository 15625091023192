import React from "react";
import {Link, useParams} from "react-router-dom";
import {RiHotelBedLine} from "react-icons/ri";

const OlapObjects = ({ item, parent, grandparent, handleSidebarChange }) => {

    const { objectId } = useParams();

    return (
        <li className="li-unstyled li-unstyled2">
            <Link to={`/olap/place/${grandparent.id}/category/${parent.id}/object/${item.id}`} className={`${objectId === item.id.toString() ? 'active' : ''}`}>
                <RiHotelBedLine className="me-2"/>
                <span>
                    {item.name}{item.channels.length > 0 && item.channels[0].channelType === "CPO" ? " - " + item.channels[0].channelDataMap.cpoId : ''}
                </span>
            </Link>
        </li>
    );
}

export default OlapObjects;
import React, { useEffect, useState } from 'react';
import {RiCloseLine, RiEditLine} from 'react-icons/ri';
import { useAuth } from '../../../hooks/useAuth';
import serviceService from "../../../services/ServiceService";
import OlapTooltip from "../OlapTooltip";

const ObjectSeasonServiceModal = ({ serviceItem, seasonId, objectId, onSubmit, price }) => {
    const [isServiceModalOpen, setIsServiceModalOpen] = useState(false);
    const [isServiceDeleteModalOpen, setIsServiceDeleteModalOpen] = useState(false);

    const [serviceData, setServiceData] = useState(serviceItem.service);

    const { user } = useAuth();

    const { bookingObjectSeasonServiceKey, season, service, ...restFullState } = serviceItem;

    const [fullStateEdit, setFullStateEdit] = useState({
        ...restFullState,
        price: price
    });


    useEffect(() => {
        const { bookingObjectSeasonServiceKey, season, service, ...restFullState } = serviceItem;

        setFullStateEdit({
            ...restFullState,
            price: price
        });

        setServiceData(service);

    }, [serviceItem]);

    const openServiceModal = () => {
        setIsServiceModalOpen(true);
    };

    const closeServiceModal = () => {
        setIsServiceModalOpen(false);
    };

    const submitServiceModal = () => {
        setIsServiceModalOpen(false);
        saveChanges();
    };

    const openServiceDeleteModal = () => {
        setIsServiceDeleteModalOpen(true);
    };

    const closeServiceDeleteModal = () => {
        setIsServiceDeleteModalOpen(false);
    };

    const submitServiceDeleteModal = () => {
        setIsServiceDeleteModalOpen(false);
        deleteService();
    };

    const saveChanges = async () => {
        try {
            await serviceService.putObjectSeasonService(objectId, seasonId, service.id, user, fullStateEdit);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    const deleteService = async () => {
        try {
            await serviceService.deleteObjectSeasonService(objectId, seasonId, service.id, user);
            onSubmit();
        } catch (err) {
            throw err;
        }
    };

    const change = (e) => {
        const { name, value } = e.target;

        // value numbers with 2 decimal places
        if (name === 'price') {
            if (value.includes('.')){
                const split = value.split('.');
                if (split[1].length > 2) {
                    return;
                }
            }
            if (value.includes(',')){
                const split = value.split(',');
                if (split[1].length > 2) {
                    return;
                }
            }
        }

        setFullStateEdit((prevState) => {
            return {
                ...prevState,
                //value need to be integer
                [name]: value === '' ? null : parseInt(value),
            };
        });
    };


    return (
        <div className="row" style={{ zIndex: '1051' }}>
            <div className="col-10">
                <div className="olap-description-text d-flex flex-wrap gap-2">
                    {fullStateEdit.price ? `${fullStateEdit.price}€, `: serviceData.price ? `${serviceData.price}€, ` : '0€'}<br/>
                    {serviceData.inclusive && 'inkl., '}
                    {serviceData.paymentInterval === 'PER_BOOKING' ? 'pro Buchung, ' : serviceData.paymentInterval === 'PER_DAY' ? 'pro Tag, ' : 'pro Stunde, '}
                    {serviceData.serviceCalculationType === 'PER_BOOKING' ? 'p.B., ' : serviceData.serviceCalculationType === 'PER_PERSON' ? 'p.P., ' : serviceData.serviceCalculationType === 'PER_ROOM_OR_OBJECT' ? 'p.Z., ' : ''}
                    {serviceData.ageMin ? `>=${serviceItem.service.ageMin}, ` : ''}
                    {serviceData.ageMax ? `<=${serviceItem.service.ageMax}, ` : ''}
                    {serviceData.addedAutomatically ? `autom., ` : ''}
                    {serviceData.hidden ? `ausbl., ` : ''}
                    {serviceData.onSite ? `nur v. Ort, ` : ''}
                </div>
            </div>
            <div className="col-2">
                <div className={'row'}>
                    <div className="col-6">
                        <div
                            onClick={openServiceModal}
                            className="btn-olap-modal btn-info btn btn-olap-success btn-success btn-lg p-2"
                            data-bs-toggle="offcanvas"
                            data-bs-target="#theme-settings-offcanvas"
                            aria-controls="theme-settings-offcanvas"
                        >
                            <RiEditLine />
                        </div>
                        <div
                            id="bottom-rightModal"
                            tabIndex="-1"
                            aria-hidden="true"
                            className={`modal fade ${isServiceModalOpen ? 'show' : ''}`}
                            style={{ display: isServiceModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}
                        >
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title">Leistung: {serviceItem.service.name[0] && serviceItem.service.name[0].text ? serviceItem.service.name[0].text : ''} bearbeiten</h5>
                                        <button type="button" className="btn-close" aria-label="Close" onClick={closeServiceModal} />
                                    </div>
                                    <div className='modal-body'>
                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <div className={'mb-3'}>
                                                    <label htmlFor='name' className='form-label'>
                                                        Preis
                                                        <OlapTooltip Text={'Spezifischer Preis nur für dieses Objekt.'} />
                                                    </label>
                                                    <input
                                                        type='number'
                                                        className={`form-control`}
                                                        name='price'
                                                        value={fullStateEdit.price ? fullStateEdit.price : ''}
                                                        onChange={change}
                                                        onKeyDown={(e) => {
                                                            if (e.key === 'e' || e.key === 'E' || e.key === '+') {
                                                                e.preventDefault();
                                                            }
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer">
                                <span className="btn btn-light" onClick={closeServiceModal}>
                                  Abbrechen
                                </span>
                                        <span className="btn btn-success" onClick={submitServiceModal}>
                                  Speichern
                                </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col-6'}>
                        <div onClick={openServiceDeleteModal} className="btn-olap-modal btn-info btn btn-olap-success btn-danger btn-lg p-2" data-bs-toggle="offcanvas" data-bs-target="#theme-settings-offcanvas" aria-controls="theme-settings-offcanvas">
                            <RiCloseLine />
                        </div>
                        <div id="bottom-rightModal" tabIndex="-1" aria-hidden="true"  className={`modal fade ${isServiceDeleteModalOpen ? 'show' : ''}`}
                             style={{ display: isServiceDeleteModalOpen ? 'block' : 'none', zIndex: '100000 !important' }}>
                            <div className="modal-dialog modal-lg">
                                <div className="modal-content">
                                    <div className='modal-header'>
                                        <h5 className='modal-title'>
                                            "{serviceItem.service.name[0] && serviceItem.service.name[0].text ? serviceItem.service.name[0].text : ''}" löschen
                                        </h5>
                                        <button type='button' className='btn-close' aria-label='Close' onClick={closeServiceDeleteModal} />
                                    </div>
                                    <div className='modal-body'>
                                        <div>
                                            Wollen Sie wirklich "{serviceItem.service.name[0] && serviceItem.service.name[0].text ? serviceItem.service.name[0].text : ''}" löschen?
                                        </div>
                                    </div>
                                    <div className='modal-footer'>
                                        <span className='btn btn-light' onClick={closeServiceDeleteModal}>
                                            Abbrechen
                                        </span>
                                        <span className='btn btn-success' onClick={submitServiceDeleteModal}>
                                            Löschen
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ObjectSeasonServiceModal;

import axios from 'axios';
import config from '../config.json';

class PlaceService {
    static async getPlaces(orgId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/organizations/${orgId}/places`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async getPlace(placeId, user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/places/${placeId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async postPlace(orgId, user, state = {name: 'Platz 1'}) {
        return axios.post(`${config.API_BASE_URI}/api/v1/organizations/${orgId}/places`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async putPlace(placeId, user, state) {
        return axios.put(`${config.API_BASE_URI}/api/v1/places/${placeId}`, state, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

    static async deletePlace(placeId, user) {
        return axios.delete(`${config.API_BASE_URI}/api/v1/places/${placeId}`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }
}

export default PlaceService;

import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {RiHotelBedLine} from "react-icons/ri";
import {useDrop} from "react-dnd";
import {useAuth} from "../../hooks/useAuth";
import PropertiesService from "../../services/PropertiesService";
import ObjectsAndSeasonsDnDDeleteModal from "./ObjectsAndSeasonsDnDDeleteModal";

const ObjectsAndSeasonsDnDObjects = ({newFetch, item}) => {
    const [state] = useState({
        bookingProperties: {

        }
    })

    const [errors, setErrors] = useState('');

    const { user } = useAuth();

    const [, drop] = useDrop({
        accept: ['CALENDAR', 'SEASON'],
        drop: async (droppedItem) => {
            // Handle the dropped item, e.g., add it to the state or perform some action

            if(droppedItem.type === "CALENDAR") {
                const promises = droppedItem.seasons.map(async (season) => {
                    await addSeasonToObject(season.id, item.id, season);
                });
                await Promise.all(promises);
                newFetch();
            } else{
                await addSeasonToObject(droppedItem.id, item.id, droppedItem);
                newFetch();
            }
        },
    });

    const validate = (season) => {
        const seasonDateRanges = season.dateRanges;
        const objectDateRanges = item.bookingObjectSeasonProperties.map((property) => property.season.dateRanges).flat();

        for (let i = 0; i < seasonDateRanges.length; i++) {
            for (let j = 0; j < objectDateRanges.length; j++) {
                if (seasonDateRanges[i].dateFrom <= objectDateRanges[j].dateTo && seasonDateRanges[i].dateTo >= objectDateRanges[j].dateFrom) {
                    return false;
                }
            }
        }
        return true;
    };

    const addSeasonToObject = async (seasonId, objectId, season) => {
        if (validate(season)){
            try {
                await PropertiesService.postSeasonObjectProperty(seasonId, objectId, user, state);
            } catch (err) {
                setErrors(err.response.data.errors);
            }
            return false;
        } else {
            setErrors('Diese Saison ist bereits vorhand in diesem Objekt.');
        }
    };

    const deleteSeasonObject = async (seasonId, objectId) => {
        try {
            await PropertiesService.deleteSeasonObjectProperty(seasonId, objectId, user);
        } catch (err) {
            //TODO
            throw err;
        }
        return false;
    };

    const submitDelete = async (seasonId) => {
        await deleteSeasonObject(seasonId, item.id);
        newFetch();
    }

    useEffect(() => {
        const errorTimer = setTimeout(() => {
            setErrors('');
        }, 3000);

        return () => {
            clearTimeout(errorTimer);
        };
    }, [errors]);


    return (
        <li className="li-unstyled li-unstyled-60">
            <Link to="">
                <RiHotelBedLine className="me-2"/>
                <span>
                    {item.name} (Objekt)
                </span>
                <small className='text-muted'>
                    {item.channels.length > 0 && item.channels[0].channelType === "CPO" ? " (" + item.channels[0].channelDataMap.cpoId + " - " + item.channels[0].channelDataMap.name + ")" : ''}
                </small>
            </Link>
            <div ref={drop} className='droparea'>
                <div className="row">
                    {item.bookingObjectSeasonProperties.map((season, index) => (
                        <div className="col-auto pe-0" key={index}>
                            <div className="card bg-light mb-0">
                                <div className="card-body">
                                    {season.season.name}
                                    <ObjectsAndSeasonsDnDDeleteModal parentName={item.name} item={season} submitDelete={submitDelete} />
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={`toastify ${errors ? 'on' : ''} bg-danger toastify-center toastify-top`} aria-live="polite" style={{transform: "translate(0px, 0px)", top: "15px"}}>Das Objekt: {item.name} hat diese Saison bereits.</div>
        </li>
    )
}

export default ObjectsAndSeasonsDnDObjects;
import React, { useEffect, useState } from 'react';
import OrganisationCard from './OrganisationCard';
import OrganisationModal from './OrganisationModal';
import OrganizationService from '../../services/OrganizationService';
import { useAuth } from '../../hooks/useAuth';
import Loader from '../../components/Loader';
import { useOrganization } from '../../hooks/useOrganization';
import { useNavigate, useSearchParams } from 'react-router-dom';
import OrganisationRequestModal from './OrganisationRequestModal';
import AlertCard from '../../components/AlertCard';
import config from '../../config.json';

const OrganisationsContent = () => {
    const [loading, setLoading] = useState(true);
    const [organizations, setOrganizations] = useState([]);

    const [searchParams, setSearchParams] = useSearchParams();

    const { setOrganization } = useOrganization();

    const navigate = useNavigate();

    const { user } = useAuth();

    const fetchOrganizations = async () => {
        try {
            const response = await OrganizationService.getOrganizations(user);
            if (response.data.length === 1 && searchParams.get('autoselect') === 'true') {
                setOrganization(response.data[0]);
                navigate('/profil', { replace: true });
            }
            setOrganizations(response.data);
            setLoading(false);
        } catch (err) {
            //TODO
            throw err;
        }
    };

    useEffect(() => {
        fetchOrganizations();
    }, []);

    const onModalSubmit = () => {
        setLoading(true);
        fetchOrganizations();
    };

    const onEditModalSubmit = () => {
        setLoading(true);
        fetchOrganizations();
    };

    const select = (idx) => {
        setOrganization(organizations[idx]);
        navigate('/profil', { replace: true });
    };

    return (
        <div className='row'>
            <div className='col-lg-12'>
                <div>
                    <div className='tab-content pt-4 text-muted'>
                        <div className='tab-pane active' id='overview-tab' role='tabpanel'>
                            <div className='row'>
                                {loading && <Loader />}
                                {loading !== true && organizations.length === 0 && (
                                    <AlertCard>
                                        <strong>Warnung</strong> - Sie sind derzeit keiner Organisation zugeordnet.
                                        Lassen Sie sich von einem Administrator einer Organisation zuordnen oder
                                        erstellen Sie eine eigene Organisation.
                                    </AlertCard>
                                )}
                                {loading !== true && organizations.length === 0 && (
                                    <div className='card'>
                                        <div className='card-body'>
                                            <h5 className='card-title mb-3'>Willkommen bei {config.PRODUCT_NAME}!</h5>
                                            <p>
                                                Wir freuen uns sehr, dass du dich für die Registrierung entschieden
                                                hast. Der erste Schritt ist getan! Allerdings hast du bisher noch keine
                                                Organisation zugeordnet.
                                            </p>
                                            <p>
                                                Damit du das volle Potenzial von {config.PRODUCT_NAME} nutzen kannst, bitten wir
                                                dich, Kontakt mit einem Administrator deiner Organisation aufzunehmen.
                                                Sie werden dich gerne hinzufügen und dir den Zugang zu den spannenden
                                                Funktionen unserer Plattform ermöglichen.
                                            </p>
                                            <p>
                                                Alternativ dazu kannst du auch deine eigene Organisation erstellen.
                                                Klicke dazu einfach auf den untenstehenden Button und folge den
                                                Schritten, um deine eigene Organisation einzurichten.
                                            </p>
                                            <div className='row'>
                                                <div className='flex-shrink-0'>
                                                    <OrganisationModal onSubmit={onModalSubmit} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {loading !== true && organizations.length > 0 && (
                                    <div className='card'>
                                        <div className='card-body'>
                                            <div className='row mb-1'>
                                                <div className='col-xl-6'>
                                                    <h5 className='card-title mb-2'>Organisationen</h5>
                                                    <p className='text-muted mb-4'>Wählen Sie eine Organisation</p>
                                                </div>
                                                <div className='col-xl-6 justify-content-end d-flex'>
                                                    {/*<OrganisationRequestModal />*/}
                                                    <OrganisationModal onSubmit={onModalSubmit} />
                                                </div>
                                            </div>
                                            <div className='row'>
                                                {organizations.map((item, idx) => (
                                                    <OrganisationCard
                                                        item={item}
                                                        idx={idx}
                                                        key={item.id}
                                                        onSelect={select}
                                                        onEditSubmit={onEditModalSubmit}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganisationsContent;

import axios from 'axios';
import config from '../config.json';

class VatService {
    static async getVats(user) {
        return axios.get(`${config.API_BASE_URI}/api/v1/vats`, {
            headers: {
                'X-Auth-Token': user.token,
            },
        });
    }

}

export default VatService;

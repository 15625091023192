import React, { useEffect, useState } from 'react';
import {Link, useLocation} from 'react-router-dom';
import { useOrganization } from '../../hooks/useOrganization';
import PlaceService from '../../services/PlaceService';
import { useAuth } from '../../hooks/useAuth';
import AlertCard from '../../components/AlertCard';
import SeasonService from "../../services/SeasonService";

const ProfileContentTabDetails = () => {
    const { organization } = useOrganization();
    const { user } = useAuth();

    const [showHelp, setShowHelp] = useState(false);
    const [showHelpCalendar, setShowHelpCalendar] = useState(false);

    let location = useLocation();

    const fetch = async () => {
        try {
            const response = await PlaceService.getPlaces(organization.id, user);
            if (response.data.length === 0) {
                setShowHelp(true);
            }
            const response2 = await SeasonService.getCalendars(organization.id, user);
            if (response2.data.length === 0) {
                setShowHelpCalendar(true);
            }
        } catch (err) {
            //TODO
            throw err;
        }
    };

    useEffect(() => {
        fetch();
    }, [location]);

    return (
        <div className='tab-content pt-4 text-muted'>
            <div className='tab-pane active' id='overview-tab' role='tabpanel'>
                <div className='row'>
                    <div className='col-xxl-3'>
                        <div className='card'>
                            <div className='card-body'>
                                <h5 className='card-title mb-3'>Organisationsdaten</h5>
                                <div className='table-responsive'>
                                    <table className='table table-borderless mb-0'>
                                        <tbody>
                                            <tr>
                                                <th className='ps-0' scope='row'>
                                                    Name :
                                                </th>
                                                <td className='text-muted'>{organization && organization.name}</td>
                                            </tr>
                                            {
                                                organization && organization.name2 !== '' && (
                                                    <tr>
                                                        <th className='ps-0' scope='row'>
                                                            Zweiter Name :
                                                        </th>
                                                        <td className='text-muted'>{organization.name2}</td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                organization && organization.phone !== '' && (
                                                    <tr>
                                                        <th className='ps-0' scope='row'>
                                                            Telefon :
                                                        </th>
                                                        <td className='text-muted'>{organization.phone}</td>
                                                    </tr>
                                                )
                                            }
                                            {
                                                organization && organization.email !== '' && (
                                                    <tr>
                                                        <th className='ps-0' scope='row'>
                                                            E-Mail :
                                                        </th>
                                                        <td className='text-muted'>{organization.email}</td>
                                                    </tr>
                                                )
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {
                            organization && organization.addresses.map((address, index) => {
                                return (
                                    <div className='card' key={index}>
                                        <div className='card-body'>
                                            <h5 className='card-title mb-3'>Adresse</h5>
                                            <div className='table-responsive'>
                                                <table className='table table-borderless mb-0'>
                                                    <tbody>
                                                    {
                                                        address.street !== '' && (
                                                            <tr>
                                                                <th className='ps-0' scope='row'>
                                                                    Straße :
                                                                </th>
                                                                <td className='text-muted'>{address.street}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        address.postalCode !== '' && (
                                                            <tr>
                                                                <th className='ps-0' scope='row'>
                                                                    PLZ :
                                                                </th>
                                                                <td className='text-muted'>{address.postalCode}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        address.city !== '' && (
                                                            <tr>
                                                                <th className='ps-0' scope='row'>
                                                                    Ort :
                                                                </th>
                                                                <td className='text-muted'>{address.city}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        address.state !== '' && (
                                                            <tr>
                                                                <th className='ps-0' scope='row'>
                                                                    Bundesland :
                                                                </th>
                                                                <td className='text-muted'>{address.state}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        address.country !== '' && (
                                                            <tr>
                                                                <th className='ps-0' scope='row'>
                                                                    Land :
                                                                </th>
                                                                <td className='text-muted'>{address.country}</td>
                                                            </tr>
                                                        )
                                                    }
                                                    {
                                                        address.type !== '' && (
                                                            <tr>
                                                                <th className='ps-0' scope='row'>
                                                                    Adressentyp :
                                                                </th>
                                                                <td className='text-muted'>
                                                                    {
                                                                        address.type === 'BILLING' ? 'Rechnungsadresse' : ''
                                                                    }
                                                                    {
                                                                        address.type === 'WORK' ? 'Arbeitsadresse' : ''
                                                                    }
                                                                    {
                                                                        address.type === 'HOME' ? 'Privatadresse' : ''
                                                                    }
                                                                    {
                                                                        address.type === 'DELIVERY' ? 'Lieferadresse' : ''
                                                                    }
                                                                </td>
                                                            </tr>
                                                        )
                                                    }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className='col-xxl-9'>
                        {showHelp && (
                            <AlertCard>
                                <strong>Warnung</strong> - Sie haben in dieser Organisation noch keinen Platz.
                            </AlertCard>
                        )}
                        {showHelp && (
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 className='card-title mb-3'>Legen Sie Ihren ersten Platz an.</h5>
                                    <p>
                                        Sie müssen vermietbare Objekte definieren, die in Objektkategorien gruppiert und einem Platz, einem Hotel o.ä. zugeordnet sind. Wechseln Sie dazu in die Objektstruktur-Ansicht.
                                    </p>
                                    <div className='row'>
                                        <div className='flex-shrink-0'>
                                            <Link to="/management?first=true"><span className="btn btn-success">Objektstruktur</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {!showHelp && showHelpCalendar && (
                            <AlertCard>
                                <strong>Warnung</strong> - Sie haben in dieser Organisation noch keinen Saisonkalendar.
                            </AlertCard>
                        )}
                        {!showHelp && showHelpCalendar && (
                            <div className='card'>
                                <div className='card-body'>
                                    <h5 className='card-title mb-3'>Legen Sie Ihren ersten Saisonkalendar an.</h5>
                                    <p>
                                        Sie müssen vermietbare Datumsbereiche definieren, die in Saisons gruppiert und einem Saisonkalender zugeordnet sind. Wechseln Sie dazu in die Saisonkalender-Ansicht.
                                    </p>
                                    <div className='row'>
                                        <div className='flex-shrink-0'>
                                            <Link to="/saison-wizard"><span className="btn btn-success">Saisonkalendar</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileContentTabDetails;

import React, {useEffect, useState} from 'react';

const OlapEditInput = () => {
    const [isEditing, setIsEditing] = useState(false);
    const [state, setState] = useState(
        {name: ""}
    );

    const change = (e) => {
        const newName = e.target.value;
        setState({ name: newName });
    };

    const saveChanges = () => {
        setIsEditing(false);
        //TODO Submit
        console.log(state.name);
    };

    return (
        <div className='text-center olap-input'>
            <input
                type="text"
                className="form-control form-control-edit text-center"
                value={state.name}
                onChange={change}
                onBlur={saveChanges}
                autoFocus={true}
            />
        </div>
    );
};

export default OlapEditInput;

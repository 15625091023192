import React, {useEffect, useState} from 'react';
import { RiOrganizationChart } from 'react-icons/ri';
import OrganisationEditModal from "./OrganisationEditModal";
import {useAuth} from "../../hooks/useAuth";
import PlaceService from "../../services/PlaceService";
import Loader from "../../components/Loader";
import {useLocation} from "react-router-dom";

const OrganisationCard = ({ item, idx = 0, onSelect = (idx) => {}, onEditSubmit = () => {} }) => {
    const [loading, setLoading] = useState(true);
    const [places, setPlaces] = useState([]);
    let location = useLocation();
    const { user } = useAuth();

    const fetchPlaces = async () => {
        const response = await PlaceService.getPlaces(item.id, user);
        setPlaces(response.data);
        setLoading(false);
    };

    useEffect(() => {
        fetchPlaces();
    }, [location]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div className='col-xxl-3 col-sm-6'>
            <div>
                <div className='card profile-project-card camp-profile-project-card shadow-none'>
                    <div className='card-header border-0 camp-profile-card-header'>
                        <div className='row align-items-center justify-content-center'>
                            <div className="col-auto m-auto">
                                <div className="organisationavatar">
                                    <RiOrganizationChart />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card-body p-4'>
                        <div className='d-flex'>
                            <div className='flex-grow-1 text-muted overflow-hidden'>
                                <h5 className='fs-14 text-truncate'>
                                    <span className='text-body'>{item.name}</span>
                                    <span className='ms-1 editbox'>
                                        <OrganisationEditModal onEditSubmit={onEditSubmit} organisation={item} />
                                    </span>
                                    {/*<span className='ms-1 editbox'>
                                        <OrganisationDeleteModal onEditSubmit={onEditSubmit} organisationName={item.name} organisationId={item.id}/>
                                    </span>*/}
                                </h5>
                                <div className='text-muted text-truncate mb-0'>
                                    Places: <span className='fw-semibold text-body'>
                                        {
                                            loading ? (
                                                <Loader card={false} />
                                            ) : (
                                                places.length
                                            )
                                        }
                                    </span>
                                </div>
                            </div>
                            <div className='flex-shrink-0 ms-2'>
                                <div className='badge bg-success-subtle text-success fs-10'>{item.role}</div>
                                <div className='col-xl-12'>
                                    <div onClick={(e) => onSelect(idx)} className='btn btn-success mini-btn'>
                                        Auswählen
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrganisationCard;
